import React from 'react'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FaTimes } from 'react-icons/fa';

import { Modal, Button } from 'react-onsenui'


interface PageState {

}

export interface Props {
    imgUrl: string
    show: boolean
    onClose(): void
}

type PageProps = Props


class ModalImg extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {
        }
    }


    componentDidMount() {
    }


    render() {
        const view = () => {
            return (
                <div
                    className='view'
                >

                    <TransformWrapper
                        wheel={{ step: 100, }
                        }

                        // onWheelStart={e => console.log('onWheelStart', e)}
                        // onWheel={e => console.log('onWheel', e)}
                        // onWheelStop={e => console.log('onWheelStop', e)}
                        // onPanningStart={e => console.log('onPanningStart', e)}
                        // onPanning={e => console.log('onPanning', e)}
                        // onPanningStop={e => console.log('onPanningStop', e)}
                        // onPinchingStart={e => console.log('onPinchingStart', e)}
                        // onPinching={e => console.log('onPinching', e)}
                        // onPinchingStop={e => console.log('onPinchingStop', e)}
                        // onZoomChange={e => console.log('onZoomChange', e)}
                    >
                        <TransformComponent
                        >
                            <img
                                className='img'
                                src={this.props.imgUrl}
                            />
                        </TransformComponent>
                    </TransformWrapper>


                </div >

            )
        }
        return (

            <Modal
                // popup={false}
                // transparent
                isOpen={this.props.show}
                animation='fade'
            // onClose={() => this.props.onClose()}
            // animationType="fade"
            // className = 'ModalImgTransition'
            // wrapClassName = 'ModalImgWrap'
            >
                <div
                    id='ModalImg'
                >
                    {view()}
                    <Button
                        onClick={() => {
                            this.props.onClose()
                        }}
                        className='iconBox'
                        modifier="quiet">
                        <FaTimes
                            className='icon'
                        />
                    </Button>
                </div>
            </Modal >
        );
    }


}

export default ModalImg
