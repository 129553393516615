import React from 'react'
import { Carousel, WingBlank } from 'antd-mobile';


interface PageState {

}


export type Props = {
    autoplay: boolean//是否自动切换
    infinite: boolean//是否循环播放
    selectedIndex: number//手动设置当前显示的索引
    dots: boolean//是否显示面板指示点
    autoplayInterval: number//自动切换的时间间隔
}

type PageProps = Props


class BaseBanner extends React.Component<PageProps, PageState> {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    static defaultProps = {
        autoplay: true,//是否自动切换
        infinite: true,//是否循环播放
        selectedIndex: 0,//手动设置当前显示的索引
        dots: true,//是否显示面板指示点
        autoplayInterval: 30000,//自动切换的时间间隔
    };


    render() {

        return (
            <div
                id='BaseBanner'
            >
                <Carousel
                    autoplay={this.props.autoplay}
                    infinite={this.props.infinite}
                    dots={this.props.dots}
                    selectedIndex={this.props.selectedIndex}
                    autoplayInterval={this.props.autoplayInterval}
                >
                    {this.props.children}
                </Carousel>
            </div>
        );
    }

}

export default BaseBanner
