import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import JJ_Components from '../../../components/JJ_Components'
import JJ_ShopDeliverySpLockerListItem from './JJ_ShopDeliverySpLockerListItem'
import *as ActionShopDelivery from '../../../actions/ActionShopDelivery'
import { GetShopDeliveryRes, GetShopDeliveryListParameters } from '../../../api'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import tool from '../../../tool'
import { ActionAreaRes, ActionAreaModuleRes } from '../../../actions/ActionArea'
import { ActionShopDeliveryRes } from '../../../actions/ActionShopDelivery'


interface PageState {
    pageKey: string
}


export interface Props {

}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>




interface RootState {
    delivery: ActionShopDeliveryRes
    area: ActionAreaRes
    areaModule: ActionAreaModuleRes
}

const mapState = (state: RootState) => ({
    delivery: state.delivery,
    area: state.area,
    areaModule: state.areaModule,
})

const mapDispatch = {
    getShopDeliveryList: ActionShopDelivery.getShopDeliveryList
}

export const connector = connect(
    mapState,
    mapDispatch
)

class JJ_ShopDeliverySpLockerList extends React.Component<PageProps, PageState> {


    constructor(props: PageProps) {
        super(props);
        this.state = {
            pageKey: `${new Date()}`
        }

    }

    componentWillUnmount() {
        this.setState = () => { }
    }

    _initData = async (): Promise<void> => {
        try {

        } catch (error) {
            console.log(error)
        }
    }

    _getShopDeliveryList = async (params: GetShopDeliveryListParameters) => {
        const res = await this.props.getShopDeliveryList(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
        }
        throw new Error(res.kind)
    }


    _onRenderRow = (rowData: GetShopDeliveryRes) => {
        return (
            <JJ_ShopDeliverySpLockerListItem
                key={rowData.id}
                {...rowData}
            />
        )
    }

    _view = () => {
        const moduleId = Object.keys(this.props.areaModule.list).find(moduleId => this.props.areaModule.list[moduleId].type === 'SHOP')
        if (moduleId) {
            const moduleData = this.props.areaModule.list[moduleId];
            return moduleData && (
                <div
                    id='JJ_ShopDeliverySpLockerList'
                >
                    <JJ_Components.baseListView
                        renderSeparator={(sectionID, rowID) => (<div style={{ height: 10 }} key={`${sectionID}${rowID}`} />)}
                        noDataViewText={`${tool.intl.formatMessage({ id: 'shopDeliverySpLockerListNoDateText' })}`}
                        renderRow={this._onRenderRow}
                        onLoadData={({ from, count }) => this._getShopDeliveryList({
                            moduleId: moduleData.id,
                            from,
                            count,
                            type: 'WECOOLER',
                        })}

                    />
                </div>
            )
        } else {
            return <div />
        }
    }

    render() {

        return (
            <JJ_Components.boxPage
                key={`${this.state.pageKey}pageKey`}
                loadData={this._initData}
            >
                {this._view()}
            </JJ_Components.boxPage>

        )
    }
}



export default connector(injectIntl(JJ_ShopDeliverySpLockerList))



