import React from 'react';
import { GetShopDeliveryRes } from '../../../api';
import tool from '../../../tool';

interface PageState {


}

export interface Props {
    onDetailsAction?(props: PageProps): void
}

type PageProps = Props & GetShopDeliveryRes



class JJ_ShopDeliverySpLockerListItem extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
        }
    }


    _imgView = () => {

        const thumbnails = this.props.thumbnails
        const [thumbnail] = thumbnails

        return thumbnail && (
            <div className='imgView'>
                <img
                    src={thumbnail.url}
                    style={tool.image.getImageStyle(thumbnail.meta, window.innerWidth - 40, window.innerHeight * .3)}
                />
            </div>
        )
    }

    _titleView = () => {
        return (
            <div className='titleView'>

                <h1>{this.props.name}</h1>
                <h2>{this.props.description}</h2>
            </div>
        )
    }

    render() {

        return (

            <div
                onClick={() => this.props.onDetailsAction && this.props.onDetailsAction(this.props)}
                id='JJ_ShopDeliverySpLockerListItem'
            >
                {this._imgView()}
                {this._titleView()}

            </div>

        )

    }
}




export default JJ_ShopDeliverySpLockerListItem
