import { InjectedIntl } from "react-intl";


let myIntl: InjectedIntl

/**
 * 获取intl
 * @param intl
 * @returns {*}
 */
export const getIntl = (intl: InjectedIntl) => myIntl = intl

/**
 * 格式消息
 * @param id
 * @returns {*|Error}
 */
export const formatMessage = ({ id }: { id: string }, body = {}) => myIntl
    ? myIntl.formatMessage({ id }, body)
    : ''









