import React from 'react'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import *as Ons from 'react-onsenui'
import tool from '../../tool';



interface PageState {
    address: string
    isDefault: boolean

}
export interface Props {
    type: 'edit' | 'add'
    address: string
    isDefault: boolean
    onEdit(props: PageProps): void
    onDelete(props: PageProps): void
    onAdd(props: PageProps): void
}

type PageProps = Props

class JJ_UserDeliveryAddressDetails extends React.Component<PageProps, PageState> {



    constructor(props: PageProps) {
        super(props);
        this.state = {
            address: props.address,
            isDefault: props.isDefault,
        }
    }

    /**
     * 提交事件
     */
    _onSubmitAction = () => {
        switch (this.props.type) {
            case 'add': {
                this.props.onAdd({ ...this.props, ...this.state })
            }
                break

            case 'edit': {
                this.props.onEdit({ ...this.props, ...this.state })
            }
                break
            default: {
            }
        }
        tool.navigator.popPage()
    }
    _submitDisabled = () => {
        if (this.state.address && (this.props.address !== this.state.address || this.props.isDefault !== this.state.isDefault)) {
            return false
        } else return true
    }

    _onDeleterAction = () => {
        tool.dialog.openActionSheet({
            title: tool.intl.formatMessage({ id: '提示' }),
            buttons: [
                {
                    label: tool.intl.formatMessage({ id: 'buttonDelete' }),
                    modifier: 'destructive'
                },
                {
                    label: tool.intl.formatMessage({ id: 'buttonCancel' }),
                    icon: 'md-close'
                }
            ]
        })
            .then((index) => {
                if (index === 0) {
                    this.props.onDelete({ ...this.props, ...this.state })
                    tool.navigator.popPage()
                }
            })
    }

    _renderRow = (row: {
        name: string;
        type: 'address'
        value: string;
    } | {
        name: string;
        type: 'isDefault';
        value: boolean;
    }) => {

        return (
            <Ons.ListItem
                key={row.name}
                modifier={`${row.type === 'isDefault' ? '' : 'chevron'} longdivider`}
                tappable={row.type !== 'isDefault'}
                onClick={() => {
                    console.log(row.type)
                    switch (row.type) {
                        case 'address': {
                            tool.navigator.pushPage({
                                key: 'JJ_TextInput',
                                props: {
                                    title: row.name,
                                    type: 'textarea',
                                    defaultInputValue: row.value,//输入初始值
                                    onData: (value) => this.setState({ address: value }),//完成回调
                                    placeholder: tool.intl.formatMessage({ id: '请输入' }) + row.name,//占位

                                }
                            })
                        }
                            break

                        default: {
                        }
                    }
                }}
            >
                <div className="left">{row.name}</div>
                <div className="right">{
                    row.type === 'isDefault'
                        ? <Ons.Switch
                            // disabled={this.props.isDefault}
                            checked={row.value}
                            onChange={e => this.setState({ isDefault: e.value })}
                        />
                        : row.value}</div>
            </Ons.ListItem>
        )
    }

    _bodyView = () => {
        const dataSource = [
            { name: tool.intl.formatMessage({ id: '联系地址' }), type: 'address', value: this.state.address },
            { name: tool.intl.formatMessage({ id: '设置默认' }), type: 'isDefault', value: this.state.isDefault },
        ]
        return (
            <div className='bodyView'>
                <Ons.List
                    dataSource={dataSource}
                    renderRow={this._renderRow}
                />
            </div>
        )
    }


    _footView = () => {
        return (
            <div className='footView'>

                {this.props.type === 'edit' && <Ons.Button
                    className={'deleteBtn'}
                    onClick={() => this._onDeleterAction()}
                >
                    {tool.intl.formatMessage({ id: 'buttonDelete' })}
                </Ons.Button>}

                <Ons.Button
                    className={'submitBtn'}
                    disabled={this._submitDisabled()}
                    onClick={() => this._onSubmitAction()}
                >
                    {tool.intl.formatMessage({ id: '提交' })}
                </Ons.Button>
            </div>
        )
    }

    render() {

        const nav = {
            showLeftIcon: true,
            showRightIcon: false,
            ...this.props
        }

        return (
            <Ons.Page renderToolbar={() => <JJ_Nav {...nav} />}>

                <div id='JJ_UserDeliveryAddressDetails'>
                    {this._bodyView()}
                    {this._footView()}
                </div>
            </Ons.Page>

        );
    }
}

export default JJ_UserDeliveryAddressDetails