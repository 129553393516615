import React from 'react'
import { Button, ActionSheet } from 'react-onsenui'
import { IoIosAdd, IoIosRemove } from 'react-icons/io'


interface PageState {

}


export interface Props {
    nub: number
    onCallNub(nub: number): void
    maxNub: number
    minNub?: number
}

type PageProps = Props

class BaseCount extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {}
    };

    static defaultProps = {

    };


    render() {
        return (
            <div id='BaseCount'>
                <Button
                    className='buttonBox'
                    onClick={() => this.props.onCallNub(this.props.nub - 1)}
                    disabled={this.props.nub === (this.props.minNub === undefined ? 1 : this.props.minNub)}
                >
                    <IoIosRemove className='icon' />
                </Button>

                <span className='number'>{this.props.nub}</span>
                <Button
                    className='buttonBox'
                    onClick={() => this.props.onCallNub(this.props.nub + 1)}
                    disabled={this.props.nub >= this.props.maxNub}
                >
                    <IoIosAdd className='icon' />
                </Button>
                <ActionSheet isOpen={true} />
            </div>
        )

    }
}

export default BaseCount
