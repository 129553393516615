import React from 'react';
import JJ_Components from '../../../components/JJ_Components';
import { GetShopCampaignRes, ThumbnailType } from '../../../api';
import tool from '../../../tool';
import { injectIntl, InjectedIntlProps } from 'react-intl';


interface PageState {

}

export interface Props {

}

type PageProps = Props & GetShopCampaignRes & InjectedIntlProps


class JJ_ShopCampaignDetailHead extends React.Component<PageProps, PageState> {




    componentWillUnmount() {
    }


    constructor(props: PageProps) {
        super(props);
        this.state = {}

    }


    _priceTextView = () => {
        const { campaignPackages = [] } = this.props
        const [campaignPackage] = campaignPackages
        const { price, priceOriginal } = campaignPackage
        return (
            <div
                className='priceTextView'
            >

                <span
                    className='text'
                >
                    {`${tool.intl.formatMessage({
                        id: this.props.type === 'GROUP'
                            ? 'shopCampaignGroupPriceText'
                            : 'shopCampaignNormalPriceText'
                    })}:`}

                    <span
                        className='priceText'
                    >$
                        <span
                            className='price'
                        >{tool.math.initPrice({ price: price }).toFixed(2)}</span>

                    </span>

                </span>

                <span
                    className='text'
                    style={{ marginLeft: 10 }}
                >
                    {`${tool.intl.formatMessage({ id: 'shopCampaignPriceOriginalText' })}:`}

                    <span
                        className='priceOriginal'
                    >${tool.math.initPrice({ price: priceOriginal }).toFixed(2)}</span>
                </span>
            </div>
        )
    }

    _headImage = (thumbnails: ThumbnailType[]) => {
        const [thumbnail = {}] = thumbnails.slice().sort((a, b) => a.meta ? a.meta.height - b.meta.height : 0)
        return (
            <div className='headImage'>
                <JJ_Components.baseBanner>
                    {thumbnails.map((thumbnail, index) => {
                        return (
                            <JJ_Components.baseImage
                                style={tool.image.getImageStyle(thumbnail.meta)}
                                key={index}
                                src={thumbnail.url}
                                className='img'
                            />
                        )
                    })}
                </JJ_Components.baseBanner>
            </div>
        )
    }


    render() {
        const { name, tagline, thumbnails } = this.props
        return (
            <div id='JJ_ShopCampaignDetailHead'>
                {this._headImage(thumbnails)}
                <div style={{ padding: 10 }}>
                    <span className='name'>{name}</span>
                    <span className='tagline'>{tagline}</span>
                    {this._priceTextView()}

                </div>
            </div>
        );
    }
}


export default injectIntl(JJ_ShopCampaignDetailHead)
