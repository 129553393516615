
const gtag = window.gtag || null;

export const screenView = (opts = {}) => {
    const {screenName} = opts
    console.log('[Analytics] screenView', opts)

    // gtag
    if (gtag) {
        gtag('screen_view', {
            screen_name: screenName,
        })
    }
}

export const addToCart = (opts = {}) => {
    const {value, currency, items} = opts
    console.log('[Analytics] addToCart', opts)

    // gtag
    if (gtag) {
        gtag('addToCart', {value, currency, items})
    }
}

export const beginCheckout = (opts = {}) => {
    const {value, currency, items, coupon} = opts
    console.log('[Analytics] beginCheckout', opts)

    // gtag
    if (gtag) {
        gtag('begin_checkout', {value, currency, items, coupon})
    }
}

export const checkoutProgress = (opts = {}) => {
    const {value, currency, items, coupon, checkoutStep, checkoutOption} = opts
    console.log('[Analytics] checkoutProgress', opts)

    // gtag
    if (gtag) {
        gtag('checkout_progress', {
            value, currency, items, coupon,
            checkout_step: checkoutStep,
            checkout_option: checkoutOption,
        })
    }
}

export const purchase = (opts = {}) => {
    const {transactionId, value, currency, tax, shipping, items, coupon} = opts
    console.log('[Analytics] purchase', opts)

    // gtag
    if (gtag) {
        gtag('purchase', {
            transaction_id: transactionId,
            value, currency, tax, shipping, items, coupon
        })
    }
}

export const removeFromCart = (opts = {}) => {
    const {value, currency, items} = opts
    console.log('[Analytics] removeFromCart', opts)

    // gtag
    if (gtag) {
        gtag('remove_from_cart', {value, currency, items})
    }
}

export const selectContent = (opts = {}) => {
    const {items, promotions, contentType, contentId} = opts
    console.log('[Analytics] selectContent', opts)

    // gtag
    if (gtag) {
        gtag('select_content', {
            items, promotions,
            content_type: contentType,
            content_id: contentId
        })
    }
}

export const setCheckoutOption = (opts = {}) => {
    const {checkoutStep, checkoutOption} = opts
    console.log('[Analytics] setCheckoutOption', opts)

    // gtag
    if (gtag) {
        gtag('set_checkout_option', {
            checkout_step: checkoutStep,
            checkout_option: checkoutOption,
        })
    }
}

export const viewItem = (opts = {}) => {
    const {items} = opts
    console.log('[Analytics] viewItem', opts)

    // gtag
    if (gtag) {
        gtag('view_item', {items})
    }
}

export const viewItemList = (opts = {}) => {
    const {items} = opts
    console.log('[Analytics] viewItemList', opts)

    // gtag
    if (gtag) {
        gtag('view_item_list', {items})
    }
}

export const timingComplete = (opts = {}) => {
    const {name, value} = opts
    console.log('[Analytics] timingComplete', opts)

    // gtag
    if (gtag) {
        gtag('timing_complete', {name, value})
    }
}
