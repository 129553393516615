
export const getImageStyle = (meta, maxWidth = window.innerWidth, maxHeight = window.innerHeight * .65) => {
    const maxScale = maxWidth / maxHeight

    const scale = meta && meta.width && meta.height ? meta.width / meta.height : 1
    let width
    let height
    if (scale > maxScale) {
        width = maxWidth
        height = maxWidth / scale
    } else {
        height = maxHeight
        width = maxHeight * scale
    }

    return {width, height}
}