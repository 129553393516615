import moment from 'moment'


export default moment

export const timeFormat =(time,format = 'YYYY-MM-DD hh:mm:ss')=>time&&moment(time).format(format)

export const minute =(time)=>moment(time).minute()
/**
 * 是否现在时间之后
 */
export const isAfter =(time,newTime = new Date())=>moment(newTime).isAfter(moment(time))
/**
 * 是否现在时间之前
 */
export const isBefore =(time,newTime = new Date())=>moment(newTime).isBefore(moment(time))

export const hour =(time)=>moment(time).hour()
export const jjTime = (timeStr, type = 'A') => {
    switch (type) {
        case 'A':
            return moment(timeStr).format('MM-DD HH:mm')

        case 'B':
            return moment(timeStr).format('HH:mm:ss:S')

        case 'C':
            return moment(timeStr).format('YYYY:MM-DD HH:mm')
    }
}













