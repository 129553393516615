import {createStore, applyMiddleware, compose} from 'redux'
import JJ_RootReducer from '../reducers/JJ_RootReducer'
import thunk from 'redux-thunk'

export default function configureStore(initialState) {
    const store = createStore(JJ_RootReducer, initialState,
        // 触发 redux-devtools

        window.devToolsExtension
            ? compose(
            applyMiddleware(thunk),
            window.devToolsExtension(),
            )
            : compose(
            applyMiddleware(thunk),
            )
)

    return store
}










