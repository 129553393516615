import React from 'react'
import *as Ons from 'react-onsenui'

class BaseSelectIcon extends React.Component {

    constructor(props) {
        super(props);
    }

    static defaultProps = {
        isSelect:false,
        onPress:(isSelect)=>{},
        id:'',
        className:'',
    };


    render() {
        return (
            <Ons.Radio
                className='checked'
                // onChange={event => this.props.onPress(this.props.isSelect)}
                onClick={event => this.props.onPress(this.props.isSelect)}
                modifier='material'
                checked={this.props.isSelect}//选中
            />
        )
    }
}

export default BaseSelectIcon
