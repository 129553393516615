

import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import JJ_Components from '../../../components/JJ_Components'
import *as ActionUserCoupon from '../../../actions/ActionUserCoupon'
import { GetUserCouponListParameters, PostUserCouponUsableParameters, GetUserCouponRes } from '../../../api'
import tool from '../../../tool'
import JJ_UserCouponListItem from './JJ_UserCouponListItem'
import *as Ons from 'react-onsenui'


interface PageState {
    pageKey: string
}

export type TypeProps =
    {
        type: 'getUserCouponList'
        params: GetUserCouponListParameters
    }
    | {
        type: 'postUserCouponUsable'
        params: PostUserCouponUsableParameters
    }
    | {
        type: 'postUserCouponFeed'
    }

export type SelectProps =
    {
        switchSelect: false
    }
    | {
        switchSelect: true
        selectUserCoupon?: GetUserCouponRes
        onSelect(userCoupon: GetUserCouponRes | undefined): void
    }



export type Props = {
    renderFooter?: ReactElement
    hideFooter?: boolean
} & TypeProps & SelectProps



type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    userCoupon: ActionUserCoupon.ActionUserCouponRes
}

const mapState = (state: RootState) => ({
    userCoupon: state.userCoupon,
})

const mapDispatch = {
    getUserCouponList: ActionUserCoupon.getUserCouponList,
    postUserCouponUsable: ActionUserCoupon.postUserCouponUsable,
    postUserCouponFeed: ActionUserCoupon.postUserCouponFeed,
}

export const connector = connect(
    mapState,
    mapDispatch
)

class JJ_UserCouponList extends React.Component<PageProps, PageState> {


    constructor(props: PageProps) {
        super(props);
        this.state = {
            pageKey: `${new Date()}`
        }

    }


    componentWillReceivePropsk = (nextProps: PageProps) => {
        if (nextProps.type !== this.props.type) {
            this.setState({
                pageKey: `${new Date()}`
            })
        }
    }

    _initData = async (): Promise<void> => {
        try {

        } catch (error) {
            console.error(error)
        }
    }

    _getUserCouponList = async (params: GetUserCouponListParameters) => {
        const res = await this.props.getUserCouponList(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'SHOP_ORDER_NOT_FOUND': {

            }
        }
        throw new Error(res.kind)
    }



    _postUserCouponUsable = async (params: PostUserCouponUsableParameters) => {
        const res = await this.props.postUserCouponUsable(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'SHOP_ORDER_NOT_FOUND': {

            }
        }
        throw new Error(res.kind)
    }


    _postUserCouponFeed = async () => {
        const res = await this.props.postUserCouponFeed()
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'SHOP_ORDER_NOT_FOUND': {

            }
        }
        throw new Error(res.kind)
    }


    _onRenderRow = (rowData: GetUserCouponRes | undefined, sectionID: React.ReactText, rowID: React.ReactText) => {


        if (rowData) {
            const userCoupon = this.props.userCoupon.list[rowData.id]
            return (
                <JJ_UserCouponListItem
                    key={`${sectionID}${rowID}`}
                    userCoupon={userCoupon}
                    selectProps={
                        this.props.switchSelect
                            ? {
                                switchSelect: true,
                                selectUserCoupon: this.props.selectUserCoupon,
                                onSelect: this.props.onSelect,
                            }
                            : {
                                switchSelect: false
                            }}
                />
            )
        } else {
            return (
                <div
                    id='JJ_UserCouponListItem'
                    key={`${sectionID}${rowID}`}

                >
                    <Ons.ListItem
                        className={`listItem`}
                        tappable = {true}
                        key={`${sectionID}${rowID}`}
                        modifier={'nodivider'}
                    >
                        <div
                            className='center'
                        >
                            <span
                                className='centerName'
                            >
                                {tool.intl.formatMessage({ id: 'JJ_UserCouponListItem[no]' })}
                            </span>
                        </div>
                        <div className='left'>
                            <Ons.Radio
                                checked={this.props.switchSelect && this.props.selectUserCoupon === undefined}
                                onChange={() => this.props.switchSelect && this.props.onSelect(undefined)}
                                modifier='material'
                            />
                        </div>
                    </Ons.ListItem>
                </div>


            )
        }

    }

    _view = () => {

        return (
            <div
                id='JJ_UserCouponList'
            >
                <JJ_Components.baseListView
                    className='baseListView'
                    renderSeparator={(sectionID, rowID) => (<div style={{ height: 10 }} key={`${sectionID}${rowID}`} />)}
                    noDataViewText={`${tool.intl.formatMessage({ id: 'userCouponList[noDataViewText]' })}`}
                    renderRow={this._onRenderRow}
                    renderFooter={this.props.renderFooter}
                    hideFooter={this.props.hideFooter}
                    onLoadData={params => {
                        switch (this.props.type) {
                            case 'getUserCouponList': {
                                return this._getUserCouponList({ ...this.props.params, ...params })

                            }
                            case 'postUserCouponFeed': {
                                return this._postUserCouponFeed()
                            }
                            case 'postUserCouponUsable': {
                                return this._postUserCouponUsable({ ...this.props.params, ...params })
                                    .then(data => {
                                        const coupons: (GetUserCouponRes | undefined)[] = data.data
                                        if (this.props.switchSelect && this.props.selectUserCoupon) {
                                            coupons.unshift(this.props.selectUserCoupon)
                                        }
                                        coupons.unshift(undefined)
                                        return data
                                    })
                            }
                        }
                    }}
                />

            </div>
        )
    }

    render() {

        return (
            <JJ_Components.boxPage
                key={`${this.state.pageKey}pageKey`}
                loadData={this._initData}
            >
                {this._view()}
            </JJ_Components.boxPage>

        )
    }
}



export default connector(JJ_UserCouponList)


