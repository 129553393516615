import React, { ReactElement } from 'react'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import *as JJ_UserCouponList from './PageItem/JJ_UserCouponList'
import tool from '../../tool'
import { GetUserCouponRes } from '../../api/api-res-type'


interface PageState {
    selectUserCoupon?: GetUserCouponRes
}

export type SelectData = {
    switchSelect: true
    onOk(userCoupon?: GetUserCouponRes): void
    selectUserCoupon?: GetUserCouponRes
} | {
    switchSelect: false
}
export type Props = {
    listProps: JJ_UserCouponList.TypeProps
    renderFooter?: ReactElement
    hideFooter?: boolean
    title?: string
} & SelectData

type PageProps = Props


class JJ_UserCoupon extends React.Component<PageProps, PageState>{

    constructor(props: PageProps) {
        super(props);
        this.state = {
            selectUserCoupon: props.switchSelect ? props.selectUserCoupon : undefined
        }

    }

    componentDidMount() {


    }
    componentWillUnmount() {
        this.setState = () => { }
    }

    render() {

        const nav = {
            showLeftIcon: true,
            title: this.props.title || tool.intl.formatMessage({ id: 'userCoupon[title]' }),
        }

        const footView = () => {
            if (this.props.switchSelect) {
                const onOk = this.props.onOk
                return (
                    <div
                        className='footView'
                    >
                        <Ons.Button
                            className={'footViewButton'}
                            modifier='large'
                            // disabled={!Boolean(this.state.selectUserCoupon)}
                            onClick={() => {
                                onOk(this.state.selectUserCoupon)
                                tool.navigator.popPage()
                            }}
                        >
                            {tool.intl.formatMessage({ id: 'userCouponList[ButtonNotSelect]' })}
                        </Ons.Button>
                    </div>
                )
            } else {
                return null
            }

        }

        const selectProps: JJ_UserCouponList.SelectProps = this.props.switchSelect
            ? {
                switchSelect: true,
                selectUserCoupon: this.state.selectUserCoupon,
                onSelect: (userCoupon) => this.setState({ selectUserCoupon: userCoupon }),
            }
            : {
                switchSelect: false
            }

        return (


            <Ons.Page
                renderToolbar={() => <JJ_Nav {...nav} />}
            >

                <div
                    id='JJ_UserCoupon'
                >
                    <JJ_UserCouponList.default
                        {...this.props.listProps}
                        {...selectProps}
                        renderFooter={this.props.renderFooter}
                        hideFooter={this.props.hideFooter}
                    />
                    {footView()}
                </div>

            </Ons.Page>


        )

    }
}

export default JJ_UserCoupon


