
import React, { Component } from 'react';

interface PageState {

}

export interface Props {
    className?: string
    progress: number
    disabled?: boolean
}
type PageProps = Props

class BaseProgressView extends Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {


        }

    };



    componentWillUnmount() {

    }



    render() {
        return (
            <div
                id={'BaseProgressView'}
                className={this.props.className || ''}
                style={{
                    opacity: this.props.disabled ? 0.4 : 1,

                }
                }
            >
                <div
                    className={'BaseProgressSon'}
                    style={{
                        width: `${this.props.progress > 100 ? 100 : this.props.progress}%`,
                    }}
                />
                <div
                    className={'BaseProgressChildren'}
                >
                    {this.props.children}
                </div>
            </div >
        );
    }
}

export default BaseProgressView


