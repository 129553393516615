import boxBtn from './Box/BoxBtn'
import boxPage from './Box/BoxPage'
import ModalImg from './Modal/ModalImg'
import baseListView from './Base/BaseListView'
import baseImage from './Base/BaseImage'
import baseBanner from './Base/BaseBanner'
import baseBannerAdvert from './Base/BaseBannerAdvert'
import baseHeadTitle from './Base/BaseHeadTitle'
import baseCount from './Base/BaseCount'
import baseSelectIcon from './Base/BaseSelectIcon'
import baseCountdownText from './Base/BaseCountdownText'
import baseProgressView from './Base/BaseProgressView'
import modalPayment from './Modal/ModalPayment'
export default {
    boxBtn,
    boxPage,
    ModalImg,
    baseListView,
    baseImage,
    baseBanner,
    baseHeadTitle,
    baseCount,
    baseSelectIcon,
    baseCountdownText,
    modalPayment,
    baseProgressView,
    baseBannerAdvert,
}