
import *as GoogleLibphonenumber from 'google-libphonenumber'

const phoneUtil = GoogleLibphonenumber.PhoneNumberUtil.getInstance()

/**
 *解析字符串并以原始缓冲区格式返回，同时保留原始输入值。
 */
export const parseAndKeepRawInput = (numberToParse: string) => phoneUtil.parseAndKeepRawInput(numberToParse)

/**
 *测试电话号码是否对某个地区有效。
 */
export const isValidNumberForRegion = (number: GoogleLibphonenumber.PhoneNumber, regionCode: string | undefined) => phoneUtil.isValidNumberForRegion(number, regionCode)

/**
 *返回电话号码所在的区域。
 */
export const getRegionCodeForNumber = (number: GoogleLibphonenumber.PhoneNumber) => phoneUtil.getRegionCodeForNumber(number)


// console.log(1111111111,parseAndKeepRawInput('+852-21234567'))
// console.log(2222222,parseAndKeepRawInput('+852-21234567').values_[1])
// console.log(5555555,parseAndKeepRawInput('18029777744'))
// console.log(5555555,getRegionCodeForNumber(parseAndKeepRawInput('')))