
import config from '../config/config';
const API_URL = config.api

export interface ApiConfigType {
  url: string
  timeout: number
}

export const ApiConfig: ApiConfigType = {
  url: API_URL,
  timeout: 10000,
}
