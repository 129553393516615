import React from 'react';

import *as Ons from 'react-onsenui'
import { Boxe, GetUserRes, GetAreaKioskRes } from '../../../api/api-res-type';
import tool from '../../../tool';
import moment from 'moment'
import ModalQr from '../../../components/Modal/ModalQr';


interface PageState {
    qrModal: {
        show: boolean
        key: string
    }

}

export interface Props {
    areaKiosk?: GetAreaKioskRes
    boxe: Boxe
    style?: React.CSSProperties
    user: GetUserRes
}

type PageProps = Props

class JJ_ShopOrderBoxItem extends React.Component<PageProps, PageState> {


    componentWillUnmount() {
    }

    constructor(props) {
        super(props);
        this.state = {
            qrModal: {
                show: false,
                key: `${Date.now()}`
            }
        }
    }


    _getBoxeStatusText = (status: Boxe['status']) => {
        /**
         * PENDING: 未整理好貨品, 默認
         * DELIVERED: 已送到自提櫃, 等待取貨
         * COMPLETED: 已取貨
         * RECALLED: 沒取貨, 已過期
         */
        switch (status) {

            case 'PENDING': {
                return tool.intl.formatMessage({ id: 'boxeStatus[PENDING]' })
            }
            case 'DELIVERED': {
                return tool.intl.formatMessage({ id: 'boxeStatus[DELIVERED]' })
            }
            case 'COMPLETED': {
                return tool.intl.formatMessage({ id: 'boxeStatus[COMPLETED]' })
            }
            case 'RECALLED': {
                return tool.intl.formatMessage({ id: 'boxeStatus[RECALLED]' })
            }
        }

    }


    /**
     * 是否显示取货最后30分钟时间
     */
    _isShowPickupEnd = (boxe: Boxe) => {
        return Boolean(boxe.pickupEndAt && moment(boxe.pickupEndAt).isBefore(moment().add(30, 'minutes')))
    }

    render() {

        return (this.props.areaKiosk && this.props.boxe.pickupSlot) && (
            <div
                id='JJ_ShopOrderBoxItem'
                style={this.props.style}
            >
                <div className="contentView">
                    <div className="statusView">
                        {this._getBoxeStatusText(this.props.boxe.status)}
                    </div>
                    <div className="kioskView">
                        {this.props.areaKiosk && this.props.areaKiosk.name}
                        <span className="divider">/</span>
                        {this.props.boxe.pickupSlot}
                    </div>
                </div>
                {((this.props.boxe.status === 'DELIVERED' || this.props.boxe.status === 'COMPLETED') && this.props.boxe.pickupQrcode) && (
                    <>
                        <div className="qrcodeView">
                            <Ons.Button
                                modifier='large outline'
                                onClick={() => {
                                    this.setState({
                                        qrModal: {
                                            ...this.state.qrModal,
                                            show: true,
                                            key: `${Date.now()}`,
                                        },
                                    })
                                }}
                            >
                                {tool.intl.formatMessage({ id: 'boxeStatusQrCodeOpenButton' })}
                            </Ons.Button>
                        </div>
                        <ModalQr
                            key={this.state.qrModal.key}
                            show={this.state.qrModal.show}
                            onClose={() => {
                                this.setState({
                                    qrModal: {
                                        ...this.state.qrModal,
                                        show: false,
                                    },
                                })
                            }}
                            qrCode={this.props.boxe.pickupQrcode}
                            title={tool.intl.formatMessage({ id: 'boxeStatusQrCodeModalTitle' })}
                            info={`${this.props.areaKiosk && this.props.areaKiosk.name} > ${this.props.boxe.pickupSlot}`}
                        />
                    </>
                )}
            </div>
        )
    }
}


export default JJ_ShopOrderBoxItem
