import { Toast } from 'antd-mobile';
import * as ons from "onsenui/js/onsenui";
import tool from ".";


/**
 * 成功
 */
export const success = ({
    content = '',
    duration = 3,
    onClose = () => {
    },
    mask = false,
}) => {
    return Toast.success(content, duration, onClose, mask)
};

/**
 * 失败
 */
export const fail = ({
    content = '',
    duration = 3,
    onClose = () => {
    },
    mask = false,
}) => {
    return Toast.fail(content, duration, onClose, mask)
};

/**
 * 信息
 */
export const info = ({
    content = '',
    duration = 3,
    onClose = () => {
    },
    mask = false,
}) => {
    return Toast.info(content, duration, onClose, mask)
};

/**
 * 加载
 */
export const loading = ({
    content = tool.intl.formatMessage({ id: 'loadingMessage' }),
    duration = 0,
    onClose = () => {
    },
    mask = true,
}) => {
    return Toast.loading(content, duration, onClose, mask)
};


/**
 * 离线
 */
export const offline = ({
    content = '',
    duration = 3,
    onClose = () => {
    },
    mask = false,
}) => {
    return Toast.offline(content, duration, onClose, mask)
};

/**
 * 隐藏
 */
export const hide = () => {
    return Toast.hide()
};


/**
 * 吐司
 */
export const toast = ({
    timeout = 3000,
    content = '',
    ...options
}) => ons.notification.toast(content, { timeout, ...options });


