import *as JJ_Request from '../JJ_Request'


/**
 * 获取支付方式
 */
export const getPayment = ({
                            paymentId = 'stripe',
                            amount = 10000,
                            ...body
                        }) => {
    const code = `${JJ_Request.code.payment}/${paymentId}`
    const newBody = {
        amount,
        ...body,
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}



/**
 * 获取支付方式
 */
export const getPaymentList = ({
                               amount = 10000,
                               ...body
                           }) => {
    const code = `${JJ_Request.code.payment}`
    const newBody = {
        amount,
        ...body,
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}
