import React from 'react'
import queryString from 'query-string';
import { connect, ConnectedProps } from 'react-redux'
import *as ActionCampaign from "../../actions/ActionCampaign";
import JJ_ShopCampaignDetailHead from "./PageItem/JJ_ShopCampaignDetailHead";
import JJ_Nav from '../SuperComponent/JJ_Nav'
import JJ_ShopCampaignDetailFoot from "./PageItem/JJ_ShopCampaignDetailFoot";
import JJ_ShopCampaignDetailBody from "./PageItem/JJ_ShopCampaignDetailBody";
import JJ_Components from '../../components/JJ_Components'
import *as Ons from 'react-onsenui'
import { injectIntl, InjectedIntlProps } from 'react-intl';
import tool from '../../tool';
import api, { GetShopCampaignParameters, GetShopCartCampaignPackageListParameters, GetShopCartCampaignPackageParameters, PostShopCartCampaignPackageParameters, PutShopCartCampaignPackageParameters, GetShopCartCampaignPackageRes, PostShopOrderParameters, PostShopOrderFromCartParameters, GetShopCampaignRes } from '../../api';
import JJ_ShopCampaignShopModal from './PageItem/JJ_ShopCampaignShopModal';
import *as ActionCartCampaignPackage from '../../actions/ActionCartCampaignPackage';
import *as ActionUser from '../../actions/ActionUser';
import JJ_ShopCartCampaignPackagesModal from './PageItem/JJ_ShopCartCampaignPackagesModal';
import moment from 'moment';


interface PageState {



    shopModal: {
        key: string
        show: boolean
        type: '立刻购买' | '加入购物车'
    },
    cartCampaignPackagesModal: {
        show: boolean
        key: string
    }
}

export interface Props {
    id: string
    moduleId: string
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    campaign: ActionCampaign.ActionCampaignRes,
    user: ActionUser.ActionUserRes,
    cartCampaignPackage: ActionCartCampaignPackage.ActionCartCampaignPackageRes
}

const mapState = (state: RootState) => ({
    campaignData: state.campaign,
    user: state.user,
    cartCampaignPackage: state.cartCampaignPackage,
})

const mapDispatch = {
    getShopCampaign: ActionCampaign.getShopCampaign,
    getShopCartCampaignPackage: ActionCartCampaignPackage.getShopCartCampaignPackage,
    getShopCartCampaignPackageList: ActionCartCampaignPackage.getShopCartCampaignPackageList,
}


export const connector = connect(
    mapState,
    mapDispatch
)


class JJ_ShopCampaignDetails extends React.Component<PageProps, PageState> {



    componentDidMount() {
        tool.analytics.screenView({ screenName: 'ShopCampaignDetails' })
    }

    static defaultProps = {};


    constructor(props: PageProps) {
        super(props);
        this.state = {

            shopModal: {
                key: `$${new Date()}`,
                show: false,
                type: '立刻购买',
            },
            cartCampaignPackagesModal: {
                show: false,
                key: `$${new Date()}`
            }
        }
    }

    /**
    * 过滤商城活动购物车
    */
    _filterShopCartCampaignList = (campaignId: string) => {
        const list = Object.keys(this.props.cartCampaignPackage.list)
            .map(key => this.props.cartCampaignPackage.list[key])
            // .filter(cartCampaignPackage => cartCampaignPackage.campaignId === campaignId)
        return list
    }


    _initData = async () => {
        try {
            await this._getShopCampaign({ campaignId: this.props.id, queryCampaignPackage: true })
            const getShopCartCampaignPackageListRes = await this._getShopCartCampaignPackageList({ count: 100000 })
            getShopCartCampaignPackageListRes.data.filter(item => item.campaignId === this.props.id)

            return
        } catch (error) {
            throw new Error(error)
        }
    }

    _getShopCampaign = async (params: GetShopCampaignParameters) => {
        const res = await this.props.getShopCampaign(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }
    }


    _getShopCartCampaignPackageList = async (params: GetShopCartCampaignPackageListParameters) => {
        const res = await this.props.getShopCartCampaignPackageList(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }
    }

    _getShopCartCampaignPackage = async (params: GetShopCartCampaignPackageParameters) => {
        const res = await this.props.getShopCartCampaignPackage(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }
    }

    _postShopCartCampaignPackage = async (params: PostShopCartCampaignPackageParameters) => {
        const res = await api.postShopCartCampaignPackage(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'CAMPAIGN_NOT_AVAILABLE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignNotAvailable' }) })
                }
                    break
                case 'CAMPAIGN_NOT_FOUND': {

                }
                    break
                case 'INVALID_PARAMS': {

                }
                    break
                case 'PACKAGE_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettailsPackageNotFound' }) })

                }
                    break
                case 'NOT_ENOUGH_INVENTORY': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[NOT_ENOUGH_INVENTORY]' }) })
                    this._initData()
                }
                    break
            }
            throw new Error(res.kind)
        }
    }


    _putShopCartCampaignPackage = async (params: PutShopCartCampaignPackageParameters) => {
        const res = await api.putShopCartCampaignPackage(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break

            }
            throw new Error(res.kind)
        }
    }



    _postShopOrder = async (params: PostShopOrderParameters) => {
        const res = await api.postShopOrder(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'CAMPAIGN_NOT_AVAILABLE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignNotAvailable' }) })
                }
                    break
                case 'COUPON_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[COUPON_NOT_FOUND]' }) })
                }
                    break
                case 'DELIVERY_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[DELIVERY_NOT_FOUND]' }) })
                }
                    break
                case 'EXCEED_PACKAGE_AMOUNT_LIMIT': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[EXCEED_PACKAGE_AMOUNT_LIMIT]' }) })
                }
                    break
                case 'EXCEED_PACKAGE_USER_AMOUNT_LIMIT': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[EXCEED_PACKAGE_USER_AMOUNT_LIMIT]' }) })
                }
                    break
                case 'INVALID_CAMPAIGN_PACKAGE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[INVALID_CAMPAIGN_PACKAGE]' }) })
                }
                    break
                case 'INVALID_PARAMS': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[INVALID_PARAMS]' }) })
                }
                    break
                case 'NOT_ENOUGH_INVENTORY': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[NOT_ENOUGH_INVENTORY]' }) })
                    this._initData()
                }
                    break
                case 'NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[NOT_FOUND]' }) })
                }
                    break
                case 'RECIPIENT_ADDRESS_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[RECIPIENT_ADDRESS_NOT_FOUND]' }) })
                }
                    break
                case 'RECIPIENT_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[RECIPIENT_NOT_FOUND]' }) })
                }
                    break
                case 'USER_COUPON_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[USER_COUPON_NOT_FOUND]' }) })
                }
                    break
                case 'USER_COUPON_NOT_USABLE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[USER_COUPON_NOT_USABLE]' }) })
                }
                    break

            }
            throw new Error(res.kind)
        }
    }

    _postShopOrderFromCart = async (params: PostShopOrderFromCartParameters) => {
        const res = await api.postShopOrderFromCart(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'CAMPAIGN_NOT_AVAILABLE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignNotAvailable' }) })
                }
                    break
                case 'COUPON_NOT_FOUND': {

                }
                    break
                case 'DELIVERY_NOT_FOUND': {

                }
                    break
                case 'EXCEED_PACKAGE_AMOUNT_LIMIT': {

                }
                    break
                case 'EXCEED_PACKAGE_USER_AMOUNT_LIMIT': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[EXCEED_PACKAGE_USER_AMOUNT_LIMIT]' }) })

                }
                    break
                case 'INVALID_CAMPAIGN_PACKAGE': {

                }
                    break
                case 'INVALID_PARAMS': {

                }
                    break
                case 'NOT_ENOUGH_INVENTORY': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[NOT_ENOUGH_INVENTORY]' }) })
                    this._initData()
                }
                    break
                case 'NOT_FOUND': {

                }
                    break
                case 'RECIPIENT_ADDRESS_NOT_FOUND': {

                }
                    break
                case 'RECIPIENT_NOT_FOUND': {

                }
                    break
                case 'USER_COUPON_NOT_FOUND': {

                }
                    break
                case 'USER_COUPON_NOT_USABLE': {

                }
                    break
                case 'CAMPAIGN_NOT_AVAILABLE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignNotAvailable' }) })
                }
                    break

                case 'CAMPAIGN_NOT_FOUND': {

                }
                    break


            }
            throw new Error(res.kind)
        }
    }




    /**
     * 加入购物车
     */
    _onAddShopCart = async (params: PostShopCartCampaignPackageParameters) => {
        try {
            await this._checkLogin()
            tool.message.loading({})
            const postShopCartCampaignPackageRes = await this._postShopCartCampaignPackage(params)
            await this._initData()
            tool.message.hide()
            this.setState({ shopModal: { ...this.state.shopModal, show: false } })
        } catch (error) {
            tool.message.hide()
            this.setState({ shopModal: { ...this.state.shopModal, show: false } })
            console.log(error)
        }
    }

    _checkLogin = async (): Promise<void> => {
        if (this.props.user.class !== 'BASIC') {
            tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignMemberLogin' }) })

            // check webview
            const urlParams = queryString.parse(location.search);
            const isWebview = urlParams.view === 'webview';

            if (!isWebview) {
                tool.navigator.pushPage({
                    key: 'JJ_LoginArea',
                    props: {
                        action: { type: 'postAuthSmsRequestNew' },
                        localStorageAction: {
                            key: 'pushCampaignDetails',
                            data: { id: this.props.id }
                        },
                    }
                })
            }

            throw new Error(tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignMemberLogin' }))
        }
    }

    /**
     * 创建订单
     */
    _onPostOrder = async (params: PostShopOrderParameters,campaign:GetShopCampaignRes) => {
        try {
            await this._checkLogin()
            tool.message.loading({})
            const postShopOrderRes = await this._postShopOrder(params)
            await this._initData()
            tool.message.hide()
            this.setState({ shopModal: { ...this.state.shopModal, show: false } })
            tool.navigator.pushPage({
                key: 'JJ_ShopOrderDetails', props: {
                    showPaymentDescription: campaign.type==='GROUP',
                    orderId: postShopOrderRes.id,
                    moduleId: this.props.moduleId,
                    onDelete: orderId => {
                        this._initData()
                    },
                    onCancel: orderId => {
                        this._initData()
                    },
                }
            })

        } catch (error) {
            tool.message.hide()
            this.setState({ shopModal: { ...this.state.shopModal, show: false } })
            console.log(error)
        }
    }
    
    /**
     * 创建购物车订单
     */
    _onPostShopOrderFromCart = async (params: PostShopOrderFromCartParameters,campaign:GetShopCampaignRes) => {
        try {
            await this._checkLogin()
            tool.message.loading({})
            const postShopOrderFromCartRes = await this._postShopOrderFromCart(params)
            await this._initData()
            tool.message.hide()
            this.setState({ cartCampaignPackagesModal: { ...this.state.cartCampaignPackagesModal, show: false } })
            tool.navigator.pushPage({
                key: 'JJ_ShopOrderDetails', props: {
                    showPaymentDescription: campaign.type==='GROUP',
                    orderId
                        : postShopOrderFromCartRes.id,
                    moduleId: this.props.moduleId,
                    onDelete: orderId => {
                        this._initData()
                    },
                    onCancel: orderId => {
                        this._initData()
                    },
                }
            })
        } catch (error) {
            tool.message.hide()
            this.setState({ cartCampaignPackagesModal: { ...this.state.cartCampaignPackagesModal, show: false } })
            console.log(error)
        }
    }


    /**
     * 倒计时视图
     */
    _countdownView = (campaign: GetShopCampaignRes) => {
        switch (campaign.type) {
            case 'FLASH':
            case 'NORMAL':
            case 'GROUP': {

                switch (campaign.status) {
                    case 'ONGOING': {
                        // 進行中
                        if (campaign.type !== 'NORMAL' && campaign.endAt) {
                            return (
                                <div
                                    className='countdownView'
                                >
                                    <span
                                        className={'countdownViewTime'}
                                    >

                                        {`${tool.intl.formatMessage({ id: 'shopCampaignCountdownTime' }, {
                                            datetime: moment(campaign.endAt).format('YYYY-MM-DD HH:mm'),
                                        })}  `}

                                    </span>
                                </div>

                            )
                        }
                        return null
                    }
                    case 'UPCOMING': {
                        // 即將開始
                        if (campaign.beginAt) {
                            return (
                                <div
                                    className='countdownView'
                                >
                                    <span
                                        className={'countdownViewTime'}
                                    >

                                        {`${tool.intl.formatMessage({ id: 'shopCampaignCountdownTime' }, {
                                            datetime: moment(campaign.beginAt).format('YYYY-MM-DD HH:mm'),
                                        })}  `}

                                        {/* {`${tool.intl.formatMessage({ id: 'shopCampaignRemainingTime' })}  `}
                                        <JJ_Components.baseCountdownText
                                            key={campaign.type}
                                            beginDate={new Date(campaign.beginAt)}
                                            endDate={new Date()}
                                            onEnd={() => {
                                                this._initData()
                                            }}
                                        /> */}
                                    </span>
                                </div>
                            )
                        }
                        return null
                    }
                    default: return null
                }
            }
            case 'NORMAL': {
                return null
            }
        }
    }


    _topView = (campaign: GetShopCampaignRes) => {

        const getButtonText = (campaign: GetShopCampaignRes) => {

            switch (campaign.type) {
                case 'NORMAL': {

                    switch (campaign.status) {
                        case 'ONGOING': {
                            return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[ONGOING]' })
                        }
                        case 'UPCOMING': {
                            return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[UPCOMING]' })
                        }
                        case 'ENDED': {
                            return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[ENDED]' })
                        }
                        default: return ''
                    }

                }

                case 'GROUP': {

                    const groupConfirmedAmount = campaign.groupConfirmedAmount

                    switch (campaign.status) {
                        case 'CANCELED': {
                            return tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[CANCELED]' })
                        }
                        case 'CONFIRMED': {
                            return tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[CONFIRMED]' })
                        }
                        case 'ENDED': {
                            return tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[ENDED]' })
                        }
                        case 'ONGOING': {
                            return `${tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[ONGOING]' })}: ${groupConfirmedAmount} ${tool.intl.formatMessage({ id: 'shopCampaignGroupConfirmedAmountUnit' })}`
                        }
                        case 'UPCOMING': {
                            return tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[UPCOMING]' })
                        }
                    }

                }
                case 'FLASH': {

                    switch (campaign.status) {
                        case 'ONGOING': {
                            return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[ONGOING]' })
                        }
                        case 'UPCOMING': {
                            return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[UPCOMING]' })
                        }
                        case 'ENDED': {
                            return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[ENDED]' })
                        }
                        default: return ''
                    }

                }
            }



        }






        switch (campaign.type) {

            case 'GROUP': {

                const groupConfirmedAmount = campaign.groupConfirmedAmount
                const groupOrderedAmount = campaign.groupOrderedAmount
                const progress = parseInt(`${groupOrderedAmount / groupConfirmedAmount * 100}`) || 0


                return (
                    <div
                        className='topView'
                    >
                        <JJ_Components.baseProgressView
                            className='progressView'
                            progress={progress}
                        >
                            <span
                                className='baseProgressSubText'
                            >
                                {getButtonText(campaign)}{`: ${groupOrderedAmount} / ${groupConfirmedAmount}`}
                            </span>
                        </JJ_Components.baseProgressView>
                        {this._countdownView(campaign)}
                    </div>
                )
            }
            case 'FLASH':
            case 'NORMAL': {



                return (campaign.status !== 'ONGOING' || campaign.endAt) && (
                    <div
                        className='topView'
                    >
                        {campaign.status !== 'ONGOING' && (
                            <JJ_Components.baseProgressView
                                className='progressView'
                                progress={100}
                            >
                                <span
                                    className='baseProgressSubText'
                                >
                                    {getButtonText(campaign)}
                                </span>
                            </JJ_Components.baseProgressView>
                        )}
                        {this._countdownView(campaign)}
                    </div>
                )
            }
        }



    }

    render() {
        const campaign = this.props.campaignData.list[this.props.id]
        const nav = {
            showLeftIcon: true,
            showRightIcon: false,
            ...this.props
        }

        return (

            <Ons.Page renderToolbar={() => <JJ_Nav {...nav} />}>
                <JJ_Components.boxPage
                    loadData={this._initData}
                >
                    {campaign && (
                        <div id='JJ_ShopCampaignDetails'>
                            {campaign && campaign.type === 'GROUP' && this._topView(campaign)}
                            <div className='scroll'>
                                {campaign && campaign.type === 'NORMAL' && this._topView(campaign)}
                                <JJ_ShopCampaignDetailHead
                                    {...this.props.campaignData.list[this.props.id]}
                                />
                                <JJ_ShopCampaignDetailBody
                                    {...this.props.campaignData.list[this.props.id]}
                                />
                            </div>
                            {
                                campaign.status === 'ONGOING' && (
                                    <JJ_ShopCampaignDetailFoot
                                        badge={this._filterShopCartCampaignList(this.props.id).length}
                                        campaing={this.props.campaignData.list[this.props.id]}
                                        callBuy={() => this.setState({ shopModal: { show: true, type: '立刻购买', key: `${new Date()}` } })}////购买回调
                                        callJoin={() => this.setState({ shopModal: { show: true, type: '加入购物车', key: `${new Date()}` } })}//加入购物车回调
                                        callShoppingCart={() => this.setState({ cartCampaignPackagesModal: { ...this.state.cartCampaignPackagesModal, show: true } })}//购物车回调
                                    />
                                )
                            }
                            <JJ_ShopCampaignShopModal
                                campaing={this.props.campaignData.list[this.props.id]}
                                key={this.state.shopModal.key + 'JJ_ShopCampaignShopModal'}
                                show={this.state.shopModal.show}
                                type={this.state.shopModal.type}
                                onCallShow={() => this.setState({ shopModal: { ...this.state.shopModal, show: false } })}
                                onCallAction={(data) => {

                                    switch (data.type) {
                                        case '加入购物车': {
                                            this._onAddShopCart({
                                                campaignId: data.campaignId,
                                                campaignPackageId: data.campaignPackageId,
                                                amount: data.amount,
                                            })
                                        }
                                            break
                                        case '立刻购买': {
                                            this._onPostOrder({
                                                campaignPackages: [{
                                                    campaignId: data.campaignId,
                                                    campaignPackageId: data.campaignPackageId,
                                                    amount: data.amount,
                                                }]
                                            },campaign)
                                        }
                                            break
                                    }
                                }
                                }

                            />
                            <JJ_ShopCartCampaignPackagesModal
                                key={this.state.cartCampaignPackagesModal.key + 'JJ_ShopCartCampaignPackagesModal'}
                                show={this.state.cartCampaignPackagesModal.show}
                                onClose={() => this.setState({ cartCampaignPackagesModal: { ...this.state.cartCampaignPackagesModal, show: false } })}
                                campaignId={this.props.id}
                                onPay={(cartCampaignPackageIds) => this._onPostShopOrderFromCart({
                                    cartCampaignPackageIds: cartCampaignPackageIds,
                                },campaign)}
                            />
                        </div>
                    )}

                </JJ_Components.boxPage>

            </Ons.Page>

        );
    }
}



export default connector(injectIntl(JJ_ShopCampaignDetails))
