/**
 * Created by gaocai on 16/9/19.
 */

import { combineReducers } from 'redux'
import ActionType from '../actions/ActionType';
import { ActionAreaRes, ActionAreaModuleRes } from '../actions/ActionArea';
import { ActionUserRes } from '../actions/ActionUser';
import { ActionCartCampaignPackageRes } from '../actions/ActionCartCampaignPackage';
import { ActionCampaignRes } from '../actions/ActionCampaign';
import { ActionOrderRes } from '../actions/ActionOrder';
import { ActionUserShopDeliveryAddressRes } from '../actions/ActionUserShopDeliveryAddress';
import { ActionShopDeliveryRes } from '../actions/ActionShopDelivery';
import { ActionModalRes } from '../actions/ActionModal';
import { ActionUserCouponRes } from '../actions/ActionUserCoupon';
import config from '../config/config';
const getLang = (lang: any) => {
    console.log('lang', lang)
    switch (lang) {
        case 'zh-cn':
        case 'zh-hans':
        case 'zh-hans-cn':
        case 'zh-hant':
        case 'zh-hant-cn':
        case 'zh-hk-cn':
        case 'cn':
            return 'zh-cn'
        case 'zh-tw':
        case 'zh-hant-tw':
        case 'zh-hant-mo':
        case 'zh-hant-hk':
        case 'zh-hk':
        case 'zh':
        case 'tw':
            return 'zh-tw'
        default: {
            if (lang.indexOf('en') != -1) {
                return 'en'
            }
            return 'zh-tw'
        }

    }
}
const userState = (): ActionUserRes => {
    const { language = 'zh-tw' } = window.navigator
    return {
        lang: getLang(language.toLowerCase()),
        id: '',
        nickname: '',
        gender: '',
        email: '',
        phone: '',
        profileImg: '',
        credit: 0,
        class: 'BASIC',
        userAreas: {
            HOME: [],
        },
        createdAt: ''
    }
}

const user = (state = userState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.userType.GET_DATA:
            return { ...state, ...action };
        case ActionType.userType.UP_DATE:
            return { ...state, ...action };
        case ActionType.userType.GET_PERMISSION_DATA:
            return { ...state, ...action };
        case ActionType.userType.INIT_DATA:
            return userState();

        default:
            return state;
    }
}


const areaState = (): ActionAreaRes => {
    return {
        listData: [],
        kioskData: {},
    }
}

const area = (state = areaState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.areaType.UP_DATE:
            return { ...state, ...action };

        case ActionType.areaType.UP_KIOSK_DATA:
            return { ...state, ...action };

        case ActionType.areaType.INIT_DATA:
            return areaState();
        default:
            return state;
    }
}


const areaModuleState = (): ActionAreaModuleRes => {
    return {
        list: {},
    }
}

const areaModule = (state = areaModuleState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.areaType.UP_DATE:
            return { ...state, ...action };

        case ActionType.areaType.INIT_DATA:
            return areaModuleState();
        default:
            return state;
    }
}


const campaignState = (): ActionCampaignRes => {
    return {
        list: {},
    }
}

const campaign = (state = campaignState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.campaignType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case ActionType.campaignType.INIT_DATA:
            return {
                ...campaignState()
            };

        default:
            return state;
    }
}
const cartCampaignPackageState = (): ActionCartCampaignPackageRes => {
    return {
        list: {},
    }
}

const cartCampaignPackage = (state = cartCampaignPackageState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.cartCampaignPackageType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case ActionType.cartCampaignPackageType.INIT_DATA:
            return {
                ...cartCampaignPackageState()
            };

        default:
            return state;
    }
}

const orderState = (): ActionOrderRes => {
    return {
        list: {},
    }
}


const order = (state = orderState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.orderType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case ActionType.orderType.INIT_DATA:
            return {
                ...orderState()
            };

        default:
            return state;
    }
}


const shopDeliveryRecipientState = () => {
    return {
        list: [],
        data: {},
        totalCount: 0,
    }
}

const shopDeliveryRecipient = (state = shopDeliveryRecipientState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.shopDeliveryRecipientType.UP_LIST_DATA:
            return {
                ...state,
                ...action,
            };
        case ActionType.shopDeliveryRecipientType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case ActionType.shopDeliveryRecipientType.INIT_DATA:
            return {
                ...shopDeliveryRecipientState()
            };

        default:
            return state;
    }
}


const userShopDeliveryAddressState = (): ActionUserShopDeliveryAddressRes => {
    return {
        list: {},
    }
}


const userShopDeliveryAddress = (state = userShopDeliveryAddressState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.userShopDeliveryAddressType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case ActionType.userShopDeliveryAddressType.INIT_DATA:
            return {
                ...userShopDeliveryAddressState()
            };

        default:
            return state;
    }
}
const shopDeliveryState = (): ActionShopDeliveryRes => {
    return {
        list: {},
    }
}


const shopDelivery = (state = shopDeliveryState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.shopDeliveryType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case ActionType.shopDeliveryType.INIT_DATA:
            return {
                ...shopDeliveryState()
            };

        default:
            return state;
    }
}
const modalState = (): ActionModalRes => {
    return {
        payment: {
            show: false,
            amount: 0,
            onClose: () => { },
            onPay: (payment) => { },
        },
        userCoupon: {
            show: false,
            onClose: () => { },
            onClick: () => { },
            userCoupons: [],
        }
    }
}


const modal = (state = modalState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.modalType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case ActionType.modalType.INIT_DATA:
            return {
                ...modalState()
            };

        default:
            return state;
    }
}
const userCouponState = (): ActionUserCouponRes => {
    return {
        list: {},
    }
}

const userCoupon = (state = userCouponState(), action: { type: any; }) => {
    switch (action.type) {

        case ActionType.userCouponType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case ActionType.userCouponType.INIT_DATA:
            return {
                ...userCouponState()
            };

        default:
            return state;
    }
}

export default combineReducers({
    user,
    area,//区域
    areaModule,
    campaign,//商城活动
    cartCampaignPackage,//商城购物车
    order,//商城訂單
    shopDeliveryRecipient,//用戶收貨人記錄
    userShopDeliveryAddress,//用戶收貨地址
    shopDelivery,//送货方式
    modal,
    userCoupon,//优惠券
});