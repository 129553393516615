import React from 'react'
import { GetUserCouponRes } from '../../../api'
import tool from '../../../tool'
import *as Ons from 'react-onsenui'
import moment from 'moment'
import { SelectProps } from './JJ_UserCouponList'


interface PageState {

}

export type Props = {
    userCoupon: GetUserCouponRes
    selectProps: SelectProps
}


type PageProps = Props


class JJ_UserCouponListItem extends React.Component<PageProps, PageState> {


    constructor(props: PageProps) {
        super(props);
        this.state = {

        }

    }

    render() {

        const userCoupon = this.props.userCoupon

        const leftView = (userCoupon: GetUserCouponRes) => {
            if (this.props.selectProps.switchSelect) {
                const onSelect = this.props.selectProps.onSelect
                const selectUserCoupon = this.props.selectProps.selectUserCoupon
                return (
                    <div className='left'>
                        <Ons.Radio
                            disabled={userCoupon.status !== 'UNUSED'}
                            checked={selectUserCoupon && userCoupon.id === selectUserCoupon.id}
                            onChange={() => onSelect(userCoupon)}
                            modifier='material'
                        />
                    </div>
                )
            }
            return null
        }


        return (
            <div
                id='JJ_UserCouponListItem'
            >
                <Ons.ListItem
                    className={`listItem ${userCoupon.status === 'UNUSED' ? '' : 'listItemDisabled'}`}
                    modifier={'nodivider'}
                >
                    <div
                        className='center'
                    >
                        <span
                            className='centerName'
                        >
                            {userCoupon.coupon.name}
                        </span>
                        <span
                            className='centerDescription'
                        >
                            {userCoupon.coupon.description}
                        </span>
                        <span
                            className='centerExpiredAt'
                        >
                            {moment(userCoupon.expiredAt).format('YYYY-MM-DD HH:mm')}
                        </span>
                    </div>
                    {leftView(userCoupon)}
                    <div
                        className='right'
                    >
                        <span
                            className='centerPrice'
                        >
                            ${tool.math.initPrice({ price: userCoupon.coupon.price }).toFixed(2)}
                        </span>
                    </div>

                </Ons.ListItem>
            </div>


        )
    }
}



export default JJ_UserCouponListItem


