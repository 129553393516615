import React from 'react';
import { Modal } from 'antd-mobile'
import *as Ons from 'react-onsenui'
import tool from '../../tool';
import JJ_UserCouponListItem from '../../screens/JJ_UserCoupon/PageItem/JJ_UserCouponListItem';
import { GetUserCouponRes } from '../../api/api-res-type';


interface PageState {

}

export interface Props {
    show: boolean
    onClose(): void
    userCoupons: GetUserCouponRes[]
    onClick(): void
}

type PageProps = Props

class ModalUserCoupon extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {

        }
    }

    render() {
        const renderRow = (userCoupon: GetUserCouponRes, index: number) => {

            return (
                <JJ_UserCouponListItem
                    key={index}
                    userCoupon={userCoupon}
                    selectProps={{ switchSelect: false }}
                />
            )
        }
        const title = tool.intl.formatMessage({ id: 'ModalUserCoupon[title]' })

        const view = () => {
            return (
                <div
                    className='view'
                >
                    <Ons.List
                        className='UserCouponList'
                        dataSource={this.props.userCoupons}
                        renderRow={renderRow}
                    />

                    <Ons.Button
                        className={'UserCouponButton'}
                        modifier={'large meterial'}
                        onClick={() => {
                            this.props.onClick()
                            tool.navigator.pushPage({
                                key: 'JJ_UserCoupon', props: {
                                    switchSelect: false,
                                    listProps: {
                                        type: 'getUserCouponList',
                                        params: {},
                                    },
                                    renderFooter: (<div>
                                        {tool.intl.formatMessage({ id: 'userCoupon[loadMoreEnd]' })}
                                    </div>),
                                }
                            })
                        }}
                    >
                        {tool.intl.formatMessage({ id: 'ModalUserCoupon[button]' })}
                    </Ons.Button>


                </div >

            )
        }
        return (



            <Modal
                popup={false}
                transparent
                visible={this.props.show}
                onClose={() => this.props.onClose()}
                title={title}
                animationType="slide-down"
            >
                <div
                    id='ModalUserCoupon'
                >
                    {view()}
                </div>
            </Modal>
        );
    }
}

export default ModalUserCoupon




