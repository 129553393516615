
import *as ruter from './ToolRuter'
import *as navigator from './ToolNavigator'
import *as store from './ToolStore'
import *as moment from './ToolMoment'
import *as message from './ToolMessage'
import *as dialog from './ToolDialog'
import *as intl from './ToolIntl'
import *as math from './ToolMath'
import  intlText from './ToolIntlText'
import  request from './ToolRequestData/JJ_RequestDefault'
import  config from './ToolConfig'
import  *as phoneNumber from './ToolPhoneNumber'
import  *as image from './ToolImage'
import  *as analytics from './ToolAnalytics'


const error = (errorText)=>{
    throw new Error(isNull(errorText)||'noData')
}

const dev = process.env.NODE_ENV === 'development'

const src = {
    prodImg1 : 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1521537922098&di=0dcda0d13912f854c35b98fd6e48b03d&imgtype=0&src=http%3A%2F%2Fimgsrc.baidu.com%2Fimage%2Fc0%253Dshijue1%252C0%252C0%252C294%252C40%2Fsign%3Ded98d99cdb58ccbf0fb1bd7971b1d64b%2F6d81800a19d8bc3eef7376e4888ba61ea8d345a0.jpg',
    prodImg2 : 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1521537922098&di=b4b9b120ae1b18c8afa9b6f8752a1168&imgtype=0&src=http%3A%2F%2Fp3.ifengimg.com%2Fcmpp%2F2016%2F06%2F08%2F10%2F0eb1abe5-aeba-4f40-be92-b8a57678d4e3_size37_w500_h324.jpg',
    prodImg3 : 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1521537922098&di=cb391b70e750148f63888641642d31f5&imgtype=0&src=http%3A%2F%2Fimgsrc.baidu.com%2Fimgad%2Fpic%2Fitem%2Fd043ad4bd11373f0049d04b9ae0f4bfbfbed0471.jpg',
    prodImg4 : 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1521537922097&di=a8f6547cf53a3e10ad027634039de862&imgtype=0&src=http%3A%2F%2Fimgsrc.baidu.com%2Fimgad%2Fpic%2Fitem%2Fa50f4bfbfbedab64c9d958e5fd36afc379311e6f.jpg',
    prodImg5 : 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1521537922097&di=3550c333b279855f596e7421ff16e441&imgtype=0&src=http%3A%2F%2Fimgsrc.baidu.com%2Fimgad%2Fpic%2Fitem%2F9a504fc2d56285353a7279d79aef76c6a6ef63b2.jpg',
    prodImg6 : 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1521537922096&di=d88db44a88273325f3717f69f9077987&imgtype=0&src=http%3A%2F%2Fimgsrc.baidu.com%2Fimgad%2Fpic%2Fitem%2F8b82b9014a90f6036385436f3212b31bb051ede5.jpg',
    prodImg7 : 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1521537922095&di=f87482a390cc03de65d340487005a130&imgtype=0&src=http%3A%2F%2Fimgsrc.baidu.com%2Fimage%2Fc0%253Dpixel_huitu%252C0%252C0%252C294%252C40%2Fsign%3Dd34a778fc2177f3e0439f44d19b75eab%2F38dbb6fd5266d0160ba67b119c2bd40735fa354f.jpg',
    prodImg8 : 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1521537922095&di=178c99c4173c6ecc36dfbd10b43f004a&imgtype=0&src=http%3A%2F%2Fimgsrc.baidu.com%2Fimgad%2Fpic%2Fitem%2F5fdf8db1cb13495451bbe3915c4e9258d0094a99.jpg',
}

const isNull = (value) => {
    let type;
    type = Object.prototype.toString.call(value).slice(8, -1);
    switch (type) {
        case 'String':
            return value !== '';
        case 'Array':
            return value.length !== 0;
        case 'Object':
            return JSON.stringify(value) !== '{}'
        case 'Number':
            return value
        default:
            return false;
    }
}


if (!dev) {
    global.console = {
        info: () => {},
        log: () => {},
        warn: () => {},
        error: () => {},
    };
}

window.tool = {
    error,
    dev,
    src,
    isNull,
    ruter,
    store,
    moment,
    message,
    intl,
    math,
    request,
    intlText,
    config,
    navigator,
    dialog,
    phoneNumber,
    image,
    analytics,
}




