
import React, { Component } from 'react';
import moment from 'moment'
import tool from '../../tool';
interface PageState {
    timeText: string
}

export interface Props {
    beginDate: Date
    endDate: Date
    onEnd(): void
    className?: string
}
type PageProps = Props

class BaseCountdownText extends Component<PageProps, PageState> {
    time?: any
    diff: number
    constructor(props: PageProps) {
        super(props);
        this.state = {
            timeText: '00:00:00',

        }
        const beginDateM = moment(props.beginDate)
        const endDateM = moment(props.endDate)
        this.diff = endDateM.diff(beginDateM)

    };
    componentDidMount() {
        this._startTime()
        this._getTimeText(this.diff)
    }


    componentWillUnmount() {
        this._endTime()
    }


    _endTime = () => {
        this.time && clearInterval(this.time)
    }


    _startTime = () => {
        const s = 1000
        this.time = setInterval(() => {
            if (this.diff > 0) {
                this.diff -= s
                this._getTimeText(this.diff)
            } else {
                this._endTime()
                this.props.onEnd()
            }
        }, s)
    }

    _getTimeText = (diff: number) => {

        const h = moment.duration(diff).hours()
        const m = moment.duration(diff).minutes();
        const d = parseInt(`${moment.duration(diff).asDays()}`)
        const newM = m < 10 ? `0${m}` : m
        const textH = `${h}${tool.intl.formatMessage({ id: 'BaseCountdownText[textH]' })}`
        const textD = `${d}${tool.intl.formatMessage({ id: 'BaseCountdownText[textD]' })}`
        const textM = `${newM}${tool.intl.formatMessage({ id: 'BaseCountdownText[textM]' })}`

        this.setState({
            timeText: `${textD} ${textH} ${textM}`
        })

    }

    render() {
        return (
            <span
                id={`BaseCountdownText`}
                className={this.props.className || ''}
            >{this.state.timeText}</span>
        );
    }
}

export default BaseCountdownText


