
const userType = {
    INIT_DATA: '[用户]初始化数据',
    UP_DATE: '[用户]更新数据',
    GET_DATA: '[用户]获取数据',
    GET_PERMISSION_DATA: '[用户]获取用户权限数据',
}


const areaType = {
    INIT_DATA: '[区域]初始化数据',
    UP_DATE: '[区域]更新数据',
    UP_KIOSK_DATA: '[地区]更新机器数据',
    UP_DATE_MODULE: '[区域]更新数据',
}
const campaignType = {
    UP_DATA: '[商城活动]更新数据',
    INIT_DATA: '[商城活动]初始化',
}
const cartCampaignPackageType = {
    UP_DATA: '[商城购物车]更新数据',
    INIT_DATA: '[商城购物车]初始化',
}
const orderType = {
    UP_DATA: '[商城訂單]更新数据',
    INIT_DATA: '[商城訂單]初始化',
}


const shopDeliveryRecipientType = {
    UP_LIST_DATA: '[用戶收貨人記錄]获取列表数据',
    UP_DATA: '[用戶收貨人記錄]更新数据',
    INIT_DATA: '[用戶收貨人記錄]初始化',
}
const userShopDeliveryAddressType = {
    UP_DATA: '[用戶收貨地址]更新数据',
    INIT_DATA: '[用戶收貨地址]初始化',
}
const shopDeliveryType = {
    UP_DATA: '[送货方式]更新数据',
    INIT_DATA: '[送货方式]初始化',
}
const modalType = {
    UP_DATA: '[弹出模块]更新数据',
    INIT_DATA: '[弹出模块]初始化',
}
const userCouponType = {
    UP_DATA: '[用户优惠券]更新数据',
    INIT_DATA: '[用户优惠券]初始化',
}

export default{
    userType,//用户
    areaType,//区域
    modalType,//弹出模块
    campaignType,//商城活动
    cartCampaignPackageType,//商城购物车
    orderType,//商城訂單
    shopDeliveryRecipientType,//(商城) 用戶收貨人記錄
    userShopDeliveryAddressType,//用戶收貨地址
    shopDeliveryType,//送货方式
    userCouponType,//用户优惠券

}