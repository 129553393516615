import Type from './ActionType';
import { MyThunkDispatch } from './action-interface';
import tool from '../tool'
import {
    GetShopCartCampaignPackageRes,
    GetShopCartCampaignPackageListParameters,
    GetShopCartCampaignPackageParameters,
} from '../api';
import api from '../api';
const upDataAction = (data: any) => {
    return {
        type: Type.cartCampaignPackageType.UP_DATA, ...data
    }
}

const initDataAction = () => {
    return {
        type: Type.cartCampaignPackageType.INIT_DATA
    }
}

interface GetShopCartCampaignPackageList {
    [id: string]: GetShopCartCampaignPackageRes
}
export interface ActionCartCampaignPackageRes {
    list: GetShopCartCampaignPackageList
}

export const getShopCartCampaignPackageList = (params: GetShopCartCampaignPackageListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getShopCartCampaignPackageList(params)
        if (res.kind === 'ok') {
            const cartCampaignPackage: ActionCartCampaignPackageRes = tool.store.getState().cartCampaignPackage
            cartCampaignPackage.list = {}
            res.data.data.forEach((item: GetShopCartCampaignPackageRes) => {
                cartCampaignPackage.list[item.id] = item
            })
            dispatch(upDataAction(cartCampaignPackage))
        }
        return res
    }
}
export const getShopCartCampaignPackage = (params: GetShopCartCampaignPackageParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getShopCartCampaignPackage(params)
        if (res.kind === 'ok') {
            const cartCampaignPackage: ActionCartCampaignPackageRes = tool.store.getState().cartCampaignPackage
            cartCampaignPackage.list[res.data.id] = res.data
            dispatch(upDataAction(cartCampaignPackage))
        }
        return res
    }
}


export const initData = () => {
    return (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}












