export default {
  "ALREADY_LOGGED_IN": "已登錄",

  "AppSlitterSideTitle[help]": "客服中心",
  "AppSlitterSideTitle[licenses]": "限制食品許可證",
  "AppSlitterSideTitle[shopDeliverySpLocker]": "本屋苑取貨點",

  "AppSplitterSideTitle[SignOut]": "退出登錄",
  "AppSplitterSideTitle[other]": "其他",

  "BaseCountdownText[textD]": "天",
  "BaseCountdownText[textH]": "小時",
  "BaseCountdownText[textM]": "分",

  "INVALID_TOKEN": "錯誤令牌",

  "JJ_Guide[footButton]": "立刻體驗",
  "JJ_Guide[topButton]": "跳過",

  "JJ_Licenses[title]": "受限制食物售賣許可證",

  "JJ_ShopCampaignDetailGroupFoot[buttonPay]": "拼團",
  "JJ_ShopCampaignDetailNormalFoot[buttonPay]": "立刻購買",
  "JJ_ShopCampaign[tagMenuButton]": "所有分類",
  "JJ_ShopCart[title]": "購物車",
  "JJ_ShopDeliverySpLocker[title]": "本屋苑取貨點",
  "JJ_ShopOrderBoxItem[contentViewRightViewButtonDescription]": "請到自提櫃掃描二維碼",
  "JJ_ShopOrderBoxItem[contentViewRightViewButton]": "顯示二維碼",
  "JJ_ShopOrderBoxItem[modalQrTitle]": "訂單取貨二維碼",
  "JJ_ShopOrderBoxItem[pickupEndAtDescription]": "注意: 30分鐘後不能再開啓自提櫃門, 請及時取貨, 謝謝。",
  "JJ_ShopOrderDetails[buttonGroup]": "訂金支付",
  "JJ_ShopOrderDetails[buttonNoraml]": "支付",
  "JJ_ShopOrderListItem[humanIdTitle]": "訂單編號: {humanId}",
  "JJ_ShopOrder[title]": "我的訂單",
  "JJ_ShopTagMenu[title]": "選擇分類",

  "JJ_UserCouponListItem[no]": "無",

  "ModalPayment[paymentButton]": "去支付",
  "ModalPayment[title]": "請選擇支付方式",
  "ModalPayment[walletBalanceLabel]": "餘額",

  "ModalUserCoupon[button]": "查看詳情",
  "ModalUserCoupon[title]": "您獲得新的優惠券",

  "appSideMenuChangeLanguageTitle": "語言設定",
  "appSideMenuMyItemsTitle": "我的記錄",

  "areaModuleDisabledMessage": "抱歉, 系統維護中, 請稍後再回來",

  "baseListNoMoreShopDataMessage": "暫時沒有更多項目",
  "baseListPullToRefreshFinish": "已更新完成",
  "baseListPullToRefreshLoading": "加載中...",
  "baseListPullToRefreshPull": "下拉更新列表",
  "baseListPullToRefreshRelease": "鬆開可更新列表",

  "boxPageButtonRefresh": "重新加載",

  "boxeStatusQrCodeModalTitle": "WeCooler 取貨二維碼",
  "boxeStatusQrCodeOpenButton": "打開取貨二維碼",
  "boxeStatus[COMPLETED]": "已經取貨",
  "boxeStatus[DELIVERED]": "等待取貨",
  "boxeStatus[PENDING]": "整理貨品中",
  "boxeStatus[RECALLED]": "沒有取貨, 已經過期",

  "buttonAddToCart": "加入購物車",

  "buttonCancel": "取消",

  "buttonComplete": "完成",

  "buttonDelete": "刪除",

  "buttonRefresh": "重新加載",

  "buttonStripePay": "去支付",

  "buttonViewCart": "查看購物車",

  "closeButton": "關閉",

  "helpModalButtonEmail": "電郵地址",
  "helpModalButtonWhatsapp": "WhatsApp",
  "helpModalTitle": "客服中心",

  "licensesAddressLabel": "業務地址",

  "licensesNumberLabel": "受限制食物售賣許可證",

  "listItemRight[notSelected]": "未選擇",

  "loadingMessage": "載入中...",

  "loginAreaButton": "確定",

  "loginGuestLoginButton": "先逛一逛",

  "loginMemberLoginButton": "會員登錄",
  "loginMemberRegistrationButton": "會員註冊",
  "loginMemberRegistrationInfo": "會員註冊立刻贈送優惠券",

  "loginOrderInputOrderIdErrorInvalidFormat": "錯誤格式, 請檢查再提交",
  "loginOrderInputOrderIdPlaceholder": "XXX-XXX-XXX-XXX",

  "loginSms[TOO_EARLY]": "發送過於頻密, 請稍後重試",
  "loginSms[USER_NOT_FOUND]": "該手機號碼沒有註冊",
  "loginSms[title]": "會員登錄",

  "networkErrorMessage": "網絡異常, 請稍後重試",

  "orderStatus[CANCELED]": "已取消",
  "orderStatus[COMPLETED]": "已取貨",
  "orderStatus[CONFIRMED]": "待發貨",
  "orderStatus[DELIVERED]": "待取貨",
  "orderStatus[EXPIRED]": "已過期",
  "orderStatus[PAID]": "待發貨",
  "orderStatus[PAID_CONFIRMED]": "整理發貨中",
  "orderStatus[PAID_ONGOING]": "已支付, 正在成團",
  "orderStatus[PENDING]": "未付款",
  "orderStatus[PREPAID]": "已預支付",
  "orderStatus[PREPAID_CONFIRMED]": "等待支付剩餘金額",
  "orderStatus[PREPAID_ONGOING]": "已支付訂金, 等待成團中",
  "orderStatus[RECALLED]": "未取貨已過期",
  "orderStatus[REFUNDED]": "已退款",

  "pageErrorNotLoadMessage": "加載失敗, 請稍後重試",

  "registeredMemberSms[ALREADY_USER]": "此電話已被註冊, 您可以前往登入",
  "registeredMemberSms[AREA_NOT_FOUND]": "區域不正確, 請返回重試",
  "registeredMemberSms[ConfirmButtonText]": "確定",
  "registeredMemberSms[DUPLICATE_PHONE]": "此電話號碼已經存在",
  "registeredMemberSms[INVALID_PHONE_NUMBER]": "請輸入正確的電話號碼",
  "registeredMemberSms[INVALID_TOKEN]": "短訊驗證碼不正確",
  "registeredMemberSms[SYSTEM_ERROR]": "系統錯誤, 請稍後重試",
  "registeredMemberSms[TOO_EARLY]": "操作太頻密, 請稍後重試",
  "registeredMemberSms[loginButton]": "已經註冊過?",
  "registeredMemberSms[phoneInputViewPlaceholder]": "請輸入手機號碼",
  "registeredMemberSms[sendVerificationCodeSuccess]": "驗證碼已發送, 請留意您的手機短訊, 基於您手機的電訊商可能最多需時數分鐘",
  "registeredMemberSms[title]": "會員註冊",
  "registeredMemberSms[verificationInputViewButton]": "發送",
  "registeredMemberSms[verificationInputViewOffSentInfo]": "請輸入手機號碼並點擊發送驗證碼",
  "registeredMemberSms[verificationInputViewOnSentInfo]": "驗證碼已發送, 請留意您的手機短訊, 基於您手機的電訊商可能最多需時數分鐘",
  "registeredMemberSms[verificationInputViewPlaceholder]": "請輸入驗證碼",
  "registeredMemberSms[verificationResendInputViewButton]": "再次發送",

  "shopCampaign": "商城活動",
  "shopCampaignAmountShown": "库存{number}件",
  "shopCampaignCountdownTime": "訂單 {datetime} 到期",
  "shopCampaignDettailsCampaignMemberLogin": "必須要註冊成爲會員才能購買該活動商品",
  "shopCampaignDettailsCampaignNotAvailable": "該活動暫時不能購買",
  "shopCampaignDettailsPackageNotFound": "活動套餐已經下架",
  "shopCampaignDettails[EXCEED_PACKAGE_USER_AMOUNT_LIMIT]": "該產品剛剛已被搶光",
  "shopCampaignDettails[NOT_ENOUGH_INVENTORY]": "庫存不足, 請稍後重試",
  "shopCampaignDettails[listItemButtonDelete]": "刪除",
  "shopCampaignGroupConfirmedAmountUnit": "件",
  "shopCampaignGroupPriceText": "團購價",
  "shopCampaignGroupStatus[CANCELED]": "活動已取消",
  "shopCampaignGroupStatus[CONFIRMED]": "成團發貨中",
  "shopCampaignGroupStatus[ENDED]": "團購已結束",
  "shopCampaignGroupStatus[ONGOING]": "成團数量",
  "shopCampaignGroupStatus[UPCOMING]": "即將開始",
  "shopCampaignNormalPriceText": "優惠價",
  "shopCampaignNormalStatus[ENDED]": "產品已暫時下架",
  "shopCampaignNormalStatus[ONGOING]": "產品現正有售",
  "shopCampaignNormalStatus[UPCOMING]": "產品即將推出",
  "shopCampaignOrder": "我的訂單",
  "shopCampaignPackage": "套裝",
  "shopCampaignPriceOriginalText": "超市價",
  "shopCampaignRemainingTime": "剩餘時間",
  "shopCampaignSearchInputPlaceholder": "輸入搜索心水貨品",
  "shopCampaignSearchReaultEmpty": "在上面輸入搜索關鍵字",
  "shopCampaignSearchReaultHeaderLabel": "搜索結果",
  "shopCampaignShopModalButtonAddToCart": "加入購物車",
  "shopCampaignShopModalButtonPay": "立刻購買",
  "shopCampaignTagAll": "全部",

  "shopCampaingListNoDateText": "準備產品中, 敬請留意",

  "shopCart": "購物車",
  "shopCartCampaignPackagesModal[amountText]": "總計",
  "shopCartCampaignPackagesModal[noDateText]": "購物車沒有東西",
  "shopCartCampaignPackagesModal[payButton]": "去結算",

  "shopDeliverySpLockerListNoDateText": "沒有數據",

  "shopOrderCalculatedAdditionalAmount": "含支付附加費",
  "shopOrderCalculatedAdditionalShippingAmount": "含運費/附加費",
  "shopOrderDeatils[orderPaymentTransactionsTitle]": "支付方式",
  "shopOrderDeatils[typeTitle]": "類別",
  "shopOrderDeliveryAddressButtonSelect": "選擇收貨地址",
  "shopOrderDeliveryAddressLabel": "收貨地址",
  "shopOrderDeliveryButtonSelect": "選擇送貨方式",
  "shopOrderDeliveryRecipientSelectTitle": "選擇收貨人",
  "shopOrderDeliveryRecipientTitle": "收件人",
  "shopOrderDeliveryRequestedAtLabel": "送貨日期",
  "shopOrderDeliverySelectTitle": "選擇送貨方式",
  "shopOrderDeliveryTitle": "配送方式",
  "shopOrderDetailCancel": "取消訂單",
  "shopOrderDetailCreatedAt": "創建時間",
  "shopOrderDetailDeleteOrder": "刪除訂單",
  "shopOrderDetailInfo": "訂單項目",
  "shopOrderDetailPriceOriginal": "原始價格",
  "shopOrderDetailsErrorCode[CANCEL_NOT_ALLOWED]": "該訂單不能被取消",
  "shopOrderDetailsErrorCode[NOT_FOUND]": "訂單已經被刪除",
  "shopOrderDetailsErrorCode[NO_PAYMENT_NEEDED]": "系統異常, 稍後重試",
  "shopOrderDetailsErrorCode[ORDER_IS_PAID]": "訂單已經付款",
  "shopOrderDetailsErrorCode[PAYMENT_SYSTEM_ERROR]": "支付系統出錯, 請稍後重試, 或者使用其他支付方式。",
  "shopOrderDetailsErrorCode[STRIPE_AMOUNT_TOO_LOW]": "抱歉, 金額太低無法使用此支付方式",
  "shopOrderDetailsErrorCode[WALLET_NOT_ENOUGHT_BALANCE]": "抱歉, 您的餘額不足夠",
  "shopOrderDetails[boxes]": "取貨包",
  "shopOrderDetails[deleteShopOrderSuccess]": "訂單刪除成功",
  "shopOrderDetails[logisticsDisplay]": "物流跟蹤",
  "shopOrderDetails[noPayment]": "暫時不支持該{name}支付",
  "shopOrderDetails[orderActionViewTitle]": "其他操作",
  "shopOrderDetails[orderDeliveryViewTitle]": "配送信息",
  "shopOrderDetails[orderDiscount]": "折扣金額",
  "shopOrderDetails[orderInfoViewTitle]": "訂單信息",
  "shopOrderDetails[paymenError]": "訂單支付失敗",
  "shopOrderDetails[paymenSuccess]": "訂單支付成功",
  "shopOrderDetails[putShopOrderSuccess]": "訂單編輯成功",
  "shopOrderDetails[shopOrderCancelSuccess]": "訂單取消成功",
  "shopOrderDetails[title]": "訂單詳情",
  "shopOrderListNoDateText": "暫無訂單",
  "shopOrderQrCode[CANCELED]": "已取消",
  "shopOrderQrCode[COMPLETED]": "已取貨",
  "shopOrderQrCode[EXPIRED]": "已過期",
  "shopOrderQrCode[RECALLED]": "沒取貨已經過期",
  "shopOrderQrCode[REFUNDED]": "已退款",
  "shopOrderRecipientButtonSelect": "選擇收貨人",
  "shopOrderStatusCompleted": "完成",
  "shopOrderStatusCompletedDescription": "流程完成",
  "shopOrderStatusDelivered": "待取貨",
  "shopOrderStatusDeliveredDirectDescription": "用户直接收货",
  "shopOrderStatusDeliveredSelfPickupDescription": "用戶前來取貨",
  "shopOrderStatusDeliveredSpLockerDescription": "用户从自提柜收取",
  "shopOrderStatusPaid": "待分配",
  "shopOrderStatusPaidDescription": "分配包裝",
  "shopOrderStatusPaidDirect": "待包装",
  "shopOrderStatusPaidSpLockerDescription": "分配自提櫃",
  "shopOrderStatusPending": "代付款",
  "shopOrderStatusPendingDescription": "用戶付款",
  "shopOrderTotalAmount": "合計",
  "shopOrderTotalRefundedAmount": "已退款金額",
  "shopOrderUserDeliveryAddressDefaultLabel": "(默認)",
  "shopOrderUserDeliveryAddressTitle": "收貨地址",
  "shopOrderUserRemark": "留言備註",
  "shopOrderUserRemarkEmpty": "無",

  "userCouponList[ButtonNotSelect]": "請選擇優惠券",
  "userCouponList[noDataViewText]": "沒有優惠券",
  "userCoupon[loadMoreEnd]": "沒有更多優惠券",
  "userCoupon[title]": "我的優惠券",

  "userDeliveryAddressButtonAdd": "添加收貨地址",
  "userDeliveryAddressButtonEdit": "編輯收貨地址",
  "userDeliveryDeleteFailed": "删除失敗",
  "userDeliveryDeleteSuccess": "删除成功",
  "userDeliveryRecipientButtonAdd": "添加收貨人聯系",
  "userDeliveryShippingFeeWavied": "免運費",
  "userDeliveryShippingFeeWaviedDescription": "滿 ${number} 免運費",

  "总价": "總共",

  "总共商品": "共{number}件商品",

  "折后价格": "折後價格",

  "提交": "提交",

  "提取码": "提取碼",

  "收货人": "收貨人",

  "数量": "數量",

  "查询": "查詢我的訂單",

  "正常": "正常",

  "添加失败": "添加失敗",

  "添加成功": "添加成功",

  "确定": "確定",

  "立刻购买": "立刻購買",

  "编辑失败": "編輯失敗",

  "编辑成功": "編輯成功",

  "联系地址": "聯繫地址",

  "联系电话": "聯繫電話",

  "自提柜": "自提櫃",

  "规格": "規格",

  "订单": "訂單",

  "订单编号": "訂單編號",

  "订单详情": "訂單詳情",

  "设置默认": "設定默認",

  "详情": "詳情",

  "请输入": "請輸入",

  "请输入订单编号登录": "請輸入您的訂單號",

  "请选择区域登录": "請選擇您的區域",

  "请选择登录方式": "請選擇登錄方式",

  "购物车": "購物車",

  "输入银行卡": "輸入您的信用卡號碼",

  "运费": "運費",

  "重新加载": "重新加載",

  "附加费": "附加費",

  "限购数量": "每人限購：{number} 件"
}