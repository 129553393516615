import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import JJ_Components from '../../../components/JJ_Components'
import *as ActionShopDeliveryRecipient from '../../../actions/ActionShopDeliveryRecipient'
import { injectIntl, InjectedIntlProps } from "react-intl";
import JJ_UserDeliveryRecipientListItem from './JJ_UserDeliveryRecipientListItem'
import *as Ons from 'react-onsenui'

import { FaPlus } from 'react-icons/fa';
import api, { GetUserShopDeliveryRecipientListParameters, GetUserShopDeliveryRecipientParameters, GetUserShopDeliveryRecipientListRes, GetUserShopDeliveryRecipientRes, PutUserShopDeliveryRecipientParameters, PostUserShopDeliveryRecipientParameters, DeleteUserShopDeliveryRecipientParameters } from '../../../api';
import tool from '../../../tool';



interface PageState {
    selectId?: string
    shopDeliveryRecipientList: string[]
}

export interface Props {
    onSelect(id: string): void
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    shopDeliveryRecipient: ActionShopDeliveryRecipient.ActionUserShopDeliveryRecipientRes,
}

const mapState = (state: RootState) => ({
    shopDeliveryRecipient: state.shopDeliveryRecipient,
})

const mapDispatch = {
    getUserShopDeliveryRecipientList: ActionShopDeliveryRecipient.getUserShopDeliveryRecipientList,
    getUserShopDeliveryRecipient: ActionShopDeliveryRecipient.getUserShopDeliveryRecipient,
}


export const connector = connect(
    mapState,
    mapDispatch
)



class JJ_UserDeliveryRecipientList extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {
            shopDeliveryRecipientList: []
        }
    }




    /**
     * 初始化
     */
    _initData = async () => {
        try {
            const getUserShopDeliveryRecipientListRes = await this._getUserShopDeliveryRecipientList({ count: 1000 })
            this.setState({
                shopDeliveryRecipientList: getUserShopDeliveryRecipientListRes.data.map(data => data.id)
            })
        } catch (error) {
            console.error(error)
        }
    }



    _getUserShopDeliveryRecipientList = async (params: GetUserShopDeliveryRecipientListParameters) => {
        const res = await this.props.getUserShopDeliveryRecipientList(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }

    _getUserShopDeliveryRecipient = async (params: GetUserShopDeliveryRecipientParameters) => {
        const res = await this.props.getUserShopDeliveryRecipient(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }


    _putUserShopDeliveryRecipient = async (params: PutUserShopDeliveryRecipientParameters) => {
        const res = await api.putUserShopDeliveryRecipient(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }

    _postUserShopDeliveryRecipient = async (params: PostUserShopDeliveryRecipientParameters) => {
        const res = await api.postUserShopDeliveryRecipient(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }


    _deleteUserShopDeliveryRecipient = async (params: DeleteUserShopDeliveryRecipientParameters) => {
        const res = await api.deleteUserShopDeliveryRecipient(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }


    _onPutUserShopDeliveryRecipient = async (params: PutUserShopDeliveryRecipientParameters) => {
        try {
            tool.message.loading({})
            await this._putUserShopDeliveryRecipient(params)
            await this._getUserShopDeliveryRecipient({ deliveryRecipientId: params.deliveryRecipientId })
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: '编辑成功' }) })
        } catch (error) {
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: '编辑失败' }) })
            console.error(error)
        }
    }


    _onPostUserShopDeliveryRecipient = async (params: PostUserShopDeliveryRecipientParameters) => {
        try {
            tool.message.loading({})
            const postRes = await this._postUserShopDeliveryRecipient(params)
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: '添加成功' }) })

        } catch (error) {
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: '添加失败' }) })
            console.error(error)
        }
    }


    _onDeleteUserShopDeliveryRecipient = async (params: DeleteUserShopDeliveryRecipientParameters) => {
        try {
            tool.message.loading({})
            await this._deleteUserShopDeliveryRecipient(params)
            await this._getUserShopDeliveryRecipient({ deliveryRecipientId: params.deliveryRecipientId })
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: 'userDeliveryDeleteSuccess' }) })
        } catch (error) {
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: 'userDeliveryDeleteFailed' }) })
            console.error(error)
        }
    }


    _renderRow = (id: string) => {
        switch (id) {
            case 'add': {
                return (
                    <Ons.Button
                        id="JJ_UserDeliveryRecipientListItem"
                        className={'add'}
                        key={id}
                        onClick={() => {
                            tool.navigator.pushPage({
                                key: 'JJ_UserDeliveryRecipientDetails',
                                props: {
                                    type: 'add',
                                    recipientName: '',
                                    recipientPhone: '',
                                    isDefault: false,
                                    onEdit: (props) => { console.log('onEdit', props) },
                                    onDelete: (props) => { console.log('onDelete', props) },
                                    onAdd: async (props) => {
                                        await this._onPostUserShopDeliveryRecipient({
                                            recipientName: props.recipientName,
                                            recipientPhone: props.recipientPhone,
                                            userId: 'me',
                                            isDefault: props.isDefault,
                                        })
                                        await this._initData()
                                    }
                                }
                            })

                            console.log('pushPage')
                        }}
                    >
                        <FaPlus />
                        <span>{tool.intl.formatMessage({ id: 'userDeliveryRecipientButtonAdd' })}</span>
                    </Ons.Button>
                )
            }
            default: {


                const deliveryRecipient = this.props.shopDeliveryRecipient.list[id]

                return (
                    <JJ_UserDeliveryRecipientListItem
                        key={id}
                        onSelect={selectId => {
                            this.setState({ selectId })
                            this.props.onSelect(selectId)
                        }}
                        deliveryRecipient={deliveryRecipient}
                        onPress={() => {

                            tool.navigator.pushPage({
                                key: 'JJ_UserDeliveryRecipientDetails',
                                props: {
                                    type: 'edit',
                                    recipientName: deliveryRecipient.recipientName,
                                    recipientPhone: deliveryRecipient.recipientPhone,
                                    isDefault: deliveryRecipient.isDefault,
                                    onEdit: async (props) => {
                                        await this._onPutUserShopDeliveryRecipient({
                                            deliveryRecipientId: deliveryRecipient.id,
                                            recipientName: props.recipientName,
                                            recipientPhone: props.recipientPhone,
                                            isDefault: props.isDefault,
                                        })
                                        await this._initData()
                                    },
                                    onAdd: (props) => { console.log('onAdd', props) },
                                    onDelete: async (props) => {
                                        await this._onDeleteUserShopDeliveryRecipient({ deliveryRecipientId: deliveryRecipient.id })
                                        await this._initData()
                                    },
                                }
                            })
                        }}
                        checked={deliveryRecipient.id === this.state.selectId}
                    />
                )
            }
        }
    }

    _view = () => {
        const recipientList = this.state.shopDeliveryRecipientList
        const dataSource = [
            ...recipientList,
            'add',
        ]
        return (
            <Ons.List
                id='JJ_UserDeliveryRecipientList'
                dataSource={dataSource}
                renderRow={this._renderRow}
            />
        )
    }

    render() {

        return (
            <JJ_Components.boxPage
                loadData={this._initData}
            >
                {this._view()}
            </JJ_Components.boxPage>

        )
    }
}



export default connector(injectIntl(JJ_UserDeliveryRecipientList))
