import zhTw from './zh-tw';
import zhCn from './zh-cn';
import en from './en';

export const getLangPack = (lang) => {
    switch (lang) {
        case 'zh-cn':
            return zhCn;
        case 'zh-tw':
            return zhTw;
        case 'en':
        default:
            return en;
    }
}
