export default {
  "ALREADY_LOGGED_IN": "已登录",

  "AppSlitterSideTitle[help]": "客服中心",
  "AppSlitterSideTitle[licenses]": "限制食品许可证",
  "AppSlitterSideTitle[shopDeliverySpLocker]": "本屋苑取货点",

  "AppSplitterSideTitle[SignOut]": "退出登录",
  "AppSplitterSideTitle[other]": "其他",

  "BaseCountdownText[textD]": "天",
  "BaseCountdownText[textH]": "小时",
  "BaseCountdownText[textM]": "分",

  "INVALID_TOKEN": "错误令牌",

  "JJ_Guide[footButton]": "立刻体验",
  "JJ_Guide[topButton]": "跳过",

  "JJ_Licenses[title]": "受限制食物售卖许可证",

  "JJ_ShopCampaignDetailGroupFoot[buttonPay]": "拼团",
  "JJ_ShopCampaignDetailNormalFoot[buttonPay]": "立刻购买",
  "JJ_ShopCampaign[tagMenuButton]": "所有分类",
  "JJ_ShopCart[title]": "购物车",
  "JJ_ShopDeliverySpLocker[title]": "本屋苑取货点",
  "JJ_ShopOrderBoxItem[contentViewRightViewButtonDescription]": "请到自提柜扫描二维码",
  "JJ_ShopOrderBoxItem[contentViewRightViewButton]": "显示二维码",
  "JJ_ShopOrderBoxItem[modalQrTitle]": "订单取货二维码",
  "JJ_ShopOrderBoxItem[pickupEndAtDescription]": "注意: 30分钟后不能再开启自提柜门, 请及时取货, 谢谢。",
  "JJ_ShopOrderDetails[buttonGroup]": "订金支付",
  "JJ_ShopOrderDetails[buttonNoraml]": "支付",
  "JJ_ShopOrderListItem[humanIdTitle]": "订单编号: {humanId}",
  "JJ_ShopOrder[title]": "我的订单",
  "JJ_ShopTagMenu[title]": "选择分类",

  "JJ_UserCouponListItem[no]": "无",

  "ModalPayment[paymentButton]": "去支付",
  "ModalPayment[title]": "请选择支付方式",
  "ModalPayment[walletBalanceLabel]": "余额",

  "ModalUserCoupon[button]": "查看详情",
  "ModalUserCoupon[title]": "您获得新的优惠券",

  "appSideMenuChangeLanguageTitle": "语言设定",
  "appSideMenuMyItemsTitle": "我的记录",

  "areaModuleDisabledMessage": "抱歉, 系统维护中, 请稍后再回来",

  "baseListNoMoreShopDataMessage": "暂时没有更多项目",
  "baseListPullToRefreshFinish": "已更新完成",
  "baseListPullToRefreshLoading": "加载中...",
  "baseListPullToRefreshPull": "下拉更新列表",
  "baseListPullToRefreshRelease": "松开可更新列表",

  "boxPageButtonRefresh": "重新加载",

  "boxeStatusQrCodeModalTitle": "WeCooler 取货二维码",
  "boxeStatusQrCodeOpenButton": "打开取货二维码",
  "boxeStatus[COMPLETED]": "已经取货",
  "boxeStatus[DELIVERED]": "等待取货",
  "boxeStatus[PENDING]": "整理货品中",
  "boxeStatus[RECALLED]": "没有取货, 已经过期",

  "buttonAddToCart": "加入购物车",

  "buttonCancel": "取消",

  "buttonComplete": "完成",

  "buttonDelete": "删除",

  "buttonRefresh": "重新加载",

  "buttonStripePay": "去支付",

  "buttonViewCart": "查看购物车",

  "closeButton": "关闭",

  "helpModalButtonEmail": "电邮地址",
  "helpModalButtonWhatsapp": "WhatsApp",
  "helpModalTitle": "客服中心",

  "licensesAddressLabel": "业务地址",

  "licensesNumberLabel": "受限制食物售卖许可证",

  "listItemRight[notSelected]": "未选择",

  "loadingMessage": "载入中...",

  "loginAreaButton": "确定",

  "loginGuestLoginButton": "先逛一逛",

  "loginMemberLoginButton": "会员登录",
  "loginMemberRegistrationButton": "会员注册",
  "loginMemberRegistrationInfo": "会员注册立刻赠送优惠券",

  "loginOrderInputOrderIdErrorInvalidFormat": "错误格式, 请检查再提交",
  "loginOrderInputOrderIdPlaceholder": "XXX-XXX-XXX-XXX",

  "loginSms[TOO_EARLY]": "发送过于频密, 请稍后重试",
  "loginSms[USER_NOT_FOUND]": "该手机号码没有注册",
  "loginSms[title]": "会员登录",

  "networkErrorMessage": "网络异常, 请稍后重试",

  "orderStatus[CANCELED]": "已取消",
  "orderStatus[COMPLETED]": "已取货",
  "orderStatus[CONFIRMED]": "待发货",
  "orderStatus[DELIVERED]": "待取货",
  "orderStatus[EXPIRED]": "已过期",
  "orderStatus[PAID]": "待发货",
  "orderStatus[PAID_CONFIRMED]": "整理发货中",
  "orderStatus[PAID_ONGOING]": "已支付, 正在成团",
  "orderStatus[PENDING]": "未付款",
  "orderStatus[PREPAID]": "已预支付",
  "orderStatus[PREPAID_CONFIRMED]": "等待支付剩余金额",
  "orderStatus[PREPAID_ONGOING]": "已支付订金, 等待成团中",
  "orderStatus[RECALLED]": "未取货已过期",
  "orderStatus[REFUNDED]": "已退款",

  "pageErrorNotLoadMessage": "加载失败, 请稍后重试",

  "registeredMemberSms[ALREADY_USER]": "此电话已被注册, 您可以前往登入",
  "registeredMemberSms[AREA_NOT_FOUND]": "区域不正确, 请返回重试",
  "registeredMemberSms[ConfirmButtonText]": "确定",
  "registeredMemberSms[DUPLICATE_PHONE]": "此电话号码已经存在",
  "registeredMemberSms[INVALID_PHONE_NUMBER]": "请输入正确的电话号码",
  "registeredMemberSms[INVALID_TOKEN]": "短讯验证码不正确",
  "registeredMemberSms[SYSTEM_ERROR]": "系统错误, 请稍后重试",
  "registeredMemberSms[TOO_EARLY]": "操作太频密, 请稍后重试",
  "registeredMemberSms[loginButton]": "已经注册过?",
  "registeredMemberSms[phoneInputViewPlaceholder]": "请输入手机号码",
  "registeredMemberSms[sendVerificationCodeSuccess]": "验证码已发送, 请留意您的手机短讯, 基于您手机的电讯商可能最多需时数分钟",
  "registeredMemberSms[title]": "会员注册",
  "registeredMemberSms[verificationInputViewButton]": "发送",
  "registeredMemberSms[verificationInputViewOffSentInfo]": "请输入手机号码并点击发送验证码",
  "registeredMemberSms[verificationInputViewOnSentInfo]": "验证码已发送, 请留意您的手机短讯, 基于您手机的电讯商可能最多需时数分钟",
  "registeredMemberSms[verificationInputViewPlaceholder]": "请输入验证码",
  "registeredMemberSms[verificationResendInputViewButton]": "再次发送",

  "shopCampaign": "商城活动",
  "shopCampaignAmountShown": "库存{number}件",
  "shopCampaignCountdownTime": "订单 {datetime} 到期",
  "shopCampaignDettailsCampaignMemberLogin": "必须要注册成为会员才能购买该活动商品",
  "shopCampaignDettailsCampaignNotAvailable": "该活动暂时不能购买",
  "shopCampaignDettailsPackageNotFound": "活动套餐已经下架",
  "shopCampaignDettails[EXCEED_PACKAGE_USER_AMOUNT_LIMIT]": "该产品刚刚已被抢光",
  "shopCampaignDettails[NOT_ENOUGH_INVENTORY]": "库存不足, 请稍后重试",
  "shopCampaignDettails[listItemButtonDelete]": "删除",
  "shopCampaignGroupConfirmedAmountUnit": "件",
  "shopCampaignGroupPriceText": "团购价",
  "shopCampaignGroupStatus[CANCELED]": "活动已取消",
  "shopCampaignGroupStatus[CONFIRMED]": "成团发货中",
  "shopCampaignGroupStatus[ENDED]": "团购已结束",
  "shopCampaignGroupStatus[ONGOING]": "成团数量",
  "shopCampaignGroupStatus[UPCOMING]": "即将开始",
  "shopCampaignNormalPriceText": "优惠价",
  "shopCampaignNormalStatus[ENDED]": "产品已暂时下架",
  "shopCampaignNormalStatus[ONGOING]": "产品现正有售",
  "shopCampaignNormalStatus[UPCOMING]": "产品即将推出",
  "shopCampaignOrder": "我的订单",
  "shopCampaignPackage": "套装",
  "shopCampaignPriceOriginalText": "超市价",
  "shopCampaignRemainingTime": "剩余时间",
  "shopCampaignSearchInputPlaceholder": "输入搜索心水货",
  "shopCampaignSearchReaultEmpty": "在上面输入搜索关键字",
  "shopCampaignSearchReaultHeaderLabel": "搜索结果",
  "shopCampaignShopModalButtonAddToCart": "加入购物车",
  "shopCampaignShopModalButtonPay": "立刻购买",
  "shopCampaignTagAll": "全部",

  "shopCampaingListNoDateText": "准备产品中, 敬请留意",

  "shopCart": "购物车",
  "shopCartCampaignPackagesModal[amountText]": "总计",
  "shopCartCampaignPackagesModal[noDateText]": "购物车没有东西",
  "shopCartCampaignPackagesModal[payButton]": "去结算",

  "shopDeliverySpLockerListNoDateText": "没有数据",

  "shopOrderCalculatedAdditionalAmount": "含支付附加费",
  "shopOrderCalculatedAdditionalShippingAmount": "含运费/附加费",
  "shopOrderDeatils[orderPaymentTransactionsTitle]": "支付方式",
  "shopOrderDeatils[typeTitle]": "类别",
  "shopOrderDeliveryAddressButtonSelect": "选择收货地址",
  "shopOrderDeliveryAddressLabel": "收货地址",
  "shopOrderDeliveryButtonSelect": "选择送货方式",
  "shopOrderDeliveryRecipientSelectTitle": "选择收货人",
  "shopOrderDeliveryRecipientTitle": "收件人",
  "shopOrderDeliveryRequestedAtLabel": "送货日期",
  "shopOrderDeliverySelectTitle": "选择送货方式",
  "shopOrderDeliveryTitle": "配送方式",
  "shopOrderDetailCancel": "取消订单",
  "shopOrderDetailCreatedAt": "创建时间",
  "shopOrderDetailDeleteOrder": "删除订单",
  "shopOrderDetailInfo": "订单项目",
  "shopOrderDetailPriceOriginal": "原始价格",
  "shopOrderDetailsErrorCode[CANCEL_NOT_ALLOWED]": "该订单不能被取消",
  "shopOrderDetailsErrorCode[NOT_FOUND]": "订单已经被删除",
  "shopOrderDetailsErrorCode[NO_PAYMENT_NEEDED]": "系统异常, 稍后重试",
  "shopOrderDetailsErrorCode[ORDER_IS_PAID]": "订单已经付款",
  "shopOrderDetailsErrorCode[PAYMENT_SYSTEM_ERROR]": "支付系统出错, 请稍后重试, 或者使用其他支付方式。",
  "shopOrderDetailsErrorCode[STRIPE_AMOUNT_TOO_LOW]": "抱歉, 金额太低无法使用此支付方式",
  "shopOrderDetailsErrorCode[WALLET_NOT_ENOUGHT_BALANCE]": "抱歉, 您的余额不足够",
  "shopOrderDetails[boxes]": "取货包",
  "shopOrderDetails[deleteShopOrderSuccess]": "订单删除成功",
  "shopOrderDetails[logisticsDisplay]": "物流跟踪",
  "shopOrderDetails[noPayment]": "暂时不支持该{name}支付",
  "shopOrderDetails[orderActionViewTitle]": "其他操作",
  "shopOrderDetails[orderDeliveryViewTitle]": "配送信息",
  "shopOrderDetails[orderDiscount]": "折扣金额",
  "shopOrderDetails[orderInfoViewTitle]": "订单信息",
  "shopOrderDetails[paymenError]": "订单支付失败",
  "shopOrderDetails[paymenSuccess]": "订单支付成功",
  "shopOrderDetails[putShopOrderSuccess]": "订单编辑成功",
  "shopOrderDetails[shopOrderCancelSuccess]": "订单取消成功",
  "shopOrderDetails[title]": "订单详情",
  "shopOrderListNoDateText": "暂无订单",
  "shopOrderQrCode[CANCELED]": "已取消",
  "shopOrderQrCode[COMPLETED]": "已取货",
  "shopOrderQrCode[EXPIRED]": "已过期",
  "shopOrderQrCode[RECALLED]": "没取货已经过期",
  "shopOrderQrCode[REFUNDED]": "已退款",
  "shopOrderRecipientButtonSelect": "选择收货人",
  "shopOrderStatusCompleted": "完成",
  "shopOrderStatusCompletedDescription": "流程完成",
  "shopOrderStatusDelivered": "待取货",
  "shopOrderStatusDeliveredDirectDescription": "用戶直接收貨",
  "shopOrderStatusDeliveredSelfPickupDescription": "用户前来取货",
  "shopOrderStatusDeliveredSpLockerDescription": "用戶從自提櫃收取",
  "shopOrderStatusPaid": "待分配",
  "shopOrderStatusPaidDescription": "分配包装",
  "shopOrderStatusPaidDirect": "待包裝",
  "shopOrderStatusPaidSpLockerDescription": "分配自提柜",
  "shopOrderStatusPending": "代付款",
  "shopOrderStatusPendingDescription": "用户付款",
  "shopOrderTotalAmount": "合计",
  "shopOrderTotalRefundedAmount": "已退款金额",
  "shopOrderUserDeliveryAddressDefaultLabel": "(默认)",
  "shopOrderUserDeliveryAddressTitle": "收货地址",
  "shopOrderUserRemark": "留言备注",
  "shopOrderUserRemarkEmpty": "无",

  "userCouponList[ButtonNotSelect]": "请选择优惠券",
  "userCouponList[noDataViewText]": "没有优惠券",
  "userCoupon[loadMoreEnd]": "没有更多优惠券",
  "userCoupon[title]": "我的优惠券",

  "userDeliveryAddressButtonAdd": "添加收货地址",
  "userDeliveryAddressButtonEdit": "编辑收货地址",
  "userDeliveryDeleteFailed": "删除失败",
  "userDeliveryDeleteSuccess": "删除成功",
  "userDeliveryRecipientButtonAdd": "添加收货人联系",
  "userDeliveryShippingFeeWavied": "免运费",
  "userDeliveryShippingFeeWaviedDescription": "满 ${number} 免运费",

  "总价": "总共",

  "总共商品": "共{number}件商品",

  "折后价格": "折后价格",

  "提交": "提交",

  "提取码": "提取码",

  "收货人": "收货人",

  "数量": "数量",

  "查询": "查询我的订单",

  "正常": "正常",

  "活动商城": "活动商城",

  "添加失败": "添加失败",

  "添加成功": "添加成功",

  "确定": "确定",

  "立刻购买": "立刻购买",

  "编辑失败": "编辑失败",

  "编辑成功": "编辑成功",

  "联系地址": "联系地址",

  "联系电话": "联系电话",

  "自提柜": "自提柜",

  "规格": "规格",

  "订单": "订单",

  "订单编号": "订单编号",

  "订单详情": "订单详情",

  "设置默认": "设置默认",

  "详情": "详情",

  "请输入": "请输入",

  "请输入订单编号登录": "请输入您的订单号",

  "请选择区域登录": "请选择您的区域",

  "请选择登录方式": "请选择登录方式",

  "购物车": "购物车",

  "输入银行卡": "输入您的信用卡号码",

  "运费": "运费",

  "重新加载": "重新加载",

  "附加费": "附加费",

  "限购数量": "每人限购：{number} 件"
}