import React from 'react';
import { connect, ConnectedProps } from 'react-redux'
import *as Ons from 'react-onsenui'
import JJ_UserDeliveryAddress from '../JJ_UserDeliveryAddress'
import *as ActionUserShopDeliveryAddress from '../../../actions/ActionUserShopDeliveryAddress'
import { GetShopDeliveryRes, GetUserShopDeliveryAddressParameters, GetUserShopDeliveryAddressListParameters } from '../../../api';
import tool from '../../../tool';
import {FaEdit} from 'react-icons/fa';
import moment from 'moment';

interface PageState {
    deliveryAddressId?: string
    deliveryAddressAddress?: string
    deliveryRequestedAt?: string
}

export interface Props {
    delivery: GetShopDeliveryRes
    deliveryAddressId?: string
    deliveryAddressAddress?: string
    deliveryRequestedAt?: string
    onSelect(
        props: {
            delivery: GetShopDeliveryRes
            deliveryAddressId?: string
            deliveryRequestedAt?: string
        }
    ): void
    amount: number
    checked: boolean
}



type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>

interface RootState {
    userShopDeliveryAddress: ActionUserShopDeliveryAddress.ActionUserShopDeliveryAddressRes,
}

const mapState = (state: RootState) => ({
    userShopDeliveryAddress: state.userShopDeliveryAddress,
})

const mapDispatch = {
    getUserShopDeliveryAddress: ActionUserShopDeliveryAddress.getUserShopDeliveryAddress,
    getUserShopDeliveryAddressList: ActionUserShopDeliveryAddress.getUserShopDeliveryAddressList,
}


export const connector = connect(
    mapState,
    mapDispatch
)


class JJ_UserDeliveryListItem extends React.Component<PageProps, PageState> {

    componentDidMount() {
        if (this.props.deliveryAddressId) {
            this._getUserShopDeliveryAddressAddress()
        }
    }

    constructor(props: PageProps) {
        super(props);

        const timetableDates = this._getTimetableDates();

        this.state = {
            deliveryAddressId: props.deliveryAddressId,
            deliveryAddressAddress: props.deliveryAddressAddress,
            deliveryRequestedAt: props.deliveryRequestedAt || timetableDates[0],
        }

    }


    _getUserShopDeliveryAddressAddress = async () => {
        try {
            const data = await this._getUserShopDeliveryAddress({ deliveryAddressId: this.state.deliveryAddressId || '' });
            this.setState({
                deliveryAddressAddress: data.address,
            })
        } catch (err) {}
    }

    _getUserShopDeliveryAddress = async (params: GetUserShopDeliveryAddressParameters) => {
        const res = await this.props.getUserShopDeliveryAddress(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }

    _getUserShopDeliveryAddressList = async (params: GetUserShopDeliveryAddressListParameters) => {
        const res = await this.props.getUserShopDeliveryAddressList(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }


    _getTimetableDates = () => {
        const {
            timetable,
        } = this.props.delivery

        // timetable
        let timetableDates: string[] = [];

        if (timetable) {
            const cutOffDay = timetable.cutOffTime > '00:00' && moment().format('HH:mm') >= timetable.cutOffTime ? 1 : 0;

            const dateBegin = moment().startOf('day').add(timetable.excludingDays + cutOffDay, 'day');
            const dateEnd = moment().startOf('day').add(timetable.includingDays, 'day');

            for (let date = moment(dateBegin); date < dateEnd; date.add(1, 'day')) {
                if (timetable.whitelistWeekdays) {
                    if (!timetable.whitelistWeekdays[date.isoWeekday()]) continue;
                }

                timetableDates.push(date.format('YYYY-MM-DD'));
            }

            if (timetable.blacklistDates) {
                timetableDates = timetableDates.filter(date => timetable.blacklistDates.indexOf(date) === -1);
            }

            if (timetable.whitelistDates) {
                timetableDates = [...timetableDates, ...timetable.whitelistDates].filter((value, index, self) => self.indexOf(value) === index);
            }

            timetableDates.sort();
        }

        return timetableDates;
    }

    _onSelect = () => {
        const {
            type,
        } = this.props.delivery

        this.props.onSelect({
            delivery: this.props.delivery,
            deliveryAddressId: this.state.deliveryAddressId,
            deliveryRequestedAt: this.state.deliveryRequestedAt,
        })

        if (type === 'DIRECT' && !this.state.deliveryAddressId) {
            (async () => {
                try {
                    const data = await this._getUserShopDeliveryAddressList({ onlyDefault: true });
                    if (data.data[0]) {
                        this.setState({
                            deliveryAddressId: data.data[0].id,
                            deliveryAddressAddress: data.data[0].address,
                        })
                        this.props.onSelect({
                            delivery: this.props.delivery,
                            deliveryAddressId: data.data[0].id,
                            deliveryRequestedAt: this.state.deliveryRequestedAt,
                        })
                    }
                } catch (err) {}
            })();
        }
    }

    _titleView = () => {

        const {
            name,

        } = this.props.delivery
        return (
            <div className='titleView'>
                <Ons.Radio
                    className='checked'
                    // onChange={event => this._onSelect()}
                    modifier='material'
                    checked={this.props.checked}//选中
                />
                <span className='name'>{name}</span>
            </div>
        )

    }
    _centerView = () => {
        const {
            type,
            description,
            timetable,
        } = this.props.delivery

        const timetableDates = this._getTimetableDates();

        return (
            <div className='centerView'>
                <span className='description'>{description}</span>
                {timetable && this.props.checked && (
                    <div className="timetable">
                        <span>{tool.intl.formatMessage({ id: 'shopOrderDeliveryRequestedAtLabel' })}:</span>
                        <Ons.Select
                            // size='6'
                            className="select"
                            value={this.state.deliveryRequestedAt}
                            modifier='underbar'
                            onChange={e => {
                                this.setState({
                                    deliveryRequestedAt: e.target.value,
                                }, () => this._onSelect())
                            }}
                        >
                            {timetableDates.map((value) => {
                                return <option key={value} value={value}>{value}</option>
                            })}
                        </Ons.Select>
                    </div>
                )}

                {type === 'DIRECT' && this.props.checked && (
                    <div
                        className="JJ_UserDeliveryAddress"
                        onClick={() => {
                            tool.navigator.pushPage({
                                key: 'JJ_UserDeliveryAddress',
                                props: {
                                    onSelect: (deliveryAddressId: string, deliveryAddressAddress: string) => {
                                        this.setState({
                                            deliveryAddressId,
                                            deliveryAddressAddress,
                                        }, () => this._onSelect())
                                    },
                                    checked: this.props.checked,
                                    deliveryAddressId: this.state.deliveryAddressId,
                                    deliveryAddressAddress: this.state.deliveryAddressAddress,
                                }
                            })
                        }}
                    >
                        {this.state.deliveryAddressId && (
                            <div className="selected">
                                <span className="label">{tool.intl.formatMessage({ id: 'shopOrderDeliveryAddressLabel' })}:</span>
                                <span>{this.state.deliveryAddressAddress || '...'}</span>
                            </div>
                        )}
                        <div className="bottom">
                            <FaEdit />
                            <span>{tool.intl.formatMessage({ id: 'shopOrderDeliveryAddressButtonSelect' })}</span>
                        </div>
                    </div>
                )}
            </div>
        )

    }
    _shippingFeeView = () => {
        const {
            shippingFee,
            shippingFeeWaivingAmount,

        } = this.props.delivery
        return (
            <div className='shippingFeeView'>
                {
                    shippingFee > 0 && (!shippingFeeWaivingAmount || this.props.amount < shippingFeeWaivingAmount) ? (
                        <span className='shippingFee'>
                            {tool.intl.formatMessage({ id: '运费' })}: + ${tool.math.initPrice({ price: shippingFee }).toFixed()}
                        </span>
                    ) : (
                            <span className='shippingFee wavied'>
                                {tool.intl.formatMessage({ id: 'userDeliveryShippingFeeWavied' })}
                            </span>
                        )
                }
                {shippingFeeWaivingAmount && shippingFeeWaivingAmount > 0 &&
                    <span
                        className='shippingFeeWaivingAmount'>
                        {tool.intl.formatMessage({ id: 'userDeliveryShippingFeeWaviedDescription' }, { number: tool.math.initPrice({ price: shippingFeeWaivingAmount }).toFixed() })}
                    </span>}
            </div>
        )

    }

    render() {
        return (
            <div
                id="JJ_UserDeliveryListItem"
                onClick={() => this._onSelect()}
            >
                {this._titleView()}
                {this._shippingFeeView()}
                {this._centerView()}
        </div>
        );
    }
}



export default connector(JJ_UserDeliveryListItem)
