import React from 'react';
import { Button } from 'antd-mobile'
import { GetShopCampaignRes } from '../../../api/api-res-type';
import tool from '../../../tool';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import JJ_Components from '../../../components/JJ_Components';
import moment from 'moment';


interface PageState {

}
type ActionType = {
    type: 'details'
    data: PageProps
}

export interface Props {
    onAction(params: ActionType): void
    onEndDate?(props: PageProps): void
    campaign: GetShopCampaignRes
}

type PageProps = Props & InjectedIntlProps


class JJ_ShopCampaignListItem extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {}
    }


    _headView = () => {

        const thumbnails = this.props.campaign.thumbnails
        const [thumbnail] = thumbnails

        return <img
            src={thumbnail.url}
            style={tool.image.getImageStyle(thumbnail.meta, (window.innerWidth - 40) / 2, window.innerHeight * .3)}
        />

    }



    _priceTextView = () => {
        if (this.props.campaign.campaignPackages) {
            const [campaignPackage] = this.props.campaign.campaignPackages
            const { price, priceOriginal } = campaignPackage
            return (
                <div
                    className='priceTextView'
                >

                    <span
                        className='text'
                    >
                        {`${tool.intl.formatMessage({ id: 
                            this.props.campaign.type==='GROUP'
                            ?'shopCampaignGroupPriceText' 
                            :'shopCampaignNormalPriceText' 
                            })}:`}

                        <span
                            className='priceText'
                        >$
                        <span
                                className='price'
                            >{tool.math.initPrice({ price: price }).toFixed(2)}</span>

                        </span>

                    </span>

                    <span
                        className='text'
                        style={{ marginLeft: 10 }}
                    >
                        {`${tool.intl.formatMessage({ id: 'shopCampaignPriceOriginalText' })}:`}

                        <span
                            className='priceOriginal'
                        >${tool.math.initPrice({ price: priceOriginal }).toFixed(2)}</span>
                    </span>


                </div>
            )
        }
        return null
    }


    /**
     * 倒计时视图
     */
    _countdownView = (campaign: GetShopCampaignRes) => {
        switch (campaign.type) {
            case 'FLASH':
            case 'NORMAL':
            case 'GROUP': {

                switch (campaign.status) {
                    case 'ONGOING': {
                        // 進行中
                        if (campaign.type !== 'NORMAL' && campaign.endAt) {
                            return (
                                <div
                                    className='countdownView'
                                >
                                    <span
                                        className={'countdownViewTime'}
                                    >
                                        {`${tool.intl.formatMessage({ id: 'shopCampaignCountdownTime' }, {
                                            datetime: moment(campaign.endAt).format('YYYY-MM-DD HH:mm'),
                                        })}  `}
                                    </span>

                                </div>

                            )
                        }
                        return null
                    }
                    case 'UPCOMING': {
                        // 即將開始
                        if (campaign.beginAt) {
                            return (
                                <div
                                    className='countdownView'
                                >
                                    <span
                                        className={'countdownViewTime'}
                                    >
                                        {`${tool.intl.formatMessage({ id: 'shopCampaignCountdownTime' }, {
                                            datetime: moment(campaign.beginAt).format('YYYY-MM-DD HH:mm'),
                                        })}  `}
                                    </span>
                                </div>
                            )
                        }
                        return null
                    }
                    default: return null
                }
            }

        }
    }



    _getButtonText = (campaign: GetShopCampaignRes) => {

        switch (campaign.type) {
            case 'NORMAL': {

                switch (campaign.status) {
                    case 'ONGOING': {
                        return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[ONGOING]' })
                    }
                    case 'UPCOMING': {
                        return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[UPCOMING]' })
                    }
                    case 'ENDED': {
                        return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[ENDED]' })
                    }
                    default: return ''
                }

            }

            case 'GROUP': {

                const groupConfirmedAmount = campaign.groupConfirmedAmount

                switch (campaign.status) {
                    case 'CANCELED': {
                        return tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[CANCELED]' })
                    }
                    case 'CONFIRMED': {
                        return tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[CONFIRMED]' })
                    }
                    case 'ENDED': {
                        return tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[ENDED]' })
                    }
                    case 'ONGOING': {
                        return `${tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[ONGOING]' })}: ${groupConfirmedAmount} ${tool.intl.formatMessage({ id: 'shopCampaignGroupConfirmedAmountUnit' })}`
                    }
                    case 'UPCOMING': {
                        return tool.intl.formatMessage({ id: 'shopCampaignGroupStatus[UPCOMING]' })
                    }
                }

            }
            case 'FLASH': {

                switch (campaign.status) {
                    case 'ONGOING': {
                        return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[ONGOING]' })
                    }
                    case 'UPCOMING': {
                        return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[UPCOMING]' })
                    }
                    case 'ENDED': {
                        return tool.intl.formatMessage({ id: 'shopCampaignNormalStatus[ENDED]' })
                    }
                    default: return ''
                }

            }
        }



    }



    _footView = (campaign: GetShopCampaignRes) => {



        const groupConfirmedAmount = campaign.groupConfirmedAmount
        const groupOrderedAmount = campaign.groupOrderedAmount
        const progress = parseInt(`${groupOrderedAmount / groupConfirmedAmount * 100}`) || 0


        switch (campaign.type) {


            case 'GROUP': {




                return (
                    <div
                        className='footView'
                    >
                        {this._countdownView(campaign)}

                        <JJ_Components.baseProgressView
                            className='progressView'
                            progress={progress}
                        >
                            <span
                                className='baseProgressSubText'
                            >
                                {this._getButtonText(campaign)}{` ${groupOrderedAmount} / ${groupConfirmedAmount}`}
                            </span>
                        </JJ_Components.baseProgressView>
                    </div>
                )
            }

            case 'FLASH': 
            case 'NORMAL': {

                return (
                    <div
                        className='footView'
                    >
                        {this._countdownView(campaign)}

                        {campaign.status !== 'ONGOING' && (
                            <JJ_Components.baseProgressView
                                className='progressView'
                                progress={100}
                            >
                                <span
                                    className='baseProgressSubText'
                                >
                                    {this._getButtonText(campaign)}
                                </span>
                            </JJ_Components.baseProgressView>
                        )}
                    </div>
                )
            }
        }

    }

    render() {
        const { name, tagline } = this.props.campaign
        return (
            <div id='JJ_ShopCampaignListItem'
                onClick={e => {
                    this.props.onAction({ type: 'details', data: this.props })
                }}
            >
                {this._headView()}
                <span
                    className='name'
                >{name}</span>
                <span
                    className='tagline'
                >{tagline}</span>
                {this._priceTextView()}
                {this._footView(this.props.campaign)}
            </div>
        );
    }
}
export default injectIntl(JJ_ShopCampaignListItem)

