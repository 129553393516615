import Type from './ActionType';
import api, { GetShopOrderRes, GetShopOrderListParameters, GetShopOrderParameters } from '../api';
import { MyThunkDispatch } from './action-interface';
import tool from '../tool';

const upDataAction = (data: ActionOrderRes) => {
    return {
        type: Type.orderType.UP_DATA,
        ...data
    }
}

const initDataAction = () => {
    return {
        type: Type.orderType.INIT_DATA
    }
}

interface ActionOrderListRes {
    [id: string]: GetShopOrderRes
}
export interface ActionOrderRes {
    list: ActionOrderListRes
}

export const getShopOrderList = (params: GetShopOrderListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getShopOrderList(params)
        if (res.kind === 'ok') {
            const order: ActionOrderRes = tool.store.getState().order
            res.data.data.forEach(item => {
                order.list[item.id] = item
            })
            dispatch(upDataAction(order))
        }
        return res
    }
}
export const getShopOrder = (params: GetShopOrderParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getShopOrder(params)
        if (res.kind === 'ok') {
            const order: ActionOrderRes = tool.store.getState().order
            order.list[res.data.id] = res.data
            dispatch(upDataAction(order))
        }
        return res
    }
}

export const initData = () => {
    return (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}












