import React from 'react'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import JJ_LicensesList from './PageItem/JJ_LicensesList'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import tool from '../../tool'


interface PageState {

}


export interface Props {

}

type PageProps = Props & InjectedIntlProps



class JJ_Licenses extends React.Component<PageProps, PageState>{

    constructor(props: PageProps) {
        super(props);
    }

    static defaultProps = {

    };


    componentDidMount() {
        console.log('JJ_Licenses', this.props)

    }

    render() {

        const nav = {
            showLeftIcon: true,
            title: tool.intl.formatMessage({ id: 'JJ_Licenses[title]' }),
        }

        return (

            <Ons.Page
                renderToolbar={() => <JJ_Nav {...nav} />}
            >

                <div
                    id='JJ_Licenses'
                >
                    <JJ_LicensesList />
                </div>

            </Ons.Page>


        )

    }
}

export default injectIntl(JJ_Licenses)


