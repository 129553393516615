import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import JJ_Components from '../../components/JJ_Components'
import JJ_ShopOrderDetailHeadView from './PageItem/JJ_ShopOrderDetailHeadView'
import JJ_ShopOrderCampaignPackage from './PageItem/JJ_ShopOrderCampaignPackage'
import JJ_ShopOrderBoxItem from './PageItem/JJ_ShopOrderBoxItem'
import *as ActionOrder from "../../actions/ActionOrder";
import { InjectedIntlProps, injectIntl } from 'react-intl'
import *as ActionCampaign from "../../actions/ActionCampaign";
import tool from '../../tool'
import api, { GetShopOrderParameters, PutShopOrderParameters, PostShopOrderCancelParameters, DeleteShopOrderParameters, GetShopOrderRes, GetShopDeliveryParameters, GetUserShopDeliveryAddressParameters, GetUserShopDeliveryRecipientParameters, GetShopDeliveryRes, GetPaymentRes, PostShopOrderPaymentParameters, GetUserCouponParameters, GetShopCampaignParameters, GetShopCampaignRes, GetPaymentParameters, GetWalletListParameters, GetAreaKioskParameters, GetAreaKioskRes } from '../../api'
import *as ActionArea from '../../actions/ActionArea'
import *as ActionUserShopDeliveryAddress from '../../actions/ActionUserShopDeliveryAddress'
import *as ActionShopDelivery from '../../actions/ActionShopDelivery'
import *as ActionShopDeliveryRecipient from '../../actions/ActionShopDeliveryRecipient'
import *as ActionModal from '../../actions/ActionModal'
import JJ_ShopOrderLogisticsDisplay from './PageItem/JJ_ShopOrderLogisticsDisplay'
import moment from 'moment'
import { ActionUserRes } from '../../actions/ActionUser'
import *as ActionUserCoupon from '../../actions/ActionUserCoupon'
import config from '../../config/config'
import { render } from 'react-dom'

declare const window: any;

interface PageState {
    pageKey: number
    payment?: GetPaymentRes
    areaKiosks: GetAreaKioskRes[]
}

export interface Props {
    orderId: string
    moduleId: string
    onDelete?(orderId: string): void
    onCancel?(orderId: string): void
    /**
     * 是否显示支付描述
     */
    showPaymentDescription?: boolean
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    areaModule: ActionArea.ActionAreaModuleRes,
    campaign: ActionCampaign.ActionCampaignRes,
    order: ActionOrder.ActionOrderRes,
    user: ActionUserRes,
    userCoupon: ActionUserCoupon.ActionUserCouponRes,
    userShopDeliveryAddress: ActionUserShopDeliveryAddress.ActionUserShopDeliveryAddressRes,
    shopDelivery: ActionShopDelivery.ActionShopDeliveryRes,
    shopDeliveryRecipient: ActionShopDeliveryRecipient.ActionUserShopDeliveryRecipientRes,
}

const mapState = (state: RootState) => ({
    areaModule: state.areaModule,
    campaign: state.campaign,
    order: state.order,
    user: state.user,
    userCoupon: state.userCoupon,
    userShopDeliveryAddress: state.userShopDeliveryAddress,
    shopDelivery: state.shopDelivery,
    shopDeliveryRecipient: state.shopDeliveryRecipient,
})

const mapDispatch = {
    getShopOrder: ActionOrder.getShopOrder,
    getShopDelivery: ActionShopDelivery.getShopDelivery,
    getUserCoupon: ActionUserCoupon.getUserCoupon,
    getShopCampaign: ActionCampaign.getShopCampaign,
    getUserShopDeliveryRecipient: ActionShopDeliveryRecipient.getUserShopDeliveryRecipient,
    getUserShopDeliveryAddress: ActionUserShopDeliveryAddress.getUserShopDeliveryAddress,
    getKioskData: ActionArea.getKioskData,
    showPaymentModal: ActionModal.showPaymentModal,
}


export const connector = connect(
    mapState,
    mapDispatch
)

interface ListData {
    center: {
        title: string
        details?: string
        subDetails?: string
    }
    right?: {
        title: string
    }
    left?: {
        camponent: ReactElement
    }
    expandableContent?: ReactElement
    onAction?(): void
    disabled?: boolean
}



class JJ_ShopOrderDetails extends React.Component<PageProps, PageState>  {

    componentDidMount() {

        if (this.props.showPaymentDescription) {
            tool.dialog.alert({
                message: config.groupOrderPaymentDescription.details[this.props.user.lang],
                title: config.groupOrderPaymentDescription.title[this.props.user.lang],
            })
        }

        this._orderStatusDeliveredTinterval()

    }

    componentWillUnmount() {
        this.orderStatusDeliveredTinterval && clearInterval(this.orderStatusDeliveredTinterval)
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
            pageKey: Date.now(),
            areaKiosks: [],
        }
    }
    orderStatusDeliveredTinterval?: any

    /**
     * 没15秒检查订单状态是在 待取货 DELIVERED
     * true:初始化数据
     * false:Null
     */
    _orderStatusDeliveredTinterval = () => {
        this.orderStatusDeliveredTinterval = setInterval(() => {
            const order = this.props.order.list[this.props.orderId]
            if (order && order.status === 'DELIVERED') {
                this._initData()
            }
        }, 15000)
    }



    /**
     * 初始化数据
     */
    _initData = async () => {
        try {
            const order = await this._getShopOrder({ orderId: this.props.orderId })
            const { deliveryId, deliveryRecipientId, deliveryAddressId, userCouponId, campaignPackages, paymentTransactions } = order
            const campaignId = campaignPackages[0].campaignId
            await this._getShopCampaign({ campaignId })
            userCouponId && await this._getUserCoupon({ id: userCouponId })
            deliveryAddressId && await this._getUserShopDeliveryAddress({ deliveryAddressId })
            deliveryId && await this._getShopDelivery({ deliveryId })
            deliveryRecipientId && await this._getUserShopDeliveryRecipient({ deliveryRecipientId })
            if (paymentTransactions && paymentTransactions[0]) {
                const payment = await this._getPayment({ paymentId: paymentTransactions[0].paymentId })
                this.setState({ payment })
            }

            const areaKioskIds = order.boxes.map(box => box.pickupAreaKioskId).filter((value, index, self) => value && self.indexOf(value) === index);
            const areaKiosks: GetAreaKioskRes[] = [];
            for (let areaKioskId of areaKioskIds) {
                if (areaKioskId) {
                    const areaKiosk = await this._getAreaKiosk({ areaKioskId });
                    areaKiosks.push(areaKiosk);
                }
            }
            this.setState({ areaKiosks });

        } catch (error) {
            console.log(error)
            throw error
        }
    }


    _getShopOrder = async (params: GetShopOrderParameters) => {
        const res = await this.props.getShopOrder(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'NOT_FOUND': {
                    //订单不存在跳转
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[NOT_FOUND]' }) })
                    this.props.onDelete && this.props.onDelete(this.props.orderId)
                    tool.navigator.popPage()
                }
            }
            throw new Error(res.kind)
        }
    }
    _getPayment = async (params: GetPaymentParameters) => {
        const res = await api.getPayment(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {

            }
            throw new Error(res.kind)
        }
    }
    _getShopDelivery = async (params: GetShopDeliveryParameters) => {
        const res = await this.props.getShopDelivery(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {

            }
            throw new Error(res.kind)
        }
    }
    _getUserCoupon = async (params: GetUserCouponParameters) => {
        const res = await this.props.getUserCoupon(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {

            }
            throw new Error(res.kind)
        }
    }
    _getShopCampaign = async (params: GetShopCampaignParameters) => {
        const res = await this.props.getShopCampaign(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {

            }
            throw new Error(res.kind)
        }
    }
    _getUserShopDeliveryAddress = async (params: GetUserShopDeliveryAddressParameters) => {
        const res = await this.props.getUserShopDeliveryAddress(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {

            }
            throw new Error(res.kind)
        }
    }
    _getUserShopDeliveryRecipient = async (params: GetUserShopDeliveryRecipientParameters) => {
        const res = await this.props.getUserShopDeliveryRecipient(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {

            }
            throw new Error(res.kind)
        }
    }
    _getAreaKiosk = async (params: GetAreaKioskParameters) => {
        const res = await this.props.getKioskData(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {

            }
            throw new Error(res.kind)
        }
    }


    _putShopOrder = async (params: PutShopOrderParameters) => {
        const res = await api.putShopOrder(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'DELIVERY_ADDRESS_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[DELIVERY_ADDRESS_NOT_FOUND]' }) })

                }
                    break
                case 'DELIVERY_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[DELIVERY_NOT_FOUND]' }) })


                }
                    break
                case 'DELIVERY_RECIPIENT_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[DELIVERY_RECIPIENT_NOT_FOUND]' }) })


                }
                    break
                case 'NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[NOT_FOUND]' }) })


                }
                    break
                case 'UPDATE_NOT_ALLOWED_AFTER_PAID': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[UPDATE_NOT_ALLOWED_AFTER_PAID]' }) })

                }
                    break
            }
            throw new Error(res.kind)
        }
    }


    _postShopOrderCancel = async (params: PostShopOrderCancelParameters) => {
        const res = await api.postShopOrderCancel(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'CANCEL_NOT_ALLOWED': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[CANCEL_NOT_ALLOWED]' }) })

                }
                    break

            }
            throw new Error(res.kind)
        }
    }


    _getWalletList = async (params: GetWalletListParameters) => {
        const res = await api.getWalletList(params)

        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {
                }
                    break

            }
            throw new Error(res.kind)
        }
    }


    _postShopOrderPayment = async (params: PostShopOrderPaymentParameters) => {
        const res = await api.postShopOrderPayment(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[INVALID_PARAMS]' }) })
                }
                    break
                case 'NO_PAYMENT_NEEDED': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[NO_PAYMENT_NEEDED]' }) })
                }
                    break
                case 'ORDER_DELIVERY_MISSING': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[ORDER_DELIVERY_MISSING]' }) })
                }
                    break
                case 'ORDER_DELIVERY_RECIPIENT_MISSING': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[ORDER_DELIVERY_RECIPIENT_MISSING]' }) })
                }
                    break
                case 'ORDER_IS_CANCELED': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[ORDER_IS_CANCELED]' }) })
                }
                    break
                case 'ORDER_IS_EXPIRED': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[ORDER_IS_EXPIRED]' }) })
                }
                    break
                case 'ORDER_IS_PAID': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[ORDER_IS_PAID]' }) })
                }
                    break
                case 'PAYMENT_NOT_AVAILABLE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[PAYMENT_NOT_AVAILABLE]' }) })
                }
                    break
                case 'PAYMENT_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[PAYMENT_NOT_FOUND]' }) })
                }
                    break
                case 'PAYMENT_SYSTEM_ERROR': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[PAYMENT_SYSTEM_ERROR]' }) })
                }
                    break
                case 'STRIPE_CARD_DECLINED': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_CARD_DECLINED]' }) })
                }
                    break
                case 'STRIPE_EXPIRED_CARD': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_EXPIRED_CARD]' }) })
                }
                    break
                case 'STRIPE_INCORRECT_CVC': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_INCORRECT_CVC]' }) })
                }
                    break
                case 'STRIPE_INCORRECT_NUMBER': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_INCORRECT_NUMBER]' }) })
                }
                    break
                case 'STRIPE_INCORRECT_ZIP': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_INCORRECT_ZIP]' }) })
                }
                    break
                case 'STRIPE_INVALID_CVC': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_INVALID_CVC]' }) })
                }
                    break
                case 'STRIPE_INVALID_EXPIRY_MONTH': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_INVALID_EXPIRY_MONTH]' }) })
                }
                    break
                case 'STRIPE_INVALID_EXPIRY_YEAR': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_INVALID_EXPIRY_YEAR]' }) })
                }
                    break
                case 'STRIPE_INVALID_NUMBER': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_INVALID_NUMBER]' }) })
                }
                    break
                case 'WALLET_NOT_ENOUGHT_BALANCE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[WALLET_NOT_ENOUGHT_BALANCE]' }) })
                }
                    break


            }
            throw new Error(res.kind)
        }
    }


    _deleteShopOrder = async (params: DeleteShopOrderParameters) => {
        const res = await api.deleteShopOrder(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            throw new Error(res.kind)
        }
    }


    _onPostShopOrderCancel = async () => {

        try {
            tool.message.loading({})
            await this._postShopOrderCancel({ orderId: this.props.orderId })
            this.props.onCancel && this.props.onCancel(this.props.orderId)
            await this._getShopOrder({ orderId: this.props.orderId })
            tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[shopOrderCancelSuccess]' }) })
            tool.message.hide()
        } catch (error) {
            tool.message.hide()
            console.log(error)
        }
    }
    _onPutShopOrder = async (params: PutShopOrderParameters) => {

        try {
            tool.message.loading({})
            await this._putShopOrder(params)
            await this._getShopOrder({ orderId: params.orderId })
            // this.setState({pageKey:Date.now()})
            tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[putShopOrderSuccess]' }) })
            tool.message.hide()
        } catch (error) {
            tool.message.hide()
            console.log(error)
        }
    }


    _onDeleteShopOrder = async () => {

        try {
            tool.message.loading({})
            await this._deleteShopOrder({ orderId: this.props.orderId })
            this.props.onDelete && this.props.onDelete(this.props.orderId)
            tool.navigator.popPage()
            tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[deleteShopOrderSuccess]' }) })
            tool.message.hide()
        } catch (error) {
            tool.message.hide()
            console.log(error)
        }
    }


    _onPayment = async (order: GetShopOrderRes, isPrepaidPartial: boolean, price: number) => {

        try {
            if (!order.deliveryId) {
                tool.message.info({ content: tool.intl.formatMessage({ id: 'shopOrderDeliverySelectTitle' }) })
                return
            }

            if (!order.deliveryRecipientId) {
                tool.message.info({ content: tool.intl.formatMessage({ id: 'shopOrderDeliveryRecipientSelectTitle' }) })
                return
            }

            let payment: GetPaymentRes | undefined

            payment = await this.props.showPaymentModal(price)

            if (payment) {
                switch (payment.provider) {

                    case 'WALLET': {

                        try {
                            tool.message.loading({})
                            const wallet = await this._getWalletList({ count: 1000, userId: this.props.user.id })
                            const postShopOrderPaymentRes = await this._postShopOrderPayment({
                                orderId: order.id,
                                paymentId: payment.id,
                                walletInfo: {
                                    walletId: wallet.data[0].id,
                                },
                                isPrepaidPartial,
                            })
                            if (postShopOrderPaymentRes.isCompleted) {
                                await this._initData()
                                tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[paymenSuccess]' }) })
                            } else {
                                tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[paymenError]' }) })
                            }
                            tool.message.hide()
                        } catch (error) {
                            tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[paymenError]' }) })
                            tool.message.hide()
                            console.error(error)
                        }
                    }
                        break

                    case 'STRIPE': {
                        if (payment.customId === 'STRIPE_APPLE_PAY') {
                            tool.message.loading({})

                            const postShopOrderPaymentRes = await this._postShopOrderPayment({
                                orderId: order.id,
                                paymentId: payment.id.substr(0, 32),
                                isPrepaidPartial,
                            })

                            const totalPrice: number = price + (payment.calculatedAdditionalAmount || 0)

                            const applePayListener = async (event) => {
                                try {
                                    const message = event.data;
                                    if (message.action === 'applePay') {
                                        document.removeEventListener('message', applePayListener);
                                        tool.message.hide();

                                        if (message.success) {
                                            await this._initData()
                                            tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[paymenSuccess]' }) })
                                        }
                                    }
                                } catch (err) {
                                }
                            };

                            document.addEventListener('message', applePayListener);

                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                action: 'applePay',
                                label: this.props.areaModule.list[this.props.moduleId].title,
                                amount: (totalPrice / 10000).toFixed(2),
                                clientSecret: postShopOrderPaymentRes.stripe.clientSecret,
                                paymentTransactionId: postShopOrderPaymentRes.paymentTransactionId,
                            }));

                        } else {
                            tool.navigator.pushPage({
                                key: 'JJ_StripePay', props: {
                                    price,
                                    payment,
                                    onPayment: async ({ payment, tokenId }) => {
                                        try {
                                            tool.message.loading({})
                                            const postShopOrderPaymentRes = await this._postShopOrderPayment({
                                                orderId: order.id,
                                                paymentId: payment.id,
                                                stripeInfo: {
                                                    sourceId: tokenId
                                                },
                                                isPrepaidPartial,
                                            })
                                            if (postShopOrderPaymentRes.isCompleted) {
                                                await this._initData()
                                                tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[paymenSuccess]' }) })
                                                tool.navigator.popPage()
                                            } else {
                                                tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[paymenError]' }) })
                                            }
                                            tool.message.hide()
                                        } catch (error) {
                                            tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[paymenError]' }) })
                                            tool.message.hide()
                                            console.error(error)
                                        }
                                    }
                                }
                            })
                        }
                    }
                        break
                    case 'MPAY_MOBILE': {
                        tool.message.loading({})
                        const postShopOrderPaymentRes = await this._postShopOrderPayment({
                            orderId: order.id,
                            paymentId: payment.id,
                            mpayInfo: {
                                returnUrl: window.location.href,
                            },
                            isPrepaidPartial,
                        })
                        tool.message.hide()
                        if (!postShopOrderPaymentRes.isCompleted && postShopOrderPaymentRes.url) {
                            tool.storage.setItem({ key: 'pushAction', data: { key: 'pushShopOrderDetails', data: { id: order.id } } })
                            window.location.href = postShopOrderPaymentRes.url
                        }
                    }
                        break
                    default: {
                        tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetails[noPayment]' }, { name: payment.name }) })
                    }
                }
            }

        } catch (error) {
            console.log(error)
            tool.message.hide()
        }
    }



    render() {

        const nav = {
            showLeftIcon: true,
            showRightIcon: false,
            title: tool.intl.formatMessage({ id: 'shopOrderDetails[title]' }),
            ...this.props
        }



        const totalPriceInfo = () => {
            const { shippingFee, price, priceOriginal, additionalFee, discount, refundedAmount } = order
            const totalPriceInfo = [
                {
                    name: tool.intl.formatMessage({ id: 'shopOrderDetailPriceOriginal' }),
                    value: `$${tool.math.initPrice({ price: priceOriginal }).toFixed(2)}`,
                    className: 0
                },
                {
                    name: tool.intl.formatMessage({ id: '折后价格' }),
                    value: `$${tool.math.initPrice({ price: price }).toFixed(2)}`,
                    type: 0
                },
                {
                    name: tool.intl.formatMessage({ id: '运费' }),
                    value: `$${tool.math.initPrice({ price: shippingFee }).toFixed(2)}`,
                    type: 0
                },
                ...(additionalFee ? [
                    {
                        name: tool.intl.formatMessage({ id: '附加费' }),
                        value: `$${tool.math.initPrice({ price: additionalFee }).toFixed(2)}`,
                        type: 0
                    },
                ] : []),
                ...(discount ? [
                    {
                        name: tool.intl.formatMessage({ id: 'shopOrderDetails[orderDiscount]' }),
                        value: `- $${tool.math.initPrice({ price: discount }).toFixed(2)}`,
                        type: 0
                    },
                ] : []),
                {
                    name: tool.intl.formatMessage({ id: 'shopOrderTotalAmount' }),
                    value: `$${tool.math.initPrice({ price: (price + shippingFee + additionalFee - discount) }).toFixed(2)}`,
                    type: 1,
                },
                ...(refundedAmount ? [
                    {
                        name: tool.intl.formatMessage({ id: 'shopOrderTotalRefundedAmount' }),
                        value: `$${tool.math.initPrice({ price: refundedAmount }).toFixed(2)}`,
                        type: 2,
                    },
                ] : []),
            ]

            return (
                <div
                    className='totalPriceInfo'
                >
                    {totalPriceInfo.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={`itemBox ${item.type === 1 ? 'priceBox' : ''} ${item.type === 2 ? 'refundBox' : ''}`}
                            >
                                <span
                                    className={`name ${item.type === 1 ? 'priceName' : ''}`}
                                >{item.name}</span>
                                <span
                                    className={`value ${item.type === 1 ? 'priceValue' : ''}`}
                                >{item.value}</span>

                            </div>
                        )

                    })}
                </div>
            )
        }




        const boxeInfo = (boxes: GetShopOrderRes['boxes']) => {
            return (
                <div
                    className='boxeInfo'
                >
                    <div id="JJ_ShopOrderBox">
                        {boxes.map((item, index) => {
                            const areaKiosk = this.state.areaKiosks.find(areaKioskItem => areaKioskItem.id === item.pickupAreaKioskId);
                            return (
                                <JJ_ShopOrderBoxItem
                                    key={index}
                                    areaKiosk={areaKiosk}
                                    boxe={item}
                                    user={this.props.user}
                                    style={{ marginTop: index === 0 ? 0 : 10 }}
                                />
                            )
                        })}
                    </div>
                </div>
            )

        }


        const getOrderListView = (title: string, dataSource: ListData[]) => {
            return (
                <>
                    <Ons.ListTitle
                        style={{ marginTop: 8, marginBottom: 2, fontSize: 16 }}
                    >
                        {title}
                    </Ons.ListTitle>
                    <Ons.List
                        style={{ marginBottom: 12 }}
                        modifier='inset'
                        className='orderListView'
                        dataSource={dataSource}
                        renderRow={(row, idx) => (
                            <Ons.ListItem
                                className={`orderListItemView ${row.disabled ? 'orderListItemViewDisabled' : ''}`}
                                key={row.center.title}
                                expanded={true}
                                expandable={(Boolean(row.expandableContent))}
                                lockOnDrag={true}
                                tappable={(Boolean(row.onAction) && !row.disabled)}
                                modifier={`${Boolean(row.onAction) ? 'chevron' : ''}`}
                                onClick={() => {
                                    !row.disabled && row.onAction && row.onAction()
                                }}
                            >
                                {row.left && <div className="left">{row.left.camponent}</div>}

                                <div className="center">
                                    <span className='centerTitle'>{row.center.title}</span>
                                    {row.center.details && <span className='centerDetails'>{row.center.details}</span>}
                                    {row.center.subDetails && <span className='centerSubDetails'>{row.center.subDetails}</span>}
                                </div>
                                {
                                    row.right
                                        ? (
                                            <div className="right">{row.right.title}</div>
                                        )
                                        : null
                                }
                                <div className="expandable-content">{row.expandableContent}</div>

                            </Ons.ListItem>
                        )}
                    />
                </>

            )

        }

        /**
         * 订单配送信息
         */
        const orderDeliveryView = (order: GetShopOrderRes, ) => {
            const title = tool.intl.formatMessage({ id: 'shopOrderDetails[orderDeliveryViewTitle]' })

            const delivery = this.props.shopDelivery.list[order.deliveryId || '']
            const deliveryRecipient = this.props.shopDeliveryRecipient.list[order.deliveryRecipientId || '']
            const dataSource: ListData[] = [
                {
                    center: {
                        title: tool.intl.formatMessage({ id: 'shopOrderDeliveryRecipientTitle' }),
                        details: deliveryRecipient && deliveryRecipient.recipientName,
                        subDetails: deliveryRecipient && deliveryRecipient.recipientPhone,
                    },

                    right: !deliveryRecipient ? {
                        title: tool.intl.formatMessage({ id: 'listItemRight[notSelected]' }),
                    } : undefined,
                    disabled: order.status !== 'PENDING',
                    onAction: () => {
                        tool.navigator.pushPage({
                            key: 'JJ_UserDeliveryRecipient', props: {
                                onSubmit: async deliveryRecipientId => {
                                    await this._onPutShopOrder({
                                        orderId: order.id,
                                        deliveryRecipientId,
                                    })
                                }
                            }
                        })
                    }

                },
                {
                    center: {
                        title: tool.intl.formatMessage({ id: 'shopOrderDeliveryTitle' }),
                        details: delivery && delivery.name,
                        subDetails: delivery ? (delivery.description
                            + (delivery.type === 'DIRECT' && order.deliveryAddressId ? ` (${tool.intl.formatMessage({ id: 'shopOrderDeliveryAddressLabel' })}: ${order.address || this.props.userShopDeliveryAddress.list[order.deliveryAddressId].address})` : ''))
                            + (delivery.timetable ? ` (${tool.intl.formatMessage({ id: 'shopOrderDeliveryRequestedAtLabel' })}: ${order.deliveryConfirmedAt ? moment(order.deliveryConfirmedAt).format('YYYY-MM-DD') : '-'})` : '')
                        : '',
                    },

                    right: !delivery ? {
                        title: tool.intl.formatMessage({ id: 'listItemRight[notSelected]' }),
                    } : undefined,
                    disabled: order.status !== 'PENDING',
                    onAction: () => {
                        tool.navigator.pushPage({
                            key: 'JJ_UserDelivery', props: {
                                onSubmit: async props => {
                                    if (props.type === 'DIRECT') {
                                        //快递
                                        await this._onPutShopOrder({
                                            orderId: order.id,
                                            deliveryAddressId: props.deliveryAddressId,
                                            deliveryId: props.deliveryId,
                                            deliveryRequestedAt: moment(props.deliveryRequestedAt, 'YYYY-MM-DD').toISOString(),
                                        })

                                    } else {
                                        await this._onPutShopOrder({
                                            orderId: order.id,
                                            deliveryId: props.deliveryId,
                                            deliveryRequestedAt: moment(props.deliveryRequestedAt, 'YYYY-MM-DD').toISOString(),
                                        })
                                    }
                                    await this._initData()

                                },
                                order,
                            }
                        })
                    }

                },

                ...(delivery
                    && (delivery.type === 'SP_LOCKER' || delivery.type === 'WECOOLER')
                    && (order.status === 'DELIVERED' || order.status === 'COMPLETED')
                    && order.boxes
                    && order.boxes.length
                    ?
                    [
                        {
                            center: { title: tool.intl.formatMessage({ id: 'shopOrderDetails[boxes]' }) },
                            expandableContent: (
                                boxeInfo(order.boxes)
                            ),
                        }
                    ]
                    : []
                ),

                ...((order.status === 'PAID' || order.status === 'DELIVERED' || order.status === 'COMPLETED') && delivery ?
                    [
                        {
                            center: { title: tool.intl.formatMessage({ id: 'shopOrderDetails[logisticsDisplay]' }) },
                            expandableContent: (
                                <JJ_ShopOrderLogisticsDisplay
                                    order={order}
                                    delivery={delivery}
                                />
                            ),
                        }
                    ]
                    : []
                ),

            ]
            return getOrderListView(title, dataSource)
        }
        /**
         * 订单信息
         */
        const orderInfoView = (order: GetShopOrderRes) => {
            const { campaignPackages } = order
            const userCoupon = this.props.userCoupon.list[order.userCouponId || '']

            const dataSource1: ListData[] = [
                {
                    center: {
                        title: tool.intl.formatMessage({ id: 'shopOrderDetailInfo' }),

                    },
                    expandableContent: (
                        <>
                            {
                                campaignPackages.map((item, index) => {
                                    return (
                                        <JJ_ShopOrderCampaignPackage
                                            {...item}
                                            key={index}
                                            style={index !== 0 ? { marginTop: 10 } : {}}
                                            onDeitalAction={(props) => tool.navigator.pushPage({ key: 'JJ_ShopCampaignDetails', props: { id: props.campaignId, moduleId: this.props.moduleId } })}
                                        />

                                    )
                                })
                            }
                            {totalPriceInfo()}
                        </>
                    ),
                },
                {
                    center: {
                        title: tool.intl.formatMessage({ id: 'shopOrderUserRemark' }),
                        details: order.userRemark || `(${tool.intl.formatMessage({ id: 'shopOrderUserRemarkEmpty' })})`,
                    },
                    onAction: () => {
                        tool.navigator.pushPage({
                            key: 'JJ_TextInput',
                            props: {
                                title: tool.intl.formatMessage({ id: 'shopOrderUserRemark' }),
                                type: 'textarea',
                                defaultInputValue: order.userRemark,//输入初始值
                                onData: async (value) => {
                                    await this._onPutShopOrder({
                                        orderId: order.id,
                                        userRemark: value,
                                    });
                                    await this._initData();
                                },//完成回调
                                placeholder: tool.intl.formatMessage({ id: '请输入' }) + tool.intl.formatMessage({ id: 'shopOrderUserRemark' }),//占位
                            }
                        });
                    },
                    disabled: !(order.status === 'PENDING'),
                },

                {
                    right: userCoupon
                        ? {
                            title: '',
                        }
                        : {
                            title: tool.intl.formatMessage({ id: 'listItemRight[notSelected]' }),
                        },
                    center: {
                        title: tool.intl.formatMessage({ id: 'userCoupon[title]' }),
                        details: userCoupon ? userCoupon.coupon.name : undefined,
                        subDetails: userCoupon ? userCoupon.coupon.description : undefined,
                    },
                    /**
                     * PENDING: 待付款
                     * PREPAID: 已預支付(團購適用)
                     */
                    disabled: !(order.status === 'PENDING'),
                    onAction: () => {
                        tool.navigator.pushPage({
                            key: 'JJ_UserCoupon', props: {
                                selectUserCoupon: userCoupon,
                                switchSelect: true,
                                onOk: (userCoupon) => {
                                    if (userCoupon) {
                                        this._onPutShopOrder({ orderId: order.id, userCouponId: userCoupon.id })
                                    } else {
                                        this._onPutShopOrder({ orderId: order.id, userCouponId: null })
                                    }
                                },
                                listProps: {
                                    type: 'postUserCouponUsable',
                                    params: {
                                        areaId: this.props.user.userAreas.HOME[0],
                                        shop: {
                                            campaignPackages: order.campaignPackages.map(item => ({
                                                campaignId: item.campaignId,
                                                campaignPackageId: item.campaignPackageId,
                                                amount: item.amount,
                                            }))
                                        }
                                    },
                                },
                                renderFooter: (<div>
                                    {tool.intl.formatMessage({ id: 'userCoupon[loadMoreEnd]' })}
                                </div>),
                            }
                        })
                    }
                },
                {
                    center: {
                        title: tool.intl.formatMessage({ id: '订单编号' }),

                    },
                    right: {
                        title: order.humanId,
                    }
                },
                ...(this.state.payment
                    ? [{
                        center: {
                            title: tool.intl.formatMessage({ id: 'shopOrderDeatils[orderPaymentTransactionsTitle]' }),
                            details: this.state.payment.name,
                        },
                    }]
                    : []),
                {
                    center: {
                        title: tool.intl.formatMessage({ id: 'shopOrderDetailCreatedAt' }),
                    },
                    right: {
                        title: moment(order.createdAt).format('YYYY-MM-DD HH:mm'),
                    }
                },
            ]

            const dataSource2: ListData[] = [
                //取消订单
                ...(order.status === 'PENDING'
                    ? [
                        {
                            center: {
                                title: tool.intl.formatMessage({ id: 'shopOrderDetailCancel' }),
                            },
                            onAction: () => {
                                tool.dialog.confirm(
                                    tool.intl.formatMessage({ id: '提示' }),
                                    `${tool.intl.formatMessage({ id: 'shopOrderDetailCancel' })}?`,
                                    [
                                        {
                                            text: tool.intl.formatMessage({ id: '确定' }),
                                            onPress: () => {
                                                this._onPostShopOrderCancel()

                                            },
                                            style: 'destructive'

                                        },
                                        {
                                            text: tool.intl.formatMessage({ id: 'buttonCancel' }),

                                            onPress: () => {
                                            },
                                            style: 'cancel'
                                        },
                                    ]
                                )

                            }
                        }
                    ]
                    : []
                ),
                //删除订单
                ...((order.status === 'PENDING' || order.status === 'CANCELED' || order.status === 'EXPIRED')
                    ? [{
                        center: {
                            title: tool.intl.formatMessage({ id: 'shopOrderDetailDeleteOrder' }),
                        },
                        onAction: () => {
                            tool.dialog.confirm(
                                tool.intl.formatMessage({ id: '提示' }),
                                `${tool.intl.formatMessage({ id: 'shopOrderDetailDeleteOrder' })}?`,
                                [
                                    {
                                        text: tool.intl.formatMessage({ id: '确定' }),
                                        onPress: () => {
                                            this._onDeleteShopOrder()
                                        },
                                        style: 'destructive'

                                    },
                                    {
                                        text: tool.intl.formatMessage({ id: 'buttonCancel' }),

                                        onPress: () => {
                                        },
                                        style: 'cancel'
                                    },
                                ]
                            )
                        }
                    }]
                    : []
                ),
            ]

            return [
                getOrderListView(tool.intl.formatMessage({ id: 'shopOrderDetails[orderInfoViewTitle]' }), dataSource1),
                (dataSource2.length ? getOrderListView(tool.intl.formatMessage({ id: 'shopOrderDetails[orderActionViewTitle]' }), dataSource2) : null),
            ]
        }


        const view = (order: GetShopOrderRes, campaign: GetShopCampaignRes) => {
            const { status, expiredAt, boxes, deliveryId, deliveryRecipientId, deliveryAddressId } = order
            const delivery = deliveryId && this.props.shopDelivery.list[deliveryId]
            return (

                <div id='JJ_ShopOrderDetails'
                >
                    <div className='scroll'>
                        <div>
                            {campaign && (
                                <JJ_ShopOrderDetailHeadView
                                    campaign={campaign}
                                    order={order}
                                    callTimeLeftAction={() => this._initData()}
                                />
                            )}
                            {orderDeliveryView(order)}
                            {orderInfoView(order)}

                        </div>
                    </div>
                    {footView(order, campaign)}
                </div>
            )
        }

        const footView = (order: GetShopOrderRes, campaign: GetShopCampaignRes) => {

            /**
             * PENDING: 待付款
             * PREPAID: 已預支付(團購適用)
             * PAID: 已付款, 待發貨
             * DELIVERED: 待取貨
             * EXPIRED: 未付款已過期
             * CANCELED: 已取消
             * COMPLETED: 已取貨
             * RECALLED: 沒取貨, 已過期
             * REFUNDED: 已退款
             */



            switch (order.status) {
                case 'PENDING': {
                    //待付款

                    //全额金额
                    const fullPrice: number = order.price + order.shippingFee - order.additionalFee - order.discount
                    //订金定额
                    const groupPrepaidPrice: number = order.groupPrepaidPrice || 0
                    return (
                        <div className={'footView'}>

                            {groupPrepaidPrice > 0 && (
                                <Ons.Button
                                    className='buttonGroup'
                                    onClick={() => { this._onPayment(order, true, groupPrepaidPrice) }}
                                >
                                    {tool.intl.formatMessage({ id: 'JJ_ShopOrderDetails[buttonGroup]' })}
                                    ({`$${tool.math.initPrice({ price: groupPrepaidPrice }).toFixed(2)}`})
                                </Ons.Button>
                            )}
                            <Ons.Button
                                onClick={() => { this._onPayment(order, false, fullPrice) }}
                                className='buttonNoraml'
                            >
                                {tool.intl.formatMessage({ id: 'JJ_ShopOrderDetails[buttonNoraml]' })}
                                &nbsp;(${tool.math.initPrice({ price: fullPrice }).toFixed(2)})
                            </Ons.Button>
                        </div>
                    )

                }
                case 'PREPAID': {
                    //已預支付(團購適用)
                    const groupPrepaidPrice: number = order.groupPrepaidPrice || 0

                    //全额金额
                    const fullPrice: number = order.price + order.shippingFee - order.additionalFee - order.discount - groupPrepaidPrice


                    if (campaign.status === 'ONGOING') {
                        //進行中
                        //等待成团结束
                        return null

                    }

                    if (campaign.status === 'CONFIRMED') {
                        //已成團
                        //成团成功，等待支付余额

                        return (
                            <div className={'footView'}>
                                <Ons.Button
                                    onClick={() => { this._onPayment(order, false, fullPrice) }}
                                    className='buttonNoraml'
                                >
                                    {tool.intl.formatMessage({ id: 'JJ_ShopOrderDetails[buttonNoraml]' })}
                                    &nbsp;(${tool.math.initPrice({ price: fullPrice }).toFixed(2)})
                                </Ons.Button>
                            </div>
                        )

                    }


                    return null

                }


                default: return null

            }
        }

        const order = this.props.order.list[this.props.orderId]

        const campaign = order && this.props.campaign.list[order.campaignPackages[0].campaignId]

        return (
            <Ons.Page
                key={`Page ${this.state.pageKey}`}
                renderToolbar={() => <JJ_Nav {...nav} />}>

                < JJ_Components.boxPage
                    loadData={this._initData}
                >
                    {order && campaign && view(order, campaign)}
                </JJ_Components.boxPage >
            </Ons.Page >
        );
    }
}



export default connector(injectIntl(JJ_ShopOrderDetails))
