import React from 'react'
class BoxBtn extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isShow:false,
        }
    }

    static defaultProps = {
        animatedId:'bounceIn',
        onClick:()=>{},
    };


    componentDidMount() {
    }


    render() {
        return (
            <div
                className={`${this.state.isShow?this.props.animatedId:''} ${this.props.className}`}
                onClick={()=>{
                    this.props.onClick()
                    this.setState({
                        isShow:false
                    },()=>{
                        setTimeout(()=>this.setState({isShow:true}),50)
                    })
                }}
            >
                {this.props.children}
            </div>
        )
    }
}

export default BoxBtn
