
import { ConfigType, Guide } from './config.type'

//配置開發模式
class ConfigDev implements ConfigType {

    licenses: ConfigType['licenses'] = [
        {
            number: '0392801629',
            description: {
                'en': 'Approval for online sale of prepackaged refrigerated meat',
                'zh-tw': '批准網上售賣預先包裝冷藏肉',
                'zh-cn': '批准网上售卖预先包装冷藏肉',
            },
            address: {
                'en': 'F6, 4/F, The Mills, 45 Pak Tin Par Street, Tsuen Wan, NT',
                'zh-tw': '新界荃灣白田壩街45號南豐紗廠The Mills 4/F F6',
                'zh-cn': '新界荃湾白田坝街45号南丰纱厂The Mills 4/F F6',
            },
        },
	/*{
            number: '0392803270',
            address: {
                'en': 'F6, 4/F, The Mills, 45 Pak Tin Par Street, Tsuen Wan, NT',
                'zh-tw': '新界荃灣白田壩街45號南豐紗廠The Mills 4/F F6',
                'zh-cn': '新界荃湾白田坝街45号南丰纱厂The Mills 4/F F6',
            },
	    },*/
    ]
    groupOrderPaymentDescription: ConfigType['groupOrderPaymentDescription'] = {
        title: {
            'en': 'Group Buying',
            'zh-tw': '團購',
            'zh-cn': '团购',
        },
        details: {
            'en': 'After payment you will need to wait for this campaign to complete. When a campaign did not reach the target on time, full amount of the order will be refuned.',
            'zh-tw': '支付後需等待此團購成團後才會安排送貨。在限期內不成團的訂單將會全數退款。',
            'zh-cn': '支付后需等待此团购成团后才会安排送货。在限期内不成团的订单将会全数退款。',
        }
    }
    contactUs: ConfigType['contactUs'] = {
        title: {
            'en': 'Contact us',
            'zh-tw': '聯繫我們',
            'zh-cn': '联系我们',
        },
        details: {
            'en': 'Sharing Vision Co. Limited\ninfo@sharing.vision\n9084 4990\nF6, 4/F, The Mills, 45 Pak Tin Par St., Tsuen Wan, NT, HK',
            'zh-tw': 'Sharing Vision Co. Limited\ninfo@sharing.vision\n9084 4990\nF6, 4/F, The a Mills, 45 白田壩街, 荃灣, 新界香港',
            'zh-cn': 'Sharing Vision Co. Limited\ninfo@sharing.vision\n9084 4990\nF6, 4/F, The a Mills, 45 白田坝街, 荃湾, 新界香港',
        }
    }
    guides: Guide[] = [
        {
            img: {
            	'en': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/8901a78f02eccde0422210bcbe88c08f.jpg',
            	'zh-tw': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/f814935c0304a0e3e5ff6d0143144d8c.jpg',
            	'zh-cn': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/f814935c0304a0e3e5ff6d0143144d8c.jpg',
            },
            title: {
                'en': 'The vSHAREv website is a community group buying website that serves community residents. Residents can use group grouping to snap up a variety of selected foods at preferential prices.',
                'zh-tw': 'vSHAREv 是服務社區居民的社區團購網站, 居民可以用拼團方式, 以優惠價格搶購各類精選食品。',
                'zh-cn': 'vSHAREv 是服務社區居民的社區團購網站, 居民可以用拼團方式, 以優惠價格搶購各類精選食品。',
            },
            details: {
                'en': 'Residents only need to log in to the vSHAREv website and register as a member to participate in group buying and purchase high-quality food. After the group is formed, we will deliver it to our dedicated refrigerating and holding cabinet, and the customer can pick up the goods within the specified time.',
                'zh-tw': '居民只需登入vSHAREv, 註冊成會員即可以參與團購採購優質食品。成團後我們會配送至我們專用冷凍保溫櫃, 客人便可以在指定的時間內提取貨品。',
                'zh-cn': '居民只需登入vSHAREv, 註冊成會員即可以參與團購採購優質食品。成團後我們會配送至我們專用冷凍保溫櫃, 客人便可以在指定的時間內提取貨品。',
            },
        },
        {
            img: {
            	'en': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/f9512c9ef4465ccf08050c39724745e4.jpg',
            	'zh-tw': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/390ff2b373f277bc9b5d8a3b84ebb622.jpg',
            	'zh-cn': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/390ff2b373f277bc9b5d8a3b84ebb622.jpg',
            },
            title: {
                'en': 'Selected global premium products',
                'zh-tw': '優質',
                'zh-cn': '优质',
            },
            details: {
                'en': 'Hong Kong, the United States, Japan, Australia, Thailand, etc.',
                'zh-tw': '精選全球優質商品, 香港、美國、日本、澳洲、泰國等',
                'zh-cn': '精选全球优质商品, 香港、美国、日本、澳洲、泰国等',
            },
        },
        {
            img: {
            	'en': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/fa18c817b2b4ecc49f39a6e222c3737f.jpg',
            	'zh-tw': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/8ae7af71f6cb610a8b63eaa6a169b60e.jpg',
            	'zh-cn': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/8ae7af71f6cb610a8b63eaa6a169b60e.jpg',
            },
            title: {
                'en': 'Discount',
                'zh-tw': '優惠',
                'zh-cn': '优惠',
            },
            details: {
                'en': 'Group purchase is more favorable',
                'zh-tw': '拼住更優惠',
                'zh-cn': '拼住更优惠',
            },
        },
        {
            img: {
            	'en': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/61607652b5b05f2f9211df370832c315.jpg',
            	'zh-tw': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/e6e1c302b3a19bca53a7809818313dc2.jpg',
            	'zh-cn': 'https://vsharev-cloud-3.s3.ap-east-1.amazonaws.com/admin/e6e1c302b3a19bca53a7809818313dc2.jpg',
            },
            title: {
                'en': 'Convenience',
                'zh-tw': '方便',
                'zh-cn': '方便',
            },
            details: {
                'en': 'Deliver to the community intelligent refrigerated LOCKER, more convenient for receiving',
                'zh-tw': '送貨到小區智能冷藏LOCKER, 收貨更方便',
                'zh-cn': '送货到小区智能冷藏LOCKER, 收货更方便',
            },
        },
    ]

    areaId = ""
    api = 'https://api.app.vsharev.com'
    type: "dev" | "prod" = 'dev'

    payments = {
        stripe: {
            apiKey: 'pk_live_O9hKj87mgupwp5mYUQzUc2aU'
        }
    }
    logoUrl = 'https://public.app.vsharev.com/images/logo.png'

    //首次登入首頁時，觸發提示
    servicePrompt = {
        title: {
            'en': 'Welcome to vSHAREv',
            'zh-tw': '歡迎使用 vSHAREv',
            'zh-cn': '欢迎使用 vSHAREv',
        },
        img: {
            'en': 'https://public.app.vsharev.com/images/guardian-app-card.jpg',
            'zh-cn': 'https://public.app.vsharev.com/images/guardian-app-card.jpg',
            'zh-tw': 'https://public.app.vsharev.com/images/guardian-app-card.jpg',
        },
        details: {
            'en': 'Hello everybody, in order to faciliate all of you to buy high-quality ingredients and food online, we cooperate with the property management company to promote the \"vSHAREv\" Online Shop for Web and mobile App.\nNow our service is in the trial stage, please feel free to comment and talk to us. We are happy to listen and improve to provide better services and arrangements for your community.',
            'zh-tw': '各位朋友，為方便大家網購到優質食品食材，我們與物業管理公司合作，推此”vSHAREv”網頁及手機應用程式APP讓各位採購心儀食品。現在服務正處於試行階段，請積極提出意見，我們樂意聆聽致力改善，為你的社區提供更完善的服務和安排。',
            'zh-cn': '各位朋友，为方便大家网购到优质食品食材，我们与物业管理公司合作，推此”vSHAREv”网页及手机应用程式APP让各位采购心仪食品。现在服务正处于试行阶段，请积极提出意见，我们乐意聆听致力改善，为你的社区提供更完善的服务和安排。',
        }
	}

	redirectStandaloneUrl = 'https://www.welink-app.com/'
}

const config = new ConfigDev();
(window as any)['getConfig'] = () => config
export default config
