import React, { CSSProperties } from 'react';
import { ActivityIndicator } from 'antd-mobile'
import *as Ons from 'react-onsenui'
import { connect, ConnectedProps } from 'react-redux';
import *as ActionCampaign from "../../../actions/ActionCampaign";
import JJ_Components from '../../../components/JJ_Components'
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { CampaignPackage, GetShopCampaignParameters, ShopCampaignPackage } from '../../../api';
import tool from '../../../tool';



interface PageState {
    pageType: '正常' | '加载' | '异常'
}

export interface Props {
    style?: CSSProperties
    onDeitalAction?(props: PageProps): void
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux & CampaignPackage

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    campaign: ActionCampaign.ActionCampaignRes,
}


const mapState = (state: RootState) => ({
    campaign: state.campaign,

})

const mapDispatch = {
    getShopCampaign: ActionCampaign.getShopCampaign
}


export const connector = connect(
    mapState,
    mapDispatch
)


class JJ_ShopOrderCampaignPackage extends React.Component<PageProps, PageState> {



    componentDidMount() {
        this._initData()
    }

    componentWillUnmount() {
        this.setState = () => { }
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
            pageType: '正常',
        }
    }



    /**
     * 初始化
     */
    _initData = async () => {
        const campaign = this.props.campaign.list[this.props.campaignId]
        try {
            !campaign && this.setState({ pageType: '加载', })
            await this._getShopCampaign({ campaignId: this.props.campaignId, queryCampaignPackage: true })
            this.setState({ pageType: '正常' })
        } catch (error) {
            console.error(error)
            !campaign && this.setState({ pageType: '异常' })

        }
    }

    _getShopCampaign = async (params: GetShopCampaignParameters) => {
        const res = await this.props.getShopCampaign(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break
            }
            throw new Error(res.kind)
        }
    }

    _initPackage = () => {
        const campaign = this.props.campaign.list[this.props.campaignId]||{}
        const campaignPackages = campaign.campaignPackages
        if (campaignPackages) {
            const campaignPackage = campaignPackages.find(campaignPackage => campaignPackage.id === this.props.campaignPackageId)
            if (campaignPackage) {
                return campaignPackage
            }
        }
    }


    _headImg = (shopCampaignPackage: ShopCampaignPackage) => {
        const { thumbnails = [] } = shopCampaignPackage
        const [thumbnail] = thumbnails
        return (
            <JJ_Components.baseImage
                src={thumbnail.url}
                className='img'
            />
        )
    }


    _priceView = (shopCampaignPackage: ShopCampaignPackage) => {
        const { price, priceOriginal } = shopCampaignPackage
        const amount = this.props.amount
        return (
            <div className='priceView'>
                <span className='price'><span
                    style={{ fontSize: 15 }}>$</span>{tool.math.initPrice({ price: price * amount }).toFixed(2)}</span>
                <span className='priceOriginal'>${tool.math.initPrice({ price: priceOriginal * amount }).toFixed(2)}</span>
            </div>
        )
    }


    _view = (shopCampaignPackage: ShopCampaignPackage) => {
        const amount = this.props.amount
        const { name } = shopCampaignPackage

        return (

            <div
                style={this.props.style}
                id='JJ_ShopOrderCampaignPackage'
                onClick={(e) => {
                    if (this.props.onDeitalAction) {
                        e.stopPropagation()
                        this.props.onDeitalAction(this.props)
                    }
                }}
            >

                {this._headImg(shopCampaignPackage)}
                <div className='rightView'>
                    <span className='name'>{name}</span>
                    <div className='bottomView'>
                        {this._priceView(shopCampaignPackage)}
                        <span className='amount'>x{amount}</span>
                    </div>
                </div>
            </div>
        );
    }


    _loadingView = ({ type = '' }) => {
        return (
            <div
                style={this.props.style}
                id='loadingView'
            >
                {type === '加载'
                    ? <ActivityIndicator />
                    : <Ons.Button
                        onClick={() => this._initData()}
                    >
                        {tool.intl.formatMessage({ id: '重新加载' })}
                    </Ons.Button>
                }
            </div>
        )
    }

    render() {
        const shopCampaignPackage = this._initPackage()
        switch (this.state.pageType) {
            case '正常': {

                return shopCampaignPackage
                    ? this._view(shopCampaignPackage)
                    : this._loadingView({ type: this.state.pageType })
            }
            case '加载':
            case '异常': {
                return this._loadingView({ type: this.state.pageType })
            }
        }

    }
}



export default connector(injectIntl(JJ_ShopOrderCampaignPackage))


