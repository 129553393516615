import React from 'react'
import queryString from 'query-string';
import *as ActionUser from '../../actions/ActionUser'
import *as ActionArea from '../../actions/ActionArea'
import *as Ons from 'react-onsenui'
import tool from '../../tool';
import { injectIntl, InjectedIntlProps } from "react-intl";
import { ConnectedProps, connect } from 'react-redux';
import api, { PostAuthSessionRequestNewParameters, PostAuthSessionRequestNewRes, GetAreaModuleListParameters, GetAreaModuleParameters, GetAreaModuleRes } from '../../api';
import JJ_Components from '../../components/JJ_Components'
import config from '../../config/config';

interface PageState {

}


interface Props {
    dispatch: any
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>



interface Page {

}


interface RootState {
    user: any
}

const mapState = (state: RootState) => ({
    userData: state.user,

})

const mapDispatch = {
    authOut: ActionUser.authOut,
    getAreaModule: ActionArea.getAreaModule,
    getAreaModuleList: ActionArea.getAreaModuleList,
    getUserData: ActionUser.getUser,
    getUserSettingData: ActionUser.getUserSettingData,
    getAreaList: ActionArea.getAreaList,
}

export const connector = connect(
    mapState,
    mapDispatch
)

class JJ_Loading extends React.Component<PageProps, PageState> implements Page {
    constructor(props: PageProps) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        tool.intl.getIntl(this.props.intl)
    }


    _getUserData = async () => {
        const res = await this.props.getUserData()
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break
                case 'NOT_FOUND': {

                }
                    break
            }
            throw res.kind
        }
    }
    _getAreaModuleList = async (params: GetAreaModuleListParameters) => {
        const res = await this.props.getAreaModuleList(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break
            }
            throw res.kind
        }
    }
    _getAreaModule = async (params: GetAreaModuleParameters) => {
        const res = await this.props.getAreaModule(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break
            }
            throw res.kind
        }
    }
    _getUserSettingData = async () => {
        const res = await this.props.getUserSettingData()
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break
            }
            throw res.kind
        }
    }



    /**
     * 初始化数据
     */
    _initData = async () => {

        const loadShopCampaign = async (areaId: string, areaModule: GetAreaModuleRes) => {
            try {
                const { attrs }: any = areaModule ? areaModule : {}
                tool.navigator.resetPage({
                    key: 'JJ_ShopCampaign',
                    props: {
                        tags: attrs.CAMPAIGN_TAGS,
                        moduleId: areaModule.id,
                    },
                }, { animation: 'node' })

                // check orderId
                const urlParams = queryString.parse(location.search);

                if (urlParams.orderId) {
                    tool.navigator.pushPage({
                        key: 'JJ_ShopOrderDetails',
                        props: {
                            orderId: urlParams.orderId.toString(),
                            moduleId: areaModule.id,
                        }
                    }, { animation: 'node' })
                }

            } catch (error) {
                throw error
            }

        }

        return (async () => {
            // check one-time auth
            const urlParams = queryString.parse(location.search);

            if (urlParams.authToken) {
                try {
                    const authData = await api.postAuthOneTimeVerify({
                        token: urlParams.authToken.toString(),
                    });

                    const userData = await this._getUserData()
                } catch (error) {
                    console.log(error)
                }
            } else if (config.redirectStandaloneUrl) {
                window.location.href = config.redirectStandaloneUrl;
            }

            let areaId: any = urlParams.areaId;
            const moduleId: any = urlParams.moduleId;

            let areaModuleWeshop: any = null;

            try {
                const userData = await this._getUserData()
                await this._getUserSettingData()

                if (!areaId || !moduleId) {
                    areaId = userData.userAreas.HOME[0];

                    const getAreaModuleListRes = await this._getAreaModuleList({ areaId, type: 'SHOP' });
                    const areaModuleData = getAreaModuleListRes.data;

                    areaModuleWeshop = areaModuleData.find(areaModule => areaModule.isEnabled && (areaModule.name === 'WESHOP' || areaModule.name === 'GROUP_BUY'));
                } else if (moduleId) {
                    areaModuleWeshop = await this._getAreaModule({ areaModuleId: moduleId });
                }

                if (!areaModuleWeshop) {
                    tool.navigator.resetPage({ key: 'JJ_AreaModuleDisabled' }, { animation: 'node' });
                    return
                }

                if (areaId && areaModuleWeshop) {
                    await loadShopCampaign(areaId, areaModuleWeshop)
                    return

                } else {
                    await this.props.authOut()
                    return
                }

            } catch (error) {
                console.log(error)
                if (error !== 'NOT_LOGGED_IN') {
                    await this.props.authOut()
                }
            }
        })()
    }

    render() {
        return (
            <Ons.Page>

                <JJ_Components.boxPage
                    loadData={this._initData}
                />
            </Ons.Page>
        )
    }
}




export default connector(injectIntl(JJ_Loading))
