import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { PickerView, Button } from 'antd-mobile';
import JJ_Nav from '../SuperComponent/JJ_Nav'
import *as ActionArea from '../../actions/ActionArea'
import *as ActionUser from '../../actions/ActionUser'
import *as Ons from 'react-onsenui'
import { InjectedIntlProps, injectIntl } from 'react-intl';
import tool from '../../tool';
import api, { GetAreaListParameters, PostAuthSessionRequestNewParameters } from '../../api';
import BoxPage from '../../components/Box/BoxPage';
import { GetStorageRes } from '../../tool/ToolStorage';


interface PageState {
    value: string[],

}


export interface Props {
    localStorageAction?: GetStorageRes
    action: {
        type: 'postAuthSessionRequestNew'
    } | {
        type: 'postAuthSmsRequestNew'
    }
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface Page {

}


interface RootState {
    user: ActionUser.ActionUserRes
    area: ActionArea.ActionAreaRes
}

const mapState = (state: RootState) => ({
    areaData: state.area,
    userData: state.user,

})

const mapDispatch = {
    getAreaList: ActionArea.getAreaList,
}




export const connector = connect(
    mapState,
    mapDispatch
)



class JJ_LoginArea extends React.Component<PageProps, PageState> implements Page {

    constructor(props: PageProps) {
        super(props);
        this.state = {
            value: [],
        }
    }

    static defaultProps = {};


    componentDidMount() {

    }

    _initAreaData = () => {
        const { listData = [] } = this.props.areaData
        return {
            listData
        }
    }


    _getAreaList = async (params: GetAreaListParameters) => {
        const res = await this.props.getAreaList(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
        }
        throw new Error(res.kind)
    }

    _postAuthSessionRequestNew = async (params: PostAuthSessionRequestNewParameters) => {
        const res = await api.postAuthSessionRequestNew(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'ALREADY_LOGGED_IN': {

            }
                break
            case 'AREA_NOT_FOUND': {

            }
                break
            case 'GUEST_NOT_ALLOWED': {

            }
                break
            case 'INVALID_PARAMS': {

            }
                break
            case 'SECTION_NOT_FOUND': {

            }
                break
        }
        throw new Error(res.kind)
    }

    /**
     * 获取数据
     */
    _getData = async () => {

        try {

            const getAreaListRes = await this._getAreaList({ lang: this.props.userData.lang })
            const [data] = getAreaListRes.data
            this.setState({ value: [data.id] })

        } catch (error) {
            console.log(error)

        }

    }


    /**
     * 授权
     */
    _auth = async (areaId: string) => {
        try {
            tool.message.loading({})
            if (this.props.action.type === 'postAuthSessionRequestNew') {
                await this._postAuthSessionRequestNew({
                    areaId,
                    lang: this.props.userData.lang,
                })
                tool.navigator.resetPage({ key: 'JJ_Loading' }, { animation: 'node' })
            }
            if (this.props.action.type === 'postAuthSmsRequestNew') {
                tool.navigator.pushPage({
                    key: 'JJ_RegisteredMemberSms',
                    props: {
                        areaId,
                        localStorageAction: this.props.localStorageAction,
                    }
                })
            }

            tool.message.hide()
        } catch (error) {
            console.log(error)
            tool.message.hide()
        }
    }

    _contentView = () => {

        return (
            <div className='contentView'>
                <h1>{tool.intl.formatMessage({ id: '请选择区域登录' })}</h1>
                <div>
                    <PickerView
                        onChange={value => this.setState({ value })}
                        value={this.state.value}
                        data={this.props.areaData.listData.map(item => {
                            return {
                                value: item.id,
                                label: item.name
                            }
                        })}
                        cascade={false}
                        itemStyle={{ fontSize: 20, fontWeight: '500' }}
                    />
                    <div className='btnBox'>

                        <Ons.Button
                            onClick={() => this._auth(this.state.value[0])}
                            modifier='large'
                            className='btn'
                            style={{ opacity: 0.7 }}
                        >
                            {tool.intl.formatMessage({ id: 'loginAreaButton' })}
                        </Ons.Button>

                    </div>
                </div>
            </div>
        )
    }
    render() {
        const nav = {
            showLeftIcon: true,
            showRightIcon: false,
            ...this.props
        }

        return (

            <Ons.Page
                renderToolbar={() => <JJ_Nav {...nav} />}
            >
                <BoxPage

                    loadData={this._getData}
                >
                    <div
                        id='JJ_LoginArea'
                    >
                        {this._contentView()}
                    </div>
                </BoxPage >
            </Ons.Page >
        )
    }

}



export default connector(injectIntl(JJ_LoginArea))
