import ActionType from './ActionType'
import tool from '../tool'
import { MyThunkDispatch } from './action-interface'
import api, { GetPaymentRes } from '../api'
import *as ModalPayment from '../components/Modal/ModalPayment'
import *as ModalUserCoupon from '../components/Modal/ModalUserCoupon'
const initDataAction = () => {
    return {
        type: ActionType.modalType.INIT_DATA,
    }
}

const upDateAction = (data: ActionModalRes) => {
    return {
        type: ActionType.modalType.UP_DATA,
        ...data
    }
}


export interface ActionModalRes {
    payment: ModalPayment.Props
    userCoupon: ModalUserCoupon.Props
}

/**
 * 显示支付模块
 */
export const showPaymentModal = (
    amount: number
) => {
    let modal: ActionModalRes = tool.store.getState().modal
    return async (dispatch: MyThunkDispatch): Promise<GetPaymentRes | undefined> => {
        return await new Promise((resolve: (value?: GetPaymentRes) => void, reject: (reason?: any) => void) => {
            let modal: ActionModalRes = tool.store.getState().modal
            modal.payment.show = true
            modal.payment.amount = amount
            modal.payment.onClose = () => {

                dispatch(hidePaymentModal())
                resolve()
            }
            modal.payment.onPay = (payment) => {

                dispatch(hidePaymentModal())
                resolve(payment)

            }
            dispatch(upDateAction(modal))
        })
    }
}

/**
 * 隐藏支付模块
 */
export const hidePaymentModal = () => {
    return async (dispatch: MyThunkDispatch) => {
        let modal: ActionModalRes = tool.store.getState().modal
        modal.payment.show = false
        dispatch(upDateAction(modal))
    }
}

/**
 * 显示最新优惠券模块
 */
export const showUserCouponFeedModal = () => {
    let modal: ActionModalRes = tool.store.getState().modal
    return async (dispatch: MyThunkDispatch) => {
        return await new Promise(async (resolve: (value?: undefined) => void, reject: (reason?: any) => void) => {

            const res = await api.postUserCouponFeed()
            if (res.kind === 'ok') {
                const userCoupons = res.data.data
                
                if (userCoupons.length > 0) {
                    let modal: ActionModalRes = tool.store.getState().modal
                    modal.userCoupon.show = true
                    modal.userCoupon.userCoupons = userCoupons
                    modal.userCoupon.onClose = () => {
                        dispatch(hideUserCouponFeedModal())
                        resolve()
                    }
                    modal.userCoupon.onClick = () => {
                        dispatch(hideUserCouponFeedModal())
                        resolve()
                    }
                    dispatch(upDateAction(modal))
                }


            }
            switch (res.kind) {
                case 'SHOP_ORDER_NOT_FOUND': {

                }
            }
            reject(res.kind)
        })
    }
}

/**
 * 隐藏最新优惠券模块
 */
export const hideUserCouponFeedModal = () => {
    return async (dispatch: MyThunkDispatch) => {
        let modal: ActionModalRes = tool.store.getState().modal
        modal.userCoupon.show = false
        dispatch(upDateAction(modal))
    }
}

export const initData = () => {
    return (dispatch: MyThunkDispatch) => dispatch(initDataAction())
}





