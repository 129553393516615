import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import JJ_Components from '../../../components/JJ_Components'
import *as ActionUserShopDeliveryAddress from '../../../actions/ActionUserShopDeliveryAddress'
import { injectIntl, InjectedIntlProps } from "react-intl";
import JJ_UserDeliveryAddressListItem from './JJ_UserDeliveryAddressListItem'
import *as Ons from 'react-onsenui'

import { FaPlus } from 'react-icons/fa';
import api, { GetUserShopDeliveryAddressListParameters, GetUserShopDeliveryAddressParameters, GetUserShopDeliveryAddressListRes, GetUserShopDeliveryAddressRes, PutUserShopDeliveryAddressParameters, PostUserShopDeliveryAddressParameters, DeleteUserShopDeliveryAddressParameters } from '../../../api';
import tool from '../../../tool';



interface PageState {
    selectId?: string
    userShopDeliveryAddressList: string[]
}

export interface Props {
    selectId?: string
    onSelect(id: string, address: string): void
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    userShopDeliveryAddress: ActionUserShopDeliveryAddress.ActionUserShopDeliveryAddressRes,
}

const mapState = (state: RootState) => ({
    userShopDeliveryAddress: state.userShopDeliveryAddress,
})

const mapDispatch = {
    getUserShopDeliveryAddressList: ActionUserShopDeliveryAddress.getUserShopDeliveryAddressList,
    getUserShopDeliveryAddress: ActionUserShopDeliveryAddress.getUserShopDeliveryAddress,
}


export const connector = connect(
    mapState,
    mapDispatch
)



class JJ_UserDeliveryAddressList extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {
            selectId: props.selectId,
            userShopDeliveryAddressList: [],
        }
    }




    /**
     * 初始化
     */
    _initData = async () => {
        try {
            const getUserShopDeliveryAddressListRes = await this._getUserShopDeliveryAddressList({ count: 1000 });
            const addressDefault = getUserShopDeliveryAddressListRes.data.find(data => data.isDefault);
            this.setState({
                userShopDeliveryAddressList: getUserShopDeliveryAddressListRes.data.map(data => data.id),
                selectId: this.state.selectId ? this.state.selectId : (addressDefault && addressDefault.id),
            })
        } catch (error) {
            console.error(error)
        }
    }



    _getUserShopDeliveryAddressList = async (params: GetUserShopDeliveryAddressListParameters) => {
        const res = await this.props.getUserShopDeliveryAddressList(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }

    _getUserShopDeliveryAddress = async (params: GetUserShopDeliveryAddressParameters) => {
        const res = await this.props.getUserShopDeliveryAddress(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }


    _putUserShopDeliveryAddress = async (params: PutUserShopDeliveryAddressParameters) => {
        const res = await api.putUserShopDeliveryAddress(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }

    _postUserShopDeliveryAddress = async (params: PostUserShopDeliveryAddressParameters) => {
        const res = await api.postUserShopDeliveryAddress(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }


    _deleteUserShopDeliveryAddress = async (params: DeleteUserShopDeliveryAddressParameters) => {
        const res = await api.deleteUserShopDeliveryAddress(params)
        if (res.kind === 'ok') {
            return res.data
        }

        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }


    _onPutUserShopDeliveryAddress = async (params: PutUserShopDeliveryAddressParameters) => {
        try {
            tool.message.loading({})
            await this._putUserShopDeliveryAddress(params)
            await this._getUserShopDeliveryAddress({ deliveryAddressId: params.deliveryAddressId })
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: '编辑成功' }) })
        } catch (error) {
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: '编辑失败' }) })
            console.error(error)
        }
    }


    _onPostUserShopDeliveryAddress = async (params: PostUserShopDeliveryAddressParameters) => {
        try {
            tool.message.loading({})
            const postRes = await this._postUserShopDeliveryAddress(params)
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: '添加成功' }) })

        } catch (error) {
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: '添加失败' }) })
            console.error(error)
        }
    }


    _onDeleteUserShopDeliveryAddress = async (params: DeleteUserShopDeliveryAddressParameters) => {
        try {
            tool.message.loading({})
            await this._deleteUserShopDeliveryAddress(params)
            await this._getUserShopDeliveryAddress({ deliveryAddressId: params.deliveryAddressId })
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: 'userDeliveryDeleteSuccess' }) })
        } catch (error) {
            tool.message.hide()
            tool.message.toast({ content: tool.intl.formatMessage({ id: 'userDeliveryDeleteFailed' }) })
            console.error(error)
        }
    }


    _renderRow = (id: string) => {
        switch (id) {
            case 'add': {
                return (
                    <Ons.Button
                        id="JJ_UserDeliveryAddressListItem"
                        className={'add'}
                        key={id}
                        onClick={() => {
                            tool.navigator.pushPage({
                                key: 'JJ_UserDeliveryAddressDetails',
                                props: {
                                    type: 'add',
                                    address: '',
                                    isDefault: false,
                                    onEdit: (props) => { console.log('onEdit', props) },
                                    onDelete: (props) => { console.log('onDelete', props) },
                                    onAdd: async (props) => {
                                        await this._onPostUserShopDeliveryAddress({
                                            address: props.address,
                                            userId: 'me',
                                            isDefault: props.isDefault,
                                        })
                                        await this._initData()
                                    }
                                }
                            })

                            console.log('pushPage')
                        }}
                    >
                        <FaPlus />
                        <span>{tool.intl.formatMessage({ id: 'userDeliveryAddressButtonAdd' })}</span>
                    </Ons.Button>
                )
            }
            default: {


                const userDeliveryAddress = this.props.userShopDeliveryAddress.list[id]

                return (
                    <JJ_UserDeliveryAddressListItem
                        key={id}
                        onSelect={(selectId, selectAddress) => {
                            this.setState({ selectId })
                            this.props.onSelect(selectId, selectAddress)
                        }}
                        userDeliveryAddress={userDeliveryAddress}
                        onPress={() => {

                            tool.navigator.pushPage({
                                key: 'JJ_UserDeliveryAddressDetails',
                                props: {
                                    type: 'edit',
                                    address: userDeliveryAddress.address,
                                    isDefault: userDeliveryAddress.isDefault,
                                    onEdit: async (props) => {
                                        await this._onPutUserShopDeliveryAddress({
                                            deliveryAddressId: userDeliveryAddress.id,
                                            address: props.address,
                                            isDefault: props.isDefault,
                                        })
                                        await this._initData()
                                    },
                                    onAdd: (props) => { console.log('onAdd', props) },
                                    onDelete: async (props) => {
                                        await this._onDeleteUserShopDeliveryAddress({ deliveryAddressId: userDeliveryAddress.id })
                                        await this._initData()
                                    },
                                }
                            })
                        }}
                        checked={userDeliveryAddress.id === this.state.selectId}
                    />
                )
            }
        }
    }

    _view = () => {
        const addressList = this.state.userShopDeliveryAddressList
        const dataSource = [
            ...addressList,
            'add',
        ]
        return (
            <Ons.List
                id='JJ_UserDeliveryAddressList'
                dataSource={dataSource}
                renderRow={this._renderRow}
            />
        )
    }

    render() {

        return (
            <JJ_Components.boxPage
                loadData={this._initData}
            >
                {this._view()}
            </JJ_Components.boxPage>

        )
    }
}



export default connector(injectIntl(JJ_UserDeliveryAddressList))
