import ActionType from './ActionType'
import tool from '../tool'
import { MyThunkDispatch } from './action-interface'
import api, { GetAreaModuleListParameters, GetAreaModuleListResult, GetAreaModuleResult, GetAreaModuleParameters, GetAreaListParameters, GetAreaModuleListRes, GetAreaListRes, GetAreaKioskParameters, GetAreaKioskRes, GetAreaRes, GetAreaModuleRes } from '../api'

const initDataAction = () => {
    return {
        type: ActionType.areaType.INIT_DATA,
    }
}

const upDateAction = (data: { listData: any }) => {
    return {
        type: ActionType.areaType.UP_DATE,
        ...data
    }
}
const upDateModuleAction = (data: ActionAreaModuleRes) => {
    return {
        type: ActionType.areaType.UP_DATE,
        ...data
    }
}


const upKioskDataAction = (data: { kioskData: any }) => {
    return {
        type: ActionType.areaType.UP_KIOSK_DATA, ...data
    }
}

interface ActionAreaModuleListRes {
    [id: string]: GetAreaModuleRes
}
export interface ActionAreaModuleRes {
    list: ActionAreaModuleListRes
}

export interface ActionAreaRes {
    listData: GetAreaRes[],
    kioskData: {
        [x: string]: GetAreaKioskRes,
    },
}

/**
 * 获取area列表
 */
export const getAreaList = (params: GetAreaListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getAreaList({ type: 'COMMUNITY', count: 10000, ...params })
        if (res.kind === 'ok') {
            dispatch(upDateAction({ listData: res.data.data }))
        }
        return res
    }
}

/**
 * 获取功能模块
 */
export const getAreaModule = (params: GetAreaModuleParameters) => {
    return async (dispatch: MyThunkDispatch): Promise<GetAreaModuleResult> => {
        const res = await api.getAreaModule(params)
        if (res.kind === 'ok') {
            const _module: ActionAreaModuleRes = tool.store.getState().areaModule
            _module.list[res.data.id] = res.data
            dispatch(upDateModuleAction(_module))
        }
        return res
    }
}

/**
 * 获取功能模块
 */
export const getAreaModuleList = (params: GetAreaModuleListParameters) => {
    return async (dispatch: MyThunkDispatch): Promise<GetAreaModuleListResult> => {
        const res = await api.getAreaModuleList(params)
        if (res.kind === 'ok') {
            const _module: ActionAreaModuleRes = tool.store.getState().areaModule
            res.data.data.forEach(item => {
                _module.list[item.id] = item
            })
            dispatch(upDateModuleAction(_module))
        }
        return res
    }
}

/**
 * 获取自提柜数据
 */
export const getKioskData = (params: GetAreaKioskParameters) => {
    const { kioskData }: ActionAreaRes = tool.store.getState().area
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getAreaKiosk(params)
        if (res.kind === 'ok') {
            kioskData[params.areaKioskId] = res.data
            dispatch(upKioskDataAction({ kioskData }))
        }
        return res
    }
}

export const initData = () => {
    return (dispatch: MyThunkDispatch) => dispatch(initDataAction())
}





