import *as JJ_Request from '../JJ_Request'




/*=======================================商城活动=======================================*/


/**
 * 获取商城活动
 * @returns {*|Promise<any>}
 */
export const getShopCampaign = ({
                                    from = 0,
                                    count = 10,
                                    clientTime = new Date().toISOString(),
                                    queryCampaignPackage = true,
                                    ...body,

                                }) => {
    const code = JJ_Request.code.shop_campaign
    const newBody = {
        from,
        count,
        clientTime,
        queryCampaignPackage,
        ...body,
        status:'ONGOING',
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}


/**
 * 获取某个商城活动
 * @returns {*|Promise<any>}
 */
export const getAnShopCampaign = ({
                                      campaignId = '',
                                      queryCampaignPackage = true,
                                      ...body,
                                  }) => {
    const code = JJ_Request.code.shop_campaign + `/${campaignId}`
    const newBody = {
        clientTime: new Date().toISOString(),
        queryCampaignPackage,
        ...body
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}



/*=======================================商城购物车=======================================*/


/**
 * 获取商城购物车
 * @returns {*|Promise<any>}
 */
export const getShopCartCampaignPackage = ({
                                               from = 0,
                                               count = 10,
                                               ...body
                                           }) => {
    const code = JJ_Request.code.shop_cart_campaign_package
    const newBody = {
        from,
        count,
        ...body,
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}


/**
 * 获取某个商城购物车
 * @returns {*|Promise<any>}
 */
export const getAnShopCartCampaignPakcage =
    ({
         id = '',
         ...body,
     }) => {
        const code = JJ_Request.code.shop_cart_campaign_package + `/${id}`
        const newBody = {
            ...body
        }
        return JJ_Request.all([JJ_Request.get(code, newBody)])
            .then(ary => ary[0].data)
    }


/**
 * 添加商城购物车
 * @returns {*|Promise<any>}
 */
export const addShopCartCampaignPakcage = ({
                                               campaignId = '',
                                               campaignPackageId = '',
                                               amount = 0,
                                               ...body,
                                           }) => {
    const code = JJ_Request.code.shop_cart_campaign_package
    const newBody = {
        campaignId,
        campaignPackageId,
        amount,
        ...body
    }
    return JJ_Request.all([JJ_Request.post(code, newBody)])
        .then(ary => ary[0].data)
}

/**
 * 修改商城购物车
 * @returns {*|Promise<any>}
 */
export const putShopCartCampaignPakcage = ({
                                               id = '',
                                               amount = 0,
                                               ...body,
                                           }) => {
    const code = JJ_Request.code.shop_cart_campaign_package + `/${id}`
    const newBody = {
        amount,
        ...body
    }
    return JJ_Request.all([JJ_Request.put(code, newBody)])
        .then(ary => ary[0].data)
}


/**
 * 删除商城购物车
 * @returns {*|Promise<any>}
 */
export const deleteShopCartCampaignPakcage = ({id = ''}) => {
    const code = JJ_Request.code.shop_cart_campaign_package + `/${id}`
    return JJ_Request.all([JJ_Request.deleteMy(code)])
        .then(ary => ary[0].data)
}


/*=======================================商城订单=======================================*/


/**
 * 获取商城订单
 */
export const getShopOrder =
    ({
         from = 0,
         count = 10,
         status = 'PENDING',//  PENDING: 待付款,PAID: 已付款, 待發貨,DELIVERED: 待取貨,COMPLETED: 已取貨/全部:''
         ...body,
     }) => {
        const code = JJ_Request.code.shop_order
        const newBody = {
            from,
            count,
            status,
            ...body,
        }
        return JJ_Request.all([JJ_Request.get(code, newBody)])
            .then(ary => ary[0].data)
    }


/**
 * 获取某个商城订单
 * @returns {*|Promise<any>}
 */
export const getAnShopOrder =
    ({
         orderId = '',
         ...body,
     }) => {
        const code = JJ_Request.code.shop_order + `/${orderId}`
        const newBody = {
            ...body
        }
        return JJ_Request.all([JJ_Request.get(code, newBody)])
            .then(ary => ary[0].data)
    }

/**
 * 获取某个商城订单
 * @returns {*|Promise<any>}
 */
export const getShopOrderByHumanId =
    ({
         orderHumanId = '',
         ...body,
     }) => {
        const code = JJ_Request.code.shop_order_by_human_id + `/${orderHumanId}`
        const newBody = {
            ...body
        }
        return JJ_Request.all([JJ_Request.get(code, newBody)])
            .then(ary => ary[0].data)
    }

/**
 * 添加商城订单
 * @returns {*|Promise<any>}
 */
export const addShopOrder =
    ({
         campaignPackages = [
             {
                 campaignId: '',
                 campaignPackageId: '',
                 amount: 0,
             },
         ],
         recipientName  = '',
         ...body,
     }) => {
        const code = JJ_Request.code.shop_order
        const newBody = {
            campaignPackages,
            recipientName ,
            ...body,
        }
        return JJ_Request.all([JJ_Request.post(code, newBody)])
            .then(ary => ary[0].data)
    }

/**
 * 添加商城购物车订单
 */
export const addShopOrderFromCart = ({
                                         cartCampaignPackageIds = [],
                                         recipientName = '',
                                         ...body
                                     }) => {
    const code = JJ_Request.code.shop_order_from_cart
    const newBody = {
        cartCampaignPackageIds,
        recipientName,
        ...body
    }
    return JJ_Request.all([JJ_Request.post(code, newBody)])
        .then(ary => ary[0].data)
}


/**
 * 商城订单取消
 */
export const cancelShopOrder = ({
                                    orderId = '',
                                    ...body,
                                }) => {
    const code = JJ_Request.code.shop_order_cancel
    const newBody = {
        orderId,
        ...body
    }
    return JJ_Request.all([JJ_Request.post(code, newBody)])
        .then(ary => ary[0].data)
}


/**
 * 修改商城订单PENDING/PAID 才可以修改
 */
export const putShopOrder = ({
                                 orderId = ''
                                 , ...body,
                             }) => {
    const code = JJ_Request.code.shop_order + `/${orderId}`
    const newBody = {
        ...body
    }
    return JJ_Request.all([JJ_Request.put(code, newBody)])
        .then(ary => ary[0].data)
}


/**
 * 删除商城订单 普通會員只可以刪除 status=PENDING, CANCELED
 */
export const deleteShopOrder = (orderId = '') => {
    const code = JJ_Request.code.shop_order + `/${orderId}`
    return JJ_Request.all([JJ_Request.deleteMy(code)])
        .then(ary => ary[0].data)
}


/**
 * 添加商城购物车订单
 */
export const addShopOrderPayment = ({
                                        orderId = '',
                                        paymentId = '', //目前支援的值:stripe
                                        stripeInfo = {
                                            sourceId: '',
                                        },
                                        ...body,
                                    }) => {
    const code = JJ_Request.code.shop_order_payment
    const newBody = {
        orderId,
        paymentId,
        stripeInfo,
        ...body
    }
    return JJ_Request.all([JJ_Request.post(code, newBody)])
        .then(ary => ary[0].data)
}

/*=======================================商城活動標簽=======================================*/
/**
 * 获取商城活动
 */
export const getShopCampaignTag =({
    id = '',
    ...body
})=> {
    const code = JJ_Request.code.shop_campaign_tag + `/${id}`
    const newBody = {
        id,
        ...body,
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}






