import Type from './ActionType';
import api, { GetUserShopDeliveryAddressRes, GetUserShopDeliveryAddressListParameters, GetUserShopDeliveryAddressParameters } from '../api';
import { MyThunkDispatch } from './action-interface';
import tool from '../tool';

const upDataAction = (data: ActionUserShopDeliveryAddressRes) => {
    return {
        type: Type.userShopDeliveryAddressType.UP_DATA,
        ...data
    }
}

const initDataAction = () => {
    return {
        type: Type.userShopDeliveryAddressType.INIT_DATA
    }
}

interface ActionUserShopDeliveryAddressListRes {
    [id: string]: GetUserShopDeliveryAddressRes
}
export interface ActionUserShopDeliveryAddressRes {
    list: ActionUserShopDeliveryAddressListRes
}

export const getUserShopDeliveryAddressList = (params: GetUserShopDeliveryAddressListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getUserShopDeliveryAddressList(params)
        if (res.kind === 'ok') {
            const userShopDeliveryAddress: ActionUserShopDeliveryAddressRes = tool.store.getState().userShopDeliveryAddress
            userShopDeliveryAddress.list = {}
            res.data.data.forEach(item => {
                userShopDeliveryAddress.list[item.id] = item
            })
            dispatch(upDataAction(userShopDeliveryAddress))
        }
        return res
    }
}
export const getUserShopDeliveryAddress = (params: GetUserShopDeliveryAddressParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getUserShopDeliveryAddress(params)
        if (res.kind === 'ok') {
            const userShopDeliveryAddress: ActionUserShopDeliveryAddressRes = tool.store.getState().userShopDeliveryAddress
            userShopDeliveryAddress.list[res.data.id] = res.data
            dispatch(upDataAction(userShopDeliveryAddress))
        }
        return res
    }
}

export const initData = () => {
    return (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}












