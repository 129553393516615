/**
 * Created by JJ on 2017/11/11.
 */
// import React from 'react'
import ToolConfig from '../ToolConfig'
import axios from 'axios';



export let debug = ToolConfig.type === 'dev'
export let api = ToolConfig.api
export let v = ToolConfig.v
export let code = {
    auth_sms_request_new: '/auth/sms/request-new',      // 請求發送sms驗証碼作註冊, 發送至phone
    auth_sms_request: '/auth/sms/request',            // 請求發送sms驗証碼作登入, 發送至phone
    auth_sms_verify: '/auth/sms/verify',            // 提交sms驗証碼並登入
    auth_session_request_new: '/auth/session/request-new',            // 区域授权
    auth_session_verify: '/auth/session/verify',            // 订单Id登录
    auth_sms_verify_new: '/auth/sms/verify-new',            // 提交sms驗証碼並登入
    auth_password: '/auth/password',            //提交账号密码登录
    user: '/user',
    area: '/area',//地区
    area_module: '/area-module',//功能模块
    external_o2o_kiosk: '/external/o2o/kiosk',//售賣機/機器相關
    area_kiosk: '/area-kiosk',//地域機器 (自提柜)
    user_payment: '/user-payment',
    user_profile_img: '/user-profile-img/me',//會員頭像
    forum_section: '/forum/section',//
    forum_sub_section: '/forum/sub-section',//
    forum_thread: '/forum/thread',//
    forum_post: '/forum/post',//
    shop_file: '/shop/file',//Shop File 商城文件 (圖片/影片)
    shop_package: '/shop/package',//
    shop_order_box: '/shop/order-box',
    user_shop_delivery_recipient: '/user-shop-delivery-recipient',//用戶收貨人記錄
    user_shop_delivery_address: '/user-shop-delivery-address',//用戶收货地址
    shop_delivery: '/shop/delivery',//送貨方式
    freeride_trip_enrollment: '/freeride/trip-enrollment',//
    user_nickname: '/user-nickname',
    user_me: '/user/me',
    user_permission: '/user-permission',//用戶權限
    area_areaId: '/area',
    area_lang: '/area-lang',
    user_setting: '/user-setting',
    auth: '/auth',
    auth_facebook: '/auth/facebook',//facebook授权
    user_member_card: '/user-member-card',
    system_news: '/system-news',//系统消息
    user_forum_thread_bookmark: '/user-forum-thread-bookmark',//我的收藏
    user_forum_thread: '/user-forum-thread',//我的发布
    user_area: '/user-area',
    advert: '/advert',//广告
    system_feedback: '/system-feedback',//我的建议
    auth_sms_request_change: '/auth/sms/request-change',//绑定手机发送验证码
    auth_sms_verify_change: '/auth/sms/verify-change',//判斷sms驗証碼, 然後更新當前用戶的電話號碼和用戶登入訊息
    metadata: '/metadata',//元数据

    /*=======================商城=======================*/
    shop_campaign: '/shop/campaign', //商城活動
    shop_cart_campaign_package: '/shop/cart-campaign-package', //商城购物车
    shop_campaign_package: '/shop/campaign-package', //商城選購包
    shop_campaign_order: '/shop/campaign-order', //商城訂單
    shop_campaign_order_cancel: '/shop/campaign-order-cancel', //取消商城訂單
    shop_campaign_lang: '/shop/campaign-lang', //商城语言
    shop_campaign_order_payment: '/shop/campaign-order-payment', //支付商城訂單
    shop_product: '/shop/product', //商城產品
    shop_product_brand: '/shop/product-brand', //商城產品品牌
    shop_product_type: '/shop/product-type', //商城產品種類
    shop_product_lang: '/shop/product-lang', //商城產品語言
    shop_order: '/shop/order', //商城订单
    shop_order_by_human_id: '/shop/order-by-human-id', //商城订单
    shop_order_from_cart: '/shop/order-from-cart', //商城购物车订单
    shop_order_cancel: '/shop/order-cancel', //商城訂單取消
    shop_order_payment: '/shop/order-payment', //支付商城訂單
    shop_campaign_tag: '/shop/campaign-tag', //商城活動標簽
    /*=======================支付=======================*/
    payment: '/payment', //支付方式

    /*=======================商城=======================*/
}


/**
 * delete 请求
 * @param code          请求验证
 * @param body          请求体
 */
export const deleteMy = (code, body = {},) => {
    const uri = `${api}${code}`


    return axios.delete(uri, {
        params: {
            ...body,
        },
        validateStatus: function (status) {
            return true
        },
        withCredentials: true, // 默认的

    })
        .then(data => {
            console.log('delete--->', body)
            console.log('deleteCode--->', code, data)
            return data
        })
        .catch(e => {
            console.log('delete--->', body)
            console.log('deleteCode--->', code, e)
            return e
        })
}

/**
 * get 请求
 * @param code          请求验证
 * @param body          请求体
 */
export const get = (code, body = {},) => {
    const uri = `${api}${code}`

    return axios.get(uri, {
        params: {
            ...body,
        },
        validateStatus: function (status) {
            return true
        },
        withCredentials: true, // 默认的

    },)
        .then(data => {
            console.log('get--->', body)
            console.log('getCode--->', code, data)
            return data
        })
        .catch(e => {
            console.log('get--->', body)
            console.log('getCode--->', code, e)
            return e
        })
}


/**
 * post 请求
 * @param code
 * @param body
 * @param config
 * @returns {AxiosPromise<any>}
 */
export const post = (code, body = {}, config = {}) => {
    const uri = `${api}${code}`


    return axios.post(uri, body, {

        validateStatus: function (status) {
            return true
        },
        withCredentials: true,

        ...config,
    })
        .then(data => {
            console.log('postBody--->', body)
            console.log('postCode--->', code, data)
            return data
        })
        .catch(e => {
            console.log('postBody--->', body)
            console.log('postCode--->', code, e)
            return e
        })
}


/**
 * put 请求
 * @param code           请求类型
 * @param body           请求体
 */
export const put = (code, body = {},) => {
    const uri = `${api}${code}`


    return axios.put(uri, body, {
        validateStatus: function (status) {
            return true
        },
        withCredentials: true, // 默认的

    })
        .then(data => {
            console.log('putBody--->', body)
            console.log('putCode--->', code, data)
            return data
        })
        .catch(e => {
            console.log('putBody--->', body)
            console.log('putCode--->', code, e)
            return e
        })
}


/**
 * 请求所有
 */
export const all = (ary) => {
    return new Promise(function (resolve, reject) {
        axios.all(ary)
            .then(data => {
                resolve(data.map(item => {
                    if (item.status !== 200) {
                        if (typeof(item.data) === 'string' && item.data === 'NOT_LOGGED_IN') {
                            //强制退出 会回到登录页面
                            // window.tool.store.dispatch(ActionUser.authOut())
                        } else {
                            typeof(item.data) === 'string' && window.tool.dialog.toast({content: window.tool.intl.formatMessage({id: item.data})})
                        }
                        // window.tool.error(item.data||'no data')
                        reject(item.data)
                    }
                    return item
                }))
            })
            .catch(e => {
                reject(e)
            })
    })
}




