import React from 'react';
import { Badge } from 'antd-mobile'
import { Button } from 'react-onsenui'
import { injectIntl, InjectedIntlProps } from 'react-intl';
import tool from '../../../tool';
import { GetShopCampaignRes } from '../../../api/api-res-type';


interface PageState {

}

export interface Props {
    /**
     * 购买回调
     */
    callBuy(): void
    /**
     * 加入购物车回调
     */
    callJoin(): void
    /**
     * 购物车回调
     */
    callShoppingCart(): void

    campaing: GetShopCampaignRes

    badge?: number

}

type PageProps = Props & InjectedIntlProps



class JJ_ShopCampaignDetailFoot extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
    }

    render() {
        return (
            <div id='JJ_ShopCampaignDetailFoot'>

                {this.props.campaing.type !== 'GROUP' && (
                    <>
                        <Button
                            onClick={() => this.props.callShoppingCart()}
                            className='shoppingBarCart'
                        >
                            {tool.intl.formatMessage({ id: 'buttonViewCart' })}
                            <Badge className='badge' text={this.props.badge || 0} />
                        </Button>
                        <Button
                            modifier='cta'
                            onClick={() => this.props.callJoin()} className='shoppingBarJoin'>{tool.intl.formatMessage({ id: 'buttonAddToCart' })}</Button>
                    </>
                )}
            </div>
        );
    }
}

export default injectIntl(JJ_ShopCampaignDetailFoot)







