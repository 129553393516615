import * as ons from "onsenui/js/onsenui";
import { Modal } from 'antd-mobile'
import tool from ".";
import { Action } from "antd-mobile/lib/modal/PropsType";


interface ToastParams {
    timeout?: number
    content: string
    animation?: 'default' | 'ascend' | 'lift' | 'fall' | 'fade' | 'none'
}


/**
 * 吐司
 */
export const toast = (params: ToastParams) => ons.notification.toast(params.content, { timeout: params.timeout || 3000, animation: params.animation || 'fall' });

interface AlertParams {
    content: string
}

/**
 * 注意
 */
export const alert = (params: AlertOptions) => ons.notification.alert(params);



/**
 * 确定
 */
export const confirm = Modal.alert


interface AlertOptions {
    message?: string;
    messageHTML?: string;
    buttonLabel?: string;
    buttonLabels?: string[];
    primaryButtonIndex?: number;
    cancelable?: boolean;
    animation?: string;
    title?: string;
    modifier?: string;
    callback?: any;
    id?: string;
}


/**
 * 提示
 */
export const prompt = (message: string, options?: AlertOptions) => ons.notification.prompt(message, options);

/**
 * 提示
 */
export const openActionSheet = ({
    title = 'From object',
    cancelable = true,
    buttons = [
        'Label 0',
        'Label 1',
        {
            label: 'Label 2',
            modifier: 'destructive',
        },
        {
            label: tool.intl.formatMessage({ id: '取消' }),
            icon: 'md-close'
        }
    ],

    ...options

}) => ons.openActionSheet({

    title,
    cancelable,
    buttons,
    ...options

});




