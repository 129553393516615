import React from 'react';
import queryString from 'query-string';
import { Modal, SwipeAction, ActivityIndicator } from 'antd-mobile'
import { InjectedIntlProps, injectIntl } from 'react-intl';
import tool from '../../tool';
import *as ActionCartCampaignPackage from '../../actions/ActionCartCampaignPackage';
import *as ActionCampaign from '../../actions/ActionCampaign';
import { GetShopCartCampaignPackageListParameters, GetShopCartCampaignPackageParameters, PutShopCartCampaignPackageParameters, GetShopCampaignParameters, DeleteShopCartCampaignPackageParameters, PostShopOrderFromCartParameters } from '../../api/api-parameters-type';
import { connect, ConnectedProps } from 'react-redux'
import { GetShopCartCampaignPackageRes } from '../../api/api-res-type';
import *as ActionUser from '../../actions/ActionUser';
import JJ_Nav from '../SuperComponent/JJ_Nav'
import JJ_Components from '../../components/JJ_Components';
import *as Ons from 'react-onsenui'
import api, {GetShopCampaignRes} from '../../api'
import orderPng from '../../imgs/order.png'
import { FaTimes } from 'react-icons/fa';
interface PageState {
    loaded: boolean
}

export interface Props {
}

type PageProps = InjectedIntlProps & Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    campaign: ActionCampaign.ActionCampaignRes,
    cartCampaignPackage: ActionCartCampaignPackage.ActionCartCampaignPackageRes
    user: ActionUser.ActionUserRes,
}


const mapState = (state: RootState) => ({

    cartCampaignPackage: state.cartCampaignPackage,
    campaign: state.campaign,
    user: state.user,
})

const mapDispatch = {
    getShopCartCampaignPackage: ActionCartCampaignPackage.getShopCartCampaignPackage,
    getShopCartCampaignPackageList: ActionCartCampaignPackage.getShopCartCampaignPackageList,
    getShopCampaign: ActionCampaign.getShopCampaign,
}

export const connector = connect(
    mapState,
    mapDispatch
)


class JJ_ShopCartCampaignPackage extends React.Component<PageProps, PageState> {


    constructor(props: PageProps) {
        super(props);
        this.state = {
            loaded: false,
        }
    }

    _initData = async () => {
        try {
            await this._getShopCartCampaignPackageList({ count: 10000 })

            await Promise.all(
                Object.keys(this.props.cartCampaignPackage.list)
                    .filter(cartCampaignPackageId => !this.props.campaign.list[this.props.cartCampaignPackage.list[cartCampaignPackageId].campaignId])
                    .map(cartCampaignPackageId => {
                        return this._getShopCampaign({
                            queryCampaignPackage: true,
                            campaignId: this.props.cartCampaignPackage.list[cartCampaignPackageId].campaignId,
                        })
                        .catch(err => {});
                    })
            );

            this.setState({ loaded: true })
        } catch (error) {
            console.error('_initData', error)
        }
    }

    /**
     * 过滤商城活动购物车
     */
    _filterShopCartCampaignList = () => {
        const list = Object.keys(this.props.cartCampaignPackage.list)
            .map(key => this.props.cartCampaignPackage.list[key])
        // .filter(cartCampaignPackage => cartCampaignPackage.campaignId === campaignId)
        return list
    }

    _getShopCartCampaignPackageList = async (params: GetShopCartCampaignPackageListParameters) => {
        const res = await this.props.getShopCartCampaignPackageList(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }
    }

    _getShopCampaign = async (params: GetShopCampaignParameters) => {
        const res = await this.props.getShopCampaign(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }
    }


    _getShopCartCampaignPackage = async (params: GetShopCartCampaignPackageParameters) => {
        const res = await this.props.getShopCartCampaignPackage(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }
    }


    _putShopCartCampaignPackage = async (params: PutShopCartCampaignPackageParameters) => {
        const res = await api.putShopCartCampaignPackage(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
                    break
                case 'NOT_ENOUGH_INVENTORY': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[NOT_ENOUGH_INVENTORY]' }) })
                    this._onDeleteShopCartCampaignPackage({ id: params.id })
                }
                    break

            }
            throw new Error(res.kind)
        }
    }


    _onPutShopCartCampaignPackage = async (params: PutShopCartCampaignPackageParameters) => {

        try {
            tool.message.loading({})
            const putShopCartCampaignPackageRes = await this._putShopCartCampaignPackage(params)
            const getShopCartCampaignPackageRes = await this._getShopCartCampaignPackage({ id: putShopCartCampaignPackageRes.id })
            await this._getShopCampaign({
                queryCampaignPackage: true,
                campaignId: getShopCartCampaignPackageRes.campaignId
            })
            tool.message.hide()

        } catch (error) {
            tool.message.hide()
            console.log('_onPutShopCartCampaignPackage', error)
        }

    }

    _onDeleteShopCartCampaignPackage = async (params: DeleteShopCartCampaignPackageParameters) => {

        try {
            tool.message.loading({})
            await api.deleteShopCartCampaignPackage(params)
            await this._initData()
            tool.message.hide()

        } catch (error) {
            tool.message.hide()
            console.log('_onPutShopCartCampaignPackage', error)
        }

    }

    /**
     * 创建购物车订单
     */
    _onPostShopOrderFromCart = async (params: PostShopOrderFromCartParameters,campaign:GetShopCampaignRes) => {
        try {
            await this._checkLogin()
            tool.message.loading({})
            const postShopOrderFromCartRes = await this._postShopOrderFromCart(params)
            await this._initData()
            tool.message.hide()
            tool.navigator.popPage()
            tool.navigator.pushPage({
                key: 'JJ_ShopOrderDetails', props: {
                    showPaymentDescription: campaign.type==='GROUP',
                    orderId
                        : postShopOrderFromCartRes.id,
                    moduleId: campaign.moduleId,
                    onDelete: orderId => {
                        this._initData()
                    },
                    onCancel: orderId => {
                        this._initData()
                    },
                }
            })
        } catch (error) {
            tool.message.hide()
            console.log(error)
        }
    }

    _postShopOrderFromCart = async (params: PostShopOrderFromCartParameters) => {
        const res = await api.postShopOrderFromCart(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'CAMPAIGN_NOT_AVAILABLE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignNotAvailable' }) })
                }
                    break
                case 'COUPON_NOT_FOUND': {

                }
                    break
                case 'DELIVERY_NOT_FOUND': {

                }
                    break
                case 'EXCEED_PACKAGE_AMOUNT_LIMIT': {

                }
                    break
                case 'EXCEED_PACKAGE_USER_AMOUNT_LIMIT': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[EXCEED_PACKAGE_USER_AMOUNT_LIMIT]' }) })

                }
                    break
                case 'INVALID_CAMPAIGN_PACKAGE': {

                }
                    break
                case 'INVALID_PARAMS': {

                }
                    break
                case 'NOT_ENOUGH_INVENTORY': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettails[NOT_ENOUGH_INVENTORY]' }) })
                    this._initData()
                }
                    break
                case 'NOT_FOUND': {

                }
                    break
                case 'RECIPIENT_ADDRESS_NOT_FOUND': {

                }
                    break
                case 'RECIPIENT_NOT_FOUND': {

                }
                    break
                case 'USER_COUPON_NOT_FOUND': {

                }
                    break
                case 'USER_COUPON_NOT_USABLE': {

                }
                    break
                case 'CAMPAIGN_NOT_AVAILABLE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignNotAvailable' }) })
                }
                    break

                case 'CAMPAIGN_NOT_FOUND': {

                }
                    break


            }
            throw new Error(res.kind)
        }
    }

    _checkLogin = async (): Promise<void> => {
        if (this.props.user.class !== 'BASIC') {
            tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignMemberLogin' }) })

            // check webview
            const urlParams = queryString.parse(location.search);
            const isWebview = urlParams.view === 'webview';

            throw new Error(tool.intl.formatMessage({ id: 'shopCampaignDettailsCampaignMemberLogin' }))
        }
    }


    render() {
        const nav = {
            showLeftIcon: true,
            title: tool.intl.formatMessage({ id: 'JJ_ShopCart[title]' }),
        }

        const list = this._filterShopCartCampaignList()

        const ShopCartCampaignListView = (list: GetShopCartCampaignPackageRes[]) => {
            return (
                <div
                    className='ShopCartCampaignListView'
                >
                    {list.map((listItem, index) => {
                        const campaignPackages = this.props.campaign.list[listItem.campaignId].campaignPackages
                        if (campaignPackages) {
                            const campaignPackage = campaignPackages.find(campaignPackage => campaignPackage.id === listItem.campaignPackageId)
                            if (campaignPackage) {
                                let { userAmountLeft, amountLeft } = campaignPackage

                                userAmountLeft = userAmountLeft || Number.MAX_VALUE
                                amountLeft = amountLeft || 0
                                const maxNub = userAmountLeft > amountLeft ? amountLeft : userAmountLeft

                                return (

                                    <SwipeAction
                                        key={listItem.id}

                                        style={{ marginBottom: 5 }}
                                        autoClose
                                        right={[
                                            {
                                                text: tool.intl.formatMessage({ id: 'shopCampaignDettails[listItemButtonDelete]' }),
                                                onPress: () => {
                                                    this._onDeleteShopCartCampaignPackage({ id: listItem.id })
                                                },
                                                style: { backgroundColor: '#F4333C', color: 'white' },
                                            },
                                        ]}
                                        onOpen={() => console.log('global open')}
                                        onClose={() => console.log('global close')}
                                    >
                                        <div
                                            className={'ShopCartCampaignListItem'}
                                        >
                                            <img className='img' src={campaignPackage.thumbnails[0].url} />

                                            <div
                                                className='ShopCartCampaignListItemRightBox'
                                            >
                                                <span className='name'>{campaignPackage.name}</span>

                                                <span className='amountLimit'>  {tool.intl.formatMessage({ id: '限购数量' }, { number: maxNub })}</span>


                                                <div className='ShopCartCampaignListItemRightBoxFootBox'>

                                                    <div
                                                        className='priceAndPriceOriginalBox'
                                                    >
                                                        <span className='price'><span className='price item'>$</span>{tool.math.initPrice({ price: campaignPackage.price }).toFixed(2)}</span>
                                                        <span className='priceOriginal'>${tool.math.initPrice({ price: campaignPackage.priceOriginal }).toFixed(2)}</span>
                                                    </div>

                                                    <JJ_Components.baseCount
                                                        minNub={0}
                                                        nub={listItem.amount}
                                                        onCallNub={(nub: number) => {
                                                            if (nub > 0) {
                                                                this._onPutShopCartCampaignPackage({
                                                                    id: listItem.id,
                                                                    amount: nub,
                                                                })
                                                            } else {
                                                                this._onDeleteShopCartCampaignPackage({ id: listItem.id })
                                                            }

                                                        }}
                                                        maxNub={maxNub + listItem.amount}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </SwipeAction>


                                )
                            }
                            return (
                                <div
                                    key={listItem.id}
                                />
                            )
                        } else {
                            return (
                                <div key={listItem.id} />
                            )
                        }
                    })}
                </div>
            )
        }


        const normalView = () => {
            return this.state.loaded ? (
                <>
                    {list.length >= 1
                        ? ShopCartCampaignListView(list)
                        : noDataView()
                    }
                    {list.length >= 1 && footView()}
                </>
            ) : (
                <div className='loadView'>
                    <ActivityIndicator
                        animating={true}
                    />
                </div>
            )
        }
        const noDataView = () => {
            return (
                <div
                    className='noDataView'
                >
                    <img

                        src={orderPng} />
                    <span>{tool.intl.formatMessage({ id: 'shopCartCampaignPackagesModal[noDateText]' })}</span>
                </div>
            )
        }
        const footView = () => {
            const shopCartCampaignList = this._filterShopCartCampaignList()


            console.log('shopCartCampaignList', shopCartCampaignList)
            const {
                price,
                priceOriginal,
            } = shopCartCampaignList.reduce((p, c, ) => {

                const [campaignPackage] = this.props.campaign.list[c.campaignId].campaignPackages || []
                const price = (campaignPackage ? campaignPackage.price : 0) * c.amount
                const priceOriginal = (campaignPackage ? campaignPackage.priceOriginal : 0) * c.amount
                p.price += price
                p.priceOriginal += priceOriginal
                return p
            }, {
                price: 0,
                priceOriginal: 0,

            })

            return (
                <div
                    className='footView'
                >
                    <div
                        className='leftBox'
                    >
                        <span className='amountText'>{tool.intl.formatMessage({ id: 'shopCartCampaignPackagesModal[amountText]' })}:</span>
                        <div
                            className='priceAndPriceOriginalBox'
                        >
                            <span className='price'><span className='price item'>$</span>{tool.math.initPrice({ price: price }).toFixed(2)}</span>
                            <span className='priceOriginal'>${tool.math.initPrice({ price: priceOriginal }).toFixed(2)}</span>
                        </div>

                    </div>
                    <div
                        className='rightBox'
                    >
                        <Ons.Button
                            className='payButton'
                            modifier='cta'
                            onClick={() => {
                                const cartCampaignPackageIds = shopCartCampaignList.map(cartCampaignPackage => cartCampaignPackage.id);
                                if (shopCartCampaignList.length) {
                                    const campaign = this.props.campaign.list[shopCartCampaignList[0].campaignId];

                                    this._onPostShopOrderFromCart({
                                        cartCampaignPackageIds: cartCampaignPackageIds,
                                    },campaign)
                                }
                            }}
                        >
                            {tool.intl.formatMessage({ id: 'shopCartCampaignPackagesModal[payButton]' })}
                        </Ons.Button>
                    </div>

                </div>
            )
        }
        return (
            <Ons.Page renderToolbar={() => <JJ_Nav {...nav} />}>
                <JJ_Components.boxPage
                    loadData={this._initData}
                >
                    <div
                        id='JJ_ShopCartCampaignPackage'
                    >
                        {normalView()}
                    </div>
                </JJ_Components.boxPage>
            </Ons.Page>
        );
    }
}


export default connector(injectIntl(JJ_ShopCartCampaignPackage))






