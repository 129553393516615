
let myHistory


export const  getRuter=(history)=>myHistory = history

export const  createHref=(location)=>myHistory.createHref(location)

export const  go=(n)=>myHistory.go(n)

export const  goBack = ()=>myHistory.goBack()

export const  push = (path, state)=>myHistory.push(path, state)

export const  eplace=(path, state)=>myHistory.replace(path, state)

export const  goForward=()=>myHistory.goForward()











