import React from 'react';
import {connect} from 'react-redux'
import {injectIntl, InjectedIntlProps} from "react-intl";
import *as Ons from 'react-onsenui'
import {GoTools} from 'react-icons/go';
import tool from '../../tool';

class JJ_AreaModuleDisabled extends React.Component<InjectedIntlProps> {



    render() {
        return (
            <Ons.Page>
            	<div id="JJ_AreaModuleDisabled" className="box">
                	<GoTools className="icon" />
                	<div>{tool.intl.formatMessage({id: 'areaModuleDisabledMessage'})}</div>
            	</div>
            </Ons.Page>
        )
    }
}

function mapStateToProps(state: { user: any; }) {
    return {
        userData: state.user,
    }
}

export default connect(mapStateToProps)(injectIntl(JJ_AreaModuleDisabled))
