import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { injectIntl, InjectedIntlProps } from "react-intl";
import tool from '../../tool';
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav';
import { GetStorageRes } from '../../tool/ToolStorage';
import api, { PostAuthSmsRequestNewParameters, PostUserParameters } from '../../api';
import { AreaCodes } from '../../constants/area-code';
import BoxPage from '../../components/Box/BoxPage';
import { FaLock } from 'react-icons/fa'


interface PageState {
    phone: string
    token: string
    IntervalToken: number
    sendVerificationCodeTime: number
    areaCodeIndex: number
    sendVerificationCode: boolean
}


export interface Props {
    localStorageAction?: GetStorageRes
    areaId: string
    title?: string
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface Page {

}


interface RootState {

}

const mapState = (state: RootState) => ({


})

const mapDispatch = {

}

export const connector = connect(
    mapState,
    mapDispatch
)

class JJ_RegisteredMemberSms extends React.Component<PageProps, PageState> implements Page {
    sendVerificationCodeInterval: any
    sendVerificationCodeTimeMax = 60

    constructor(props: PageProps) {
        super(props);
        this.state = {
            phone: '',
            token: '',
            IntervalToken: 0,
            sendVerificationCodeTime: 0,
            areaCodeIndex: 0,
            sendVerificationCode: false,
        }

    }

    static defaultProps = {};


    componentWillUnmount() {
        this._clearIntervalSendVerificationCode()
    }

    _getData = async () => {

    }

    _postUser = async (params: PostUserParameters) => {
        const res = await api.postUser(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {

                case 'DUPLICATE_PHONE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[DUPLICATE_PHONE]' }) })
                }
                    break
                case 'INVALID_PHONE_NUMBER':
                case 'PHONE_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[INVALID_PHONE_NUMBER]' }) })
                }
                    break
                case 'ALREADY_USER': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[ALREADY_USER]' }) })
                }
                    break
                case 'AREA_NOT_FOUND': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[AREA_NOT_FOUND]' }) })
                }
                    break

                case 'INVALID_TOKEN': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[INVALID_TOKEN]' }) })
                }
                    break
                case 'INVALID_PARAMS':
                case 'INVALID_USERNAME':
                case 'NOT_FOUND':
                case 'DUPLICATE_USERNAME': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[SYSTEM_ERROR]' }) })
                }
                    break

            }
            throw new Error(res.kind)
        }
    }

    /**
     * 撤销发送验证码定时器
     */
    _clearIntervalSendVerificationCode = () => {
        this.sendVerificationCodeInterval && clearInterval(this.sendVerificationCodeInterval)
    }
    /**
     * 发送验证码
     */
    _onSendVerificationCode = async () => {
        try {
            tool.message.loading({})
            const areaCode = AreaCodes[this.state.areaCodeIndex].value
            await this._postAuthSmsRequestNew({ phone: `${areaCode}-${this.state.phone}` })

            this.setState({
                sendVerificationCode: true,
                sendVerificationCodeTime: this.sendVerificationCodeTimeMax,
            })
            this.sendVerificationCodeInterval = setInterval(() => {

                if (this.state.sendVerificationCodeTime <= 0) {
                    this._clearIntervalSendVerificationCode()
                }
                this.setState({
                    sendVerificationCodeTime: this.state.sendVerificationCodeTime - 1
                })

            }, 1000)
            tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[sendVerificationCodeSuccess]' }) })
            tool.message.hide()

        } catch (error) {
            tool.message.hide()
        }
    }

    _onConfirm = async () => {
        try {
            tool.message.loading({})
            const areaCode = AreaCodes[this.state.areaCodeIndex].value
            await this._postUser({
                role: 'NORMAL',
                phone: `${areaCode}-${this.state.phone}`,
                auth: {
                    smsToken: this.state.token,
                },
                userAreas: {
                    HOME: this.props.areaId
                },
                userSettings: {
                    lang: 'zh-tw'
                },
            })
            tool.message.hide()
            tool.navigator.resetPage({ key: 'JJ_Loading' })
            if (this.props.localStorageAction) {
                tool.storage.setItem({ key: 'pushAction', data: this.props.localStorageAction })
            }
        } catch (error) {
            tool.message.hide()
            console.log(error)
        }
    }

    _postAuthSmsRequestNew = async (params: PostAuthSmsRequestNewParameters) => {
        const res = await api.postAuthSmsRequestNew(params)
        if (res.kind === 'ok') {
            return true
        } else {
            switch (res.kind) {

                case 'DUPLICATE_PHONE': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[DUPLICATE_PHONE]' }) })
                }
                    break
                case 'INVALID_PHONE_NUMBER': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[INVALID_PHONE_NUMBER]' }) })
                }
                    break
                case 'TOO_EARLY': {
                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'registeredMemberSms[TOO_EARLY]' }) })
                }
                    break
            }
            throw new Error(res.kind)
        }
    }
    /**
     * 判断手机号码
     */
    _verificationPhone = () => {
        try {
            const areaCode = AreaCodes[this.state.areaCodeIndex]
            const phone = this.state.phone
            const phoneNumber = tool.phoneNumber.parseAndKeepRawInput(`${areaCode.value}-${phone}`)
            return tool.phoneNumber.isValidNumberForRegion(phoneNumber, areaCode.type)
        } catch (err) {
            console.log(err)
            return false
        }
    }

    /**
     * 判断验证码
     */
    _verificationCode = () => {
        return this.state.token.length === 6
    }


    render() {
        const nav = {
            showLeftIcon: true,
            showRightIcon: false,
            ...this.props
        }
        const phoneInputView = () => {
            return (
                <React.Fragment>
                    <div
                        className='phoneInputView'
                    >
                        <div className='phoneInputViewTopBox'>
                            <Ons.Select
                                className="select"
                                value={`${this.state.areaCodeIndex}`}
                                modifier='transparent'
                                onChange={e => this.setState({ areaCodeIndex: Number(e.target.value) })}>
                                {AreaCodes.map((areaCode, index) => (
                                    <option
                                        key={index}
                                        value={index}>
                                        {areaCode.value}
                                    </option>
                                ))}
                            </Ons.Select>
                            <Ons.Input
                                className='input'
                                type='number'
                                value={this.state.phone}
                                float={false}
                                onChange={(e) => this.setState({ phone: `${e.target.value}` })}
                                modifier='transparent'
                                placeholder={tool.intl.formatMessage({ id: 'registeredMemberSms[phoneInputViewPlaceholder]' })}
                            />
                        </div>
                        {this.state.sendVerificationCode
                            ? (
                                <span
                                    className='onSentInfo'
                                >
                                    {tool.intl.formatMessage({ id: 'registeredMemberSms[verificationInputViewOnSentInfo]' })}
                                </span>
                            ) : (
                                <span
                                    className='offSentInfo'
                                >
                                    {tool.intl.formatMessage({ id: 'registeredMemberSms[verificationInputViewOffSentInfo]' })}
                                </span>
                            )}
                    </div>
                    <Ons.Button
                        className='confirmButton'
                        modifier={this.state.sendVerificationCode ? 'large outline' : 'large'}
                        disabled={!(this._verificationPhone() && this.state.sendVerificationCodeTime <= 0)}
                        onClick={this._onSendVerificationCode}
                    >
                        {this.state.sendVerificationCode
                                ? tool.intl.formatMessage({ id: 'registeredMemberSms[verificationResendInputViewButton]' })
                                : tool.intl.formatMessage({ id: 'registeredMemberSms[verificationInputViewButton]' })}
                        {this.state.sendVerificationCodeTime >= 1 ? ` (${this.state.sendVerificationCodeTime})` : ''}
                    </Ons.Button>
                </React.Fragment>
            )
        }
        const verificationInputView = () => {

            return this.state.sendVerificationCode && (
                <React.Fragment>
                    <div
                        className='verificationInputView'
                    >
                        <div className='verificationInputViewTopBox'>
                            <div className='verificationInputViewIcon'>
                                <FaLock />
                            </div>
                            <Ons.Input
                                className='input'
                                disabled={!(this._verificationPhone() && this.state.sendVerificationCode)}
                                value={this.state.token}
                                float={false}
                                onChange={(e) => this.setState({ token: `${e.target.value.trim().replace(/[^0-9]/g, '').substr(0, 6)}` })}
                                modifier='transparent'
                                placeholder={tool.intl.formatMessage({ id: 'registeredMemberSms[verificationInputViewPlaceholder]' })}
                            />
                        </div>
                    </div>
                    <Ons.Button
                        modifier="large"
                        onClick={this._onConfirm}
                        className={'confirmButton'}
                        disabled={!(this._verificationPhone() && this._verificationCode())}
                    >
                        {tool.intl.formatMessage({ id: 'registeredMemberSms[ConfirmButtonText]' })}
                    </Ons.Button>
                </React.Fragment>
            )
        }

        return (
            <Ons.Page renderToolbar={() => (<JJ_Nav {...nav} />)}>

                <BoxPage

                    loadData={this._getData}
                >
                    <div
                        id='JJ_RegisteredMemberSms'
                    >
                        <h1 className='title'>{this.props.title || tool.intl.formatMessage({ id: 'registeredMemberSms[title]' })}</h1>

                        {phoneInputView()}
                        {verificationInputView()}

                        <Ons.Button
                            modifier="quiet"
                            onClick={() => {
                                tool.navigator.pushPage({
                                    key: 'JJ_LoginSms',
                                    props: {
                                        localStorageAction: this.props.localStorageAction,
                                    }
                                })
                            }}
                            className={'loginButton'}
                        >
                            {tool.intl.formatMessage({ id: 'registeredMemberSms[loginButton]' })}
                        </Ons.Button>
                    </div>
                </BoxPage >


            </Ons.Page>

        )
    }
}

export default connector(injectIntl(JJ_RegisteredMemberSms))


