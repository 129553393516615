import React from 'react'
import {Carousel, WingBlank} from 'antd-mobile';
import {FaAngleRight} from 'react-icons/fa'
import JJ_Components from '../JJ_Components'

class BaseHeadTitle extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    static defaultProps = {
        title: '哈哈',
        content: '',
        callContent: () => console.log('点了'),
    };


    render() {

        return (
            <div
                id='BaseHeadTitle'
                style={this.props.style}
            >
                <div
                    className='leftBox'
                >
                    <div className='line'/>
                    <span className='title'>{this.props.title}</span>
                </div>
                {this.props.content
                    ? <JJ_Components.boxBtn
                        onClick={() => this.props.callContent()}
                        className='rightBox'
                    >
                        <span
                            className='content'
                        >{this.props.content}</span>
                        <FaAngleRight className='icon'/>
                    </JJ_Components.boxBtn>
                    : null}
            </div>
        );
    }

}

export default BaseHeadTitle
