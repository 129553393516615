import React from 'react';

import *as Ons from 'react-onsenui'

import { GetUserShopDeliveryAddressRes } from '../../../api';
import tool from '../../../tool';



interface PageState {

}

export interface Props {
    onSelect(id: string, address: string): void
    userDeliveryAddress: GetUserShopDeliveryAddressRes
    onPress(): void
    checked: boolean
}



type PageProps = Props

class JJ_UserDeliveryAddressListItem extends React.Component<PageProps, PageState> {

    componentDidMount() {
        const { isDefault, id, address } = this.props.userDeliveryAddress
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
        }
    }

    _leftView = () => {

        return (
            <div className='leftView'>
                <Ons.Radio
                    inputId={`radio-${this.props.userDeliveryAddress.id}`}
                    onChange={event => this.props.onSelect(this.props.userDeliveryAddress.id, this.props.userDeliveryAddress.address)}
                    modifier='material'
                    checked={this.props.checked}//选中
                />
            </div>
        )
    }

    _centerView = () => {
        const list = [
            { type: 'address', value: this.props.userDeliveryAddress.address, isDefault: this.props.userDeliveryAddress.isDefault },
        ]

        return list.map(item => {
            return (
                <Ons.ListItem
                    tappable={false}
                    modifier='longdivider'
                    key={item.type}
                    onClick={() => this.props.onPress()}
                >
                    <div className="left">
                        <span className="addressValue">{item.value}</span>
                        {item.isDefault && (
                            <span className="defaultLabel">{tool.intl.formatMessage({ id: 'shopOrderUserDeliveryAddressDefaultLabel' })}</span>
                        )}
                    </div>
                </Ons.ListItem>
            )
        })

    }
    _rightView = () => {
        return (
            <div className='rightView'>

            </div>
        )
    }

    render() {
        return (
            <div id='JJ_UserDeliveryAddressListItem'>
                <Ons.ListItem
                    modifier='chevron longdivider'
                // tappable={false}
                >
                    <div className="left">{this._leftView()}</div>
                    <div className="center">{this._centerView()}</div>
                    <div className="right">{this._rightView()}</div>
                </Ons.ListItem>
            </div>
        );
    }
}




export default JJ_UserDeliveryAddressListItem
