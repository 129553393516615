import *as JJ_RequestAuth from './SubRequest/JJ_RequestAuth'
import *as JJ_RequestUser from './SubRequest/JJ_RequestUser'
import *as JJ_RequestArea from './SubRequest/JJ_RequestArea'
import *as JJ_RequestShop from './SubRequest/JJ_RequestShop'
import *as JJ_RequestForum from './SubRequest/JJ_RequestForum'
import *as JJ_RequestExternal from './SubRequest/JJ_RequestExternal'
import *as JJ_RequestShopDelivery from './SubRequest/JJ_RequestShopDelivery'
import *as JJ_RequestPayment from './SubRequest/JJ_RequestPayment'


export default {
    auth:JJ_RequestAuth,
    user:JJ_RequestUser,
    shop:JJ_RequestShop,
    area:JJ_RequestArea,
    forum:JJ_RequestForum,
    external:JJ_RequestExternal,
    shopDelivery:JJ_RequestShopDelivery,
    payment:JJ_RequestPayment,
}


