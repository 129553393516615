

type PushActionParams = 'pushAction'
type FirstActionParams = 'firstAction'

export type GetStorageParams = PushActionParams

export type GetStorageRes = {
    key: 'pushCampaignDetails'
    data: { id: string }
} | {
    key: 'pushShopOrderDetails'
    data: { id: string }
}

export type GetFirstActionRes = {
    key: 'first'
    data: boolean
}

export type SetStorageParams = {
    key: PushActionParams
    data: GetStorageRes
}

export type RemoveStorageParams = GetStorageParams
export type SetStorageRes = void

export const setItem = (params: SetStorageParams): SetStorageRes => {
    return localStorage.setItem(params.key, JSON.stringify(params.data))
}
export const getItem = (params: GetStorageParams) => {
    const res = localStorage.getItem(params)
    if (res !== null) {
        switch (params) {
            case 'pushAction': {
                const resData: GetStorageRes = JSON.parse(res)
                return resData
            }
        }

    } else {
        return res
    }
}

/*============================第一次启动============================ */
export const setFirstItem = (params: GetFirstActionRes): SetStorageRes => {
    return localStorage.setItem(params.key, JSON.stringify(params.data))
}

export const getFirstItem = (params: GetFirstActionRes['key']) => {
    const res = localStorage.getItem(params)
    if (res !== null) {
        const resData: GetFirstActionRes['data'] = JSON.parse(res)
        return resData
    } else {
        return res
    }
}



export const removeItem = (params: RemoveStorageParams | FirstActionParams): void => {
    localStorage.removeItem(params)
}








