import React from 'react'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import JJ_UserDeliveryAddressList from './PageItem/JJ_UserDeliveryAddressList'
import tool from '../../tool';



interface PageState {
    selectId?: string
    selectAddress?: string
}

export interface Props {
    onSelect(selectId?: string, selectAddress?: string): void
    checked: boolean
    deliveryAddressId?: string
    deliveryAddressAddress?: string
}

type PageProps = Props
class JJ_UserDeliveryAddress extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);

        this.state = {
            selectId: props.deliveryAddressId,
            selectAddress: props.deliveryAddressAddress,
        }
    }



    componentDidMount() {

    }

    render() {

        const nav = {
            showLeftIcon: true,
            title:tool.intl.formatMessage({ id: 'shopOrderUserDeliveryAddressTitle' }),
        }
        return (
            <Ons.Page renderToolbar={() => <JJ_Nav {...nav} />}>
                <div
                    id='JJ_UserDeliveryAddress'
                >
                    <div className="scroll">
                        <JJ_UserDeliveryAddressList
                            selectId={this.state.selectId}
                            onSelect={(selectId, selectAddress) => this.setState({ selectId, selectAddress })}
                        />
                    </div>
                    <Ons.Button
                        className="footView"
                        disabled={!this.state.selectId}
                        onClick={() => {
                            if (this.state.selectId) {
                                this.props.onSelect(this.state.selectId, this.state.selectAddress)
                                tool.navigator.popPage()
                            }
                        }}
                    >
                        {tool.intl.formatMessage({ id: '提交' })}
                    </Ons.Button>
                </div>
            </Ons.Page>

        )
    }
}


export default JJ_UserDeliveryAddress
