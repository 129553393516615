import React from 'react';
import JJ_Components from '../../../components/JJ_Components'
import { Modal } from 'antd-mobile'
import *as Ons from 'react-onsenui'
import { FaTimes } from 'react-icons/fa'
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connector } from './JJ_ShopCampaignList';
import { GetShopCampaignRes } from '../../../api/api-res-type';
import tool from '../../../tool';
import config from '../../../config/config.template';


interface PageState {
    packageNub: number
    nub: number
    bannerIndex: number
}


interface onCallActionParams {
    campaignId: string
    campaignPackageId: string
    amount: number
}

export interface Props {
    campaing: GetShopCampaignRes
    show: boolean
    onCallShow(): void
    onCallAction(params: PageProps & onCallActionParams): void
    type: '立刻购买' | '加入购物车'
}

type PageProps = InjectedIntlProps & Props



class JJ_ShopCampaignShopModal extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
            packageNub: 0,
            nub: 1,
            bannerIndex: 0,
        }
    }

    _headView = () => {
        if (this.props.campaing.campaignPackages) {
            const { thumbnails, price, amountShown } = this.props.campaing.campaignPackages[this.state.packageNub]
            const baseBanner = {
                key: this.state.packageNub,
                infinite: false,
            }

            let minHeight = 10000000
            thumbnails.map(thumbnail => {
                const size = tool.image.getImageStyle(thumbnail.meta, window.innerWidth * .8, window.innerHeight * .25)
                if (size.height < minHeight) {
                    minHeight = size.height
                }
            })
            return (
                <div
                    className='shoppingHead'
                >
                    <div
                        className='shoppingHeadBar'
                    >
                        <Ons.Button
                            onClick={() => this.props.onCallShow()}
                            className='shoppingHeadBarIcon'
                        >
                            <FaTimes className='icon' />
                        </Ons.Button>
                    </div>
                    <JJ_Components.baseBanner
                        {...baseBanner}
                    >
                        {thumbnails.map((thumbnail, index) => {
                            const baseImage = {
                                src: thumbnail.url,
                                style: tool.image.getImageStyle(thumbnail.meta, window.innerWidth * .8, minHeight)
                            }

                            return (
                                <div key={index} className='imgBox'>
                                    <JJ_Components.baseImage
                                        {...baseImage}
                                    />
                                </div>
                            )
                        })}
                    </JJ_Components.baseBanner>
                    <span className='price'>${tool.math.initPrice({ price: price })}</span>
                    {/* <span className='amountShown'>{tool.intl.formatMessage({ id: 'shopCampaignAmountShown' }, { number: amountShown })}</span> */}
                </div>
            )

        }

        return null

    }

    _packagesView = () => {
        const { campaignPackages } = this.props.campaing
        return (
            <div
                className='packagesView'
            >
                <span
                    className='listTitle'
                >{tool.intl.formatMessage({ id: 'shopCampaignPackage' })}</span>
                <div
                    className='packagesBox'
                >
                    {
                        campaignPackages && campaignPackages.map((item, index) => {
                            return (
                                <button
                                    key={index}
                                    onClick={() => this.setState({ packageNub: index })}
                                    className={index === this.state.packageNub ? 'itemBox selectBox' : 'itemBox'}
                                >
                                    <span
                                        className={index === this.state.packageNub ? 'itemText selectText' : 'itemText'}>{item.name}</span>
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
    _tagsView = () => {
        if (this.props.campaing.campaignPackages) {
            const { tags } = this.props.campaing.campaignPackages[this.state.packageNub].package
            return (
                <div className='tagsView'>
                    <span className='listTitle'>{tool.intl.formatMessage({ id: '规格' })}</span>
                    {tags.map((item, index) => {
                        return (
                            <div
                                className='tabBox'
                                key={index}
                            >
                                <span className='text'>{item.name}</span>
                                <span className='text'>{item.value}</span>
                            </div>
                        )
                    })}
                </div>
            )
        }

        return null

    }


    _buttonView = () => {

        if (this.props.campaing.campaignPackages) {
            const campaignPackage = this.props.campaing.campaignPackages[this.state.packageNub]
            let { amountLeft, userAmountLeft } = this.props.campaing.campaignPackages[this.state.packageNub]
            userAmountLeft = userAmountLeft || Number.MAX_VALUE
            amountLeft = amountLeft || 0
            const maxNub = userAmountLeft > amountLeft ? amountLeft : userAmountLeft
            return (
                <div className="footView">
                    <Ons.Button
                        disabled={!(maxNub >= this.state.nub)}
                        onClick={() => this.props.onCallAction({
                            ...this.props,
                            campaignId: this.props.campaing.id,
                            campaignPackageId: campaignPackage.id,
                            amount: this.state.nub
                        })}
                        className='btn'
                    >
                        <span className='buttonText'>{tool.intl.formatMessage({ id: this.props.type === '加入购物车' ? 'shopCampaignShopModalButtonAddToCart' : 'shopCampaignShopModalButtonPay' })}</span>
                    </Ons.Button>
                </div>
            )
        }

        return null
    }

    _amountView = () => {
        if (this.props.campaing.campaignPackages) {
            let { userAmountLeft, amountLeft } = this.props.campaing.campaignPackages[this.state.packageNub]

            userAmountLeft = userAmountLeft || Number.MAX_VALUE
            amountLeft = amountLeft || 0
            const maxNub = userAmountLeft > amountLeft ? amountLeft : userAmountLeft

            return (
                <div className='amountView'>
                    <div
                        className='leftBox'
                    >
                        <span className='listTitle'>{tool.intl.formatMessage({ id: '数量' })}:</span>
                        <span className='amountLimit'>  ({tool.intl.formatMessage({ id: '限购数量' }, { number: maxNub })})</span>
                    </div>
                    <JJ_Components.baseCount
                        maxNub={maxNub}
                        nub={this.state.nub}
                        onCallNub={(nub) => {
                            this.setState({ nub })
                        }}
                    />
                </div>
            )
        }

        return null

    }

    render() {

        return (
            <Modal
                popup={true}
                visible={this.props.show}
                onClose={() => this.props.onCallShow()}
                animationType="slide-up"
            >
                    <div
                        id='JJ_ShopCampaignShopModal'
                    >
                        {this._headView()}
                        <div className='contentBody'>
                            {this._packagesView()}
                            {this._tagsView()}
                            {this._amountView()}
                        </div>
                        {this._buttonView()}
                    </div>
            </Modal>
        );
    }
}



export default connector(injectIntl(JJ_ShopCampaignShopModal))




