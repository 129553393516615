import Type from './ActionType';
import api, { GetUserCouponListParameters, GetUserCouponRes, GetUserCouponParameters, PostUserCouponUsableParameters } from '../api';
import { MyThunkDispatch } from './action-interface';
import tool from '../tool';

const upDataAction = (data: ActionUserCouponRes) => {
    return {
        type: Type.userCouponType.UP_DATA,
        ...data
    }
}

const initDataAction = () => {
    return {
        type: Type.userCouponType.INIT_DATA
    }
}

interface ActionUserCouponListRes {
    [id: string]: GetUserCouponRes
}
export interface ActionUserCouponRes {
    list: ActionUserCouponListRes
}

export const getUserCouponList = (params: GetUserCouponListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getUserCouponList(params)
        if (res.kind === 'ok') {
            const userCoupon: ActionUserCouponRes = tool.store.getState().userCoupon
            res.data.data.forEach(item => {
                userCoupon.list[item.id] = item
            })
            dispatch(upDataAction(userCoupon))
        }
        return res
    }
}


export const postUserCouponUsable = (params: PostUserCouponUsableParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.postUserCouponUsable(params)
        if (res.kind === 'ok') {
            const userCoupon: ActionUserCouponRes = tool.store.getState().userCoupon
            res.data.data.forEach((item: GetUserCouponRes) => {
                userCoupon.list[item.id] = item
            })
            dispatch(upDataAction(userCoupon))
        }
        return res
    }
}
export const postUserCouponFeed = () => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.postUserCouponFeed()
        if (res.kind === 'ok') {
            const userCoupon: ActionUserCouponRes = tool.store.getState().userCoupon
            res.data.data.forEach((item: GetUserCouponRes) => {
                userCoupon.list[item.id] = item
            })
            dispatch(upDataAction(userCoupon))
        }
        return res
    }
}
export const getUserCoupon = (params: GetUserCouponParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getUserCoupon(params)
        if (res.kind === 'ok') {
            const userCoupon: ActionUserCouponRes = tool.store.getState().userCoupon
            userCoupon.list[res.data.id] = res.data
            dispatch(upDataAction(userCoupon))
        }
        return res
    }
}


export const initData = () => {
    return (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}












