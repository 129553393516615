import React from 'react';

import JJ_Components from '../../../components/JJ_Components'
import { GetShopCampaignRes } from '../../../api';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import tool from '../../../tool';
import ModalImg from '../../../components/Modal/ModalImg';




interface PageState {
    modalImg: {
        imgUrl?: string
        show: boolean
        key?: string
    }
}

export interface Props {

}

type PageProps = Props & GetShopCampaignRes & InjectedIntlProps


class JJ_ShopCampaignDetailBody extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
            modalImg: {
                show: false
            }
        }
    }


    /**
     * 详情
     */
    _details = () => {
        const baseHeadTitle = {
            title: tool.intl.formatMessage({ id: '详情' }),
        }
        const { descriptionImages } = this.props
        const images = descriptionImages.map(item => item.url)
        return (
            <div className='details'>
                <JJ_Components.baseHeadTitle {...baseHeadTitle} />
                {
                    descriptionImages.map((thumbnail, index) => {
                        const img = {
                            key: index,
                            src: thumbnail.url,
                            className: 'img',
                            style: tool.image.getImageStyle(thumbnail.meta, window.innerWidth, Number.MAX_SAFE_INTEGER)
                        }
                        return (
                            <img
                                onClick={() => {
                                    this.setState({
                                        modalImg: {
                                            ...this.state.modalImg,
                                            show: true,
                                            imgUrl: thumbnail.url,
                                        }
                                    })
                                }}
                                {...img}
                            />
                        )
                    })
                }
            </div>
        )

    }

    render() {
        return (
            <div id='JJ_ShopCampaignDetailBody'>
                {this._details()}
                {this.state.modalImg.imgUrl && (
                    <ModalImg
                        key={this.state.modalImg.key}
                        show={this.state.modalImg.show}
                        imgUrl={this.state.modalImg.imgUrl}
                        onClose={() => {
                            this.setState({
                                modalImg: { ...this.state.modalImg, show: false }
                            })
                        }}
                    />
                )}

            </div>
        );
    }
}

export default injectIntl(JJ_ShopCampaignDetailBody)



