import React from 'react'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import *as Ons from 'react-onsenui'
import tool from '../../tool';
import { AreaCodes } from '../../constants';

interface PageState {
    areaCode: string
    phone: string
}
export interface Props {
    areaCode?: string
    phone?: string
    showLeftIcon: boolean
    onData(data: { areaCode: string, phone: string }): void
    placeholder?: string
    title: string
}

type PageProps = Props
class JJ_PhoneInput extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {
            areaCode: props.areaCode || AreaCodes[0].value,
            phone: props.phone || ''
        }
    }



    componentDidMount() {

    }


    /**
     * 判断手机号码
     */
    _verificationPhone = () => {
        try {
            const areaCodeData = AreaCodes.find(item => item.value === this.state.areaCode)
            const phone = this.state.phone
            if (!areaCodeData) {
                throw new Error('找不到areaCodeData')
            }
            const phoneNumber = tool.phoneNumber.parseAndKeepRawInput(`${areaCodeData.value}-${phone}`)
            return tool.phoneNumber.isValidNumberForRegion(phoneNumber, areaCodeData.type)
        } catch (err) {
            console.error(err)
            return false
        }
    }


    /**
     * 右边完成按钮
     */
    _navRightBtn = () => {

        return (
            <Ons.ToolbarButton
                disabled={!this._verificationPhone()}
                onClick={() => {
                    this.props.onData({ phone: this.state.phone, areaCode: this.state.areaCode })
                    tool.navigator.popPage()
                }}
                style={{ color: 'white' }}
            >
                {tool.intl.formatMessage({ id: 'buttonComplete' })}
            </Ons.ToolbarButton>
        )
    }



    /**
     * 获取选择列表里面数据
     */
    _getSelectListItem = (value: string) => AreaCodes.find(item => item.value === value)



    _selectView = () => {
        return (
            <Ons.Select
                // size='6'
                className="select"
                value={this.state.areaCode}
                // modifier='material'
                modifier='transparent'
                onChange={e => this.setState({ areaCode: e.target.value })}>
                {AreaCodes.map((item, index) => <option key={index}
                    value={item.value}>{item.value}</option>)}
            </Ons.Select>
        )
    }

    _textInput = () => {
        return (
            <Ons.Input
                className='input'
                type='number'
                value={this.state.phone}
                float={false}
                onChange={(e) => this.setState({ phone: e.target.value })}
                modifier='transparent'
                placeholder={this.props.placeholder}
            />
        )
    }

    render() {
        const nav = {
            rightIcon: this._navRightBtn(),
            showLeftIcon: this.props.showLeftIcon,
            title: this.props.title,
        }
        return (
            <Ons.Page renderToolbar={() => <JJ_Nav {...nav} />}>
                <div id='JJ_PhoneInput'>
                    <div className='inputBox'>
                        {this._selectView()}
                        {this._textInput()}
                    </div>
                </div>
            </Ons.Page>
        );
    }

}


export default JJ_PhoneInput