import React from 'react'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import *as Ons from 'react-onsenui'
import tool from '../../tool'

interface PageState {
    value: string

}
export interface Props {
    type: 'text' | 'number' | 'textarea'
    title: string
    defaultInputValue?: string
    onData(value: string): void
    placeholder?: string
    textLength?: number
}

type PageProps = Props

class JJ_TextInput extends React.Component<PageProps, PageState> {
    input: any | null

    constructor(props: PageProps) {
        super(props);
        this.state = {
            value: props.defaultInputValue || '',
        }
    }


    componentDidMount() {

        this.input && this.input.focus()

    }

    _onData = () => {
        this.props.onData(this.state.value)
        tool.navigator.popPage()
    }



    /**
     * 右边完成按钮
     */
    _navRightBtn = () => {


        return (
            <Ons.ToolbarButton
                disabled={Boolean(this.state.value) && this.state.value === this.props.defaultInputValue}
                onClick={() => this._onData()}
                style={{ color: 'white' }}
            >
                {tool.intl.formatMessage({ id: 'buttonComplete' })}
            </Ons.ToolbarButton>
        )
    }


    /**
     * 编辑textInput
     */
    _editValue = (value: string) => {
        value.length < (this.props.textLength || 200) && this.setState({ value });
    }

    _textInput = () => {
        if (this.props.type === 'textarea') {
            return (
                <textarea
                    className="textarea input"
                    ref={ref => this.input = ref}
                    rows={4}
                    placeholder={this.props.placeholder}
                    onChange={(event) => this._editValue(event.target.value)}
                    value={this.state.value}
                />
            )
        } else {
            return (
                <input type={this.props.type}
                    className="text-input input"
                    ref={ref => this.input = ref}

                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    onChange={(event) => this._editValue(event.target.value)}
                />
            )
        }

    }

    render() {
        const nav = {
            rightIcon: this._navRightBtn(),
            showLeftIcon: true,
            title: this.props.title
        }
        return (
            <Ons.Page renderToolbar={() => <JJ_Nav {...nav} />}>
                <div id='JJ_TextInput'>
                    {this._textInput()}
                </div>
            </Ons.Page>
        );
    }

}


export default JJ_TextInput