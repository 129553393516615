import *as JJ_Request from '../JJ_Request'




/**
 * 获取地区
 * @returns {*|Promise<any>}
 */
export const getArea = ({...body}) => {
    const newBody = {
        type: 'COMMUNITY',
        count: 10000,
        ...body,
    }
    return JJ_Request.all([JJ_Request.get(JJ_Request.code.area, newBody)])
        .then(ary => ary[0].data)
}




/**
 * 获取某个地区自提柜
 * @param areaKioskId
 * @param body
 * @returns {*|Promise<any>}
 */
export const getAnAreaKiosk = (areaKioskId = '', body) => {
    const code = JJ_Request.code.area_kiosk + `/${areaKioskId}`
    const newBody = {
        ...body
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}



/*===============================功能模块===============================*/

/**
 * 获取功能模块
 */
export const getModule = ({
                              areaId = '',
                              from = 0,
                              count = 10,
                              ...body
                          }) => {
    const newBody = {
        areaId,
        ...body,
    }
    const code = JJ_Request.code.area_module
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}

