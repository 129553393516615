import React from 'react'
import queryString from 'query-string';
import { connect, ConnectedProps } from 'react-redux'
import JJ_ShopCampaignList from './PageItem/JJ_ShopCampaignList'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import { injectIntl, InjectedIntlProps } from "react-intl";
import { Modal, Tabs, Icon, Badge } from 'antd-mobile'
import { ActionAreaRes } from '../../actions/ActionArea'
import *as ActionCartCampaignPackage from '../../actions/ActionCartCampaignPackage';
import *as ActionModal from '../../actions/ActionModal'
import tool from '../../tool'
import api, { GetShopCartCampaignTagRes, GetShopCartCampaignTagParameters, GetAdvertRes, GetAdvertListParameters, GetShopCartCampaignPackageListParameters } from '../../api'
import BoxPage from '../../components/Box/BoxPage'
import menuPng from '../../imgs/menu.png'
import JJ_Modal from './PageItem/JJ_Modal'
import JJ_Components from '../../components/JJ_Components'
import config from '../../config/config'
import { FaShoppingCart } from 'react-icons/fa'
import { FiSearch } from 'react-icons/fi'


interface PageState {
    tagsData: GetShopCartCampaignTagRes[]
    selectedTagId: string
    index: number
    showServicePrompt: boolean
    adverts?: GetAdvertRes[]

}


export interface Props {
    tags: string[]
    moduleId: string
    showMenu?(): void

}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>




interface RootState {
    area: ActionAreaRes
    cartCampaignPackage: ActionCartCampaignPackage.ActionCartCampaignPackageRes
}

const mapState = (state: RootState) => ({
    areaData: state.area,
    cartCampaignPackage: state.cartCampaignPackage,
})

const mapDispatch = {
    showUserCouponFeedModal: ActionModal.showUserCouponFeedModal,
    getShopCartCampaignPackageList: ActionCartCampaignPackage.getShopCartCampaignPackageList,
}

export const connector = connect(
    mapState,
    mapDispatch
)


class JJ_ShopCampaign extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {
            tagsData: [],
            index: 0,
            showServicePrompt: false,
            selectedTagId: '',
        }
    }


    componentDidMount() {
        this._loadServicePrompt()
        this._localStoragePushAction()
        tool.intl.getIntl(this.props.intl)
        this.props.showUserCouponFeedModal()

    }

    _initData = async () => {
        try {
            const tagsData: GetShopCartCampaignTagRes[] = [];

            const [_, getAdvertListRes] = await Promise.all([
                Promise.all(this.props.tags.map(id => {
                    return this._getShopCartCampaignTag({ id })
                        .then(tagRes => tagsData.push(tagRes))
                        .catch(err => {});
                })),
                this._getAdvertList({
                    position: `home:top:${this.props.moduleId}`,
                    count: 1000,
                }),
            ]);

            const getShopCartCampaignPackageListRes = await this._getShopCartCampaignPackageList({ count: 100000 })

            this.setState({
                tagsData,
                adverts: getAdvertListRes.totalCount > 0 ? getAdvertListRes.data : undefined
            })
            return tagsData
        } catch (error) {
            throw new Error(error)

        }

    }


    _getShopCartCampaignPackageList = async (params: GetShopCartCampaignPackageListParameters) => {
        const res = await this.props.getShopCartCampaignPackageList(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }
    }


    /**
    * 过滤商城活动购物车
    */
    _filterShopCartCampaignList = () => {
        const list = Object.keys(this.props.cartCampaignPackage.list)
            .map(key => this.props.cartCampaignPackage.list[key])
        return list
    }


    /**
     * 获取活动标签数据
     */
    _getShopCartCampaignTag = async (params: GetShopCartCampaignTagParameters) => {

        const res = await api.getShopCartCampaignTag(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }

    }

    _getAdvertList = async (params: GetAdvertListParameters) => {

        const res = await api.getAdvertList(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            // switch (res.kind) {
            //     case 'INVALID_PARAMS': {

            //     }
            // }
            // throw new Error(res.kind)
            return {
                data: [],
                totalCount: 0,
            }
        }

    }



    _localStoragePushAction = () => {
        const pushActionRes = tool.storage.getItem('pushAction')
        console.log('_localStoragePushAction', pushActionRes)
        if (pushActionRes) {
            switch (pushActionRes.key) {
                case 'pushCampaignDetails': {

                    tool.navigator.pushPage({
                        key: 'JJ_ShopCampaignDetails',
                        props: { id: pushActionRes.data.id, moduleId: this.props.moduleId }
                    })

                }
                    break
                case 'pushShopOrderDetails': {

                    tool.navigator.pushPage({
                        key: 'JJ_ShopOrderDetails',
                        props: { orderId: pushActionRes.data.id, moduleId: this.props.moduleId }
                    })
                }
                    break
            }
        }
        tool.storage.removeItem('pushAction')
    }

    _renderHeader = (adverts: GetAdvertRes[]) => {
        return (
            <div>
                <JJ_Components.baseBannerAdvert
                    style={{ position: 'relative', minHeight: 150 }}
                    adverts={adverts}
                    autoplay={true}
                    infinite={true}
                    dots={true}
                    autoplayInterval={5000}
                    onClick={e => {
                        if (e.link) {
                            if (e.link.match(/^welink:\/\//)) {
                                console.log('Welink clicked: ' + e.link);

                                const parts = e.link.substr(9).split('/').map(val => val.split(':'));

                                switch (parts[0][0]) {
                                    case 'shop': {
                                        // skip checking moduleId

                                        if (parts[1]) {
                                            switch (parts[1][0]) {
                                                case 'campaign': {
                                                    console.log('Opening campaign: ' + parts[1][1]);

                                                    tool.navigator.pushPage({
                                                        key: 'JJ_ShopCampaignDetails',
                                                        props: { id: parts[1][1], moduleId: this.props.moduleId }
                                                    })
                                                    break;
                                                }
                                            }
                                        }

                                        break;
                                    }
                                }

                            } else if (e.link.match(/^https?:\/\//)) {
                                window.open(e.link)
                            } else {
                                console.log('Link clicked: ' + e.link)
                            }
                        }
                    }}
                />
                <div style={{
                    height: 2,
                    backgroundColor: 'rgba(114, 197, 197, 0.658)'
                }}
                />
            </div>

        )
    }

    _renderContent = (tagData: GetShopCartCampaignTagRes) => {
        return (
            <JJ_ShopCampaignList
                renderHeader={this.state.adverts ? () => this.state.adverts ? this._renderHeader(this.state.adverts) : <div /> : undefined}
                tagName={tagData.name}
                moduleId={this.props.moduleId}
                tag={tagData.id}
            />
        )
    }


    _view = () => {
        const tags = [
            // {
            //     title: tool.intl.formatMessage({ id: 'shopCampaignTagAll' }),
            //     name: tool.intl.formatMessage({ id: 'shopCampaignTagAll' }),
            //     id: undefined,
            // },
            ...this.state.tagsData.map((item: { name: any, id: string }) => {
                return {
                    ...item,
                    title: item.name,
                    key: item.id,
                }
            }),
        ]
        // return (
        //     <div
        //         id='JJ_ShopCampaign'
        //     >
        //         {this._renderContent({
        //             id: '',
        //             isVisible: true,
        //             name: 'string'
        //         })}
        //     </div>
        // )
        // 是否启动Tabs？
        return (
            <div
                id='JJ_ShopCampaign'
            >
                <Tabs
                    tabs={tags}
                    renderTabBar={props => <Tabs.DefaultTabBar {...props} page={3.5} />}
                    page={this.state.selectedTagId}
                    onChange={(tab: any) => this.setState({
                        selectedTagId: tab.key,
                    })}
                    swipeable={false}
                >
                    {this._renderContent}
                </Tabs>
            </div>
        )
    }

    /**
     * 加载服务提示
     * 第一次加载时会弹出提示警告
     */
    _loadServicePrompt = () => {

        // check webview
        const urlParams = queryString.parse(location.search);
        const isWebview = urlParams.view === 'webview';

        if (isWebview) {
            return;
        }

        const storage = window.localStorage
        const key = 'servicePrompt'

        if (storage[key]) {
            return
        }

        this.setState({ showServicePrompt: true })
        storage[key] = 'servicePrompt'
    
    }

    render() {
        const nav = {
            showLeftIcon: false,
            leftIcon: this.state.tagsData.length ? (
                <Ons.Button
                    onClick={() => tool.navigator.pushPage({
                        key: 'JJ_ShopTagMenu',
                        props: {
                            tagsData: this.state.tagsData,
                            onChange: (selectedTagId: string) => {
                                this.setState({
                                    selectedTagId,
                                })
                            }
                        }
                    })}
                    className='btn'
                >
                    {tool.intl.formatMessage({ id: 'JJ_ShopCampaign[tagMenuButton]' })}
                </Ons.Button>
            ) : null,
            rightIcon: (

                <img
                    style={{
                        height: 25,
                        width: 25,
                        padding: 10
                    }}
                    src={menuPng}
                    onClick={() => this.props.showMenu && this.props.showMenu()}

                />

            ),
            sub: (
                <div
                    id="JJ_ShopCampaignPageSearchbar"
                    onClick={() => {
                        tool.navigator.pushPage({
                            key: 'JJ_ShopCampaignSearch',
                            props: {
                                moduleId: this.props.moduleId,
                            },
                        })
                    }}
                >
                    <FiSearch className='icon' />
                    <div className='input'>{tool.intl.formatMessage({ id: 'shopCampaignSearchInputPlaceholder' })}</div>
                </div>
            ),
        }
        return (
            <Ons.Page
                id="JJ_ShopCampaignPage"
                renderToolbar={() => <JJ_Nav {...nav} />}
            >
                <BoxPage
                    loadData={this._initData}
                >

                    {this._view()}
                    <Modal
                        visible={this.state.showServicePrompt}
                        onClose={() => this.setState({ showServicePrompt: false })}
                        transparent
                        style={{
                            width: '90vw',
                        }}
                        title={config.servicePrompt.title[this.props.intl.locale]}
                        animationType="fade"
                        footer={[{
                            text: 'Ok', onPress: () => {
                                this.setState({ showServicePrompt: false })
                            }
                        }]}
                    >
                        <div
                            id='JJ_ShopCampaignServicePrompt'
                        >
                            <img
                                src={config.servicePrompt.img[this.props.intl.locale]}
                            />
                            <span>
                                {config.servicePrompt.details[this.props.intl.locale]}
                            </span>
                        </div>
                    </Modal>
                    <JJ_Modal />
                </BoxPage>

                {this._filterShopCartCampaignList().length > 0 && (
                    <div id="JJ_ShopCampaignBottomContainer">
                        <BoxPage
                            loadData={async () => {}}
                        >
                            <Ons.Button
                                className='btn'
                                onClick={() => tool.navigator.pushPage({ key: 'JJ_ShopCartCampaignPackage' })}
                            >
                                <FaShoppingCart className='icon' />
                                {tool.intl.formatMessage({ id: 'buttonViewCart' })}
                                <Badge className='badge' text={this._filterShopCartCampaignList().length || 0} />
                            </Ons.Button>
                        </BoxPage>
                    </div>
                )}
            </Ons.Page>


        )

    }
}


export default connector(injectIntl(JJ_ShopCampaign))



