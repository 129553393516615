import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import JJ_Components from '../../../components/JJ_Components'
import JJ_UserDeliveryListItem from './JJ_UserDeliveryListItem'
import *as Ons from 'react-onsenui'
import { ShopDeliveryType, GetShopDeliveryListRes, GetShopDeliveryRes } from '../../../api/api-res-type';
import *as ActionShopDelivery from '../../../actions/ActionShopDelivery'
import { GetShopDeliveryListParameters } from '../../../api';

interface PageState {
    deliveryList?: GetShopDeliveryListRes
}

export interface Props {
    moduleId: string
    onSelect(
        params: {
            type: ShopDeliveryType
            deliveryId: string
            deliveryAddressId?: string
            deliveryRequestedAt?: string
        }
    ): void
    deliveryId: string
    deliveryAddressId?: string
    deliveryRequestedAt?: string
    amount: number
}


type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>



interface RootState {
    shopDelivery: ActionShopDelivery.ActionShopDeliveryRes,
}

const mapState = (state: RootState) => ({
    shopDelivery: state.shopDelivery,
})

const mapDispatch = {
    getShopDeliveryList: ActionShopDelivery.getShopDeliveryList,
}


export const connector = connect(
    mapState,
    mapDispatch
)


class JJ_UserDeliveryList extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {

        }
    }




    _initData = async () => {
        try {
            const deliveryList = await this._getShopDeliveryList({
                moduleId: this.props.moduleId,
                count: 1000,
            })
            this.setState({ deliveryList })
        } catch (error) {
            throw error
        }
    }


    _getShopDeliveryList = async (params: GetShopDeliveryListParameters) => {
        const res = await this.props.getShopDeliveryList(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
                break
        }
        throw new Error(res.kind)
    }


    _renderRow = (rowData: GetShopDeliveryRes) => {

        return (
            <JJ_UserDeliveryListItem
                key = {rowData.id}
                delivery={rowData}
                deliveryAddressId={this.props.deliveryAddressId}
                deliveryRequestedAt={this.props.deliveryRequestedAt}
                onSelect={(props) => {
                    this.props.onSelect({
                        type: props.delivery.type,
                        deliveryId: props.delivery.id,
                        deliveryAddressId: props.deliveryAddressId,
                        deliveryRequestedAt: props.deliveryRequestedAt,
                    })
                }}
                amount={this.props.amount}
                checked={this.props.deliveryId === rowData.id}
            />
        )
    }

    _view = (deliveryList: GetShopDeliveryListRes) => {
        return (
            <Ons.List
                dataSource={deliveryList.data}
                renderRow={this._renderRow}
                id='JJ_UserDeliveryList'
            />
        )
    }

    render() {

        return (
            <JJ_Components.boxPage
                loadData={this._initData}
            >
                {this.state.deliveryList && this._view(this.state.deliveryList)}
            </JJ_Components.boxPage>

        )
    }
}



export default connector(JJ_UserDeliveryList)
