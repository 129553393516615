import *as JJ_Request from '../JJ_Request'







/**
 * 登出
 * @returns {*|Promise<any>}
 */
export const deleteAuth = () => {
    const code = JJ_Request.code.auth
    return JJ_Request.all([JJ_Request.deleteMy(code)])
        .then(ary => ary[0].data)
}


/**
 * 区域授权
 */
export const postAuthArea = ({
                                 areaId = '',
                                 lang = '',
                                 ...body,
                             }) => {
    const newBody = {
        areaId,
        lang,
        ...body,
    }
    const code = JJ_Request.code.auth_session_request_new
    return JJ_Request.all([JJ_Request.post(code, newBody)])
        .then(ary => ary[0].data)
}


/**
 * 订单人id授权
 */
export const postAuthOrderHumanId = ({
                                         orderHumanId = '',
                                         ...body,
                                     }) => {
    const newBody = {
        orderHumanId,
        ...body,
    }
    const code = JJ_Request.code.auth_session_verify
    return JJ_Request.all([JJ_Request.post(code, newBody)])
        .then(ary => ary[0].data)
}

