import React from 'react';
import queryString from 'query-string';
import { Modal, ActivityIndicator } from 'antd-mobile'
import *as Ons from 'react-onsenui'
import api, { GetPaymentListParameters, GetWalletListParameters, GetPaymentRes } from '../../api';
import { FaQuestion } from 'react-icons/fa';
import tool from '../../tool';
import BoxNoDataPage from '../Box/BoxNoDataPage';

import paymentImageAlipayHk from '../../imgs/payment-logo-alipay-hk.png';
import paymentImageAlipayCn from '../../imgs/payment-logo-alipay-hk.png';
import paymentImageCreditCard from '../../imgs/payment-logo-credit-card.png';
import paymentImageStripeApplePay from '../../imgs/payment-logo-apple-pay.png';

interface PageState {
    paymentList?: GetPaymentRes[]
    selectIndex: number
    loadType: 'load' | 'error' | 'normal'
    walletBalance: number
}

export interface Props {
    show: boolean
    amount: number
    onClose(): void
    onPay(payment: GetPaymentRes): void
}

type PageProps = Props



class ModalPayment extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
            loadType: 'load',
            selectIndex: 0,
            walletBalance: 0,
        }
    }


    componentWillReceiveProps(newProps: PageProps) {
        if (newProps.show !== this.props.show) {
            if (newProps.show) {
                this._initData(newProps)
            }
        }
    }

    _initData = async (props: PageProps) => {
        try {
            this.setState({ loadType: 'load' })
            const paymentListRes = await this._getPaymentList({ count: 1000, amount: props.amount })

            if (paymentListRes.data.some(payment => payment.customId === 'WALLET')) {
                const wallet = await this._getWalletList({ count: 1000, userId: 'me' })
                this.setState({
                    walletBalance: wallet.data[0].balance,
                })
            }

            const paymentsNew = paymentListRes.data;

            // check apple pay
            const urlParams = queryString.parse(location.search);
            if (urlParams.applePay && Number(urlParams.applePay) === 1) {
                const paymentStripe = paymentsNew.find(payment => payment.provider === 'STRIPE' && payment.isEnabled);
                const paymentStripeIndex = paymentsNew.findIndex(payment => payment.provider === 'STRIPE' && payment.isEnabled);

                if (paymentStripe) {
                    paymentsNew.splice(paymentStripeIndex, 0, {
                        ...paymentStripe,
                        id: `${paymentStripe.id}_APPLE_PAY`,
                        name: 'Apple Pay',
                        customId: 'STRIPE_APPLE_PAY',
                    });
                }
            }

            this.setState({
                loadType: 'normal',
                paymentList: paymentsNew,
            })
        } catch (error) {
            this.setState({ loadType: 'error' })
            console.error(error)
        }
    }

    _getWalletList = async (params: GetWalletListParameters) => {
        const res = await api.getWalletList(params)

        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {
                }
                    break

            }
            throw new Error(res.kind)
        }
    }


    _getPaymentList = async (params: GetPaymentListParameters) => {
        const res = await api.getPaymentList(params)
        if (res.kind === 'ok') {
            return res.data
        }
        switch (res.kind) {
            case 'INVALID_PARAMS': {

            }
        }
        throw new Error(res.kind)
    }

    render() {
        const {walletBalance} = this.state;

        const loadView = () => {
            return (
                <div className='loadView'>
                    <ActivityIndicator
                        animating={true}
                    />

                </div>
            )
        }

        const normalView = () => {

            if (this.state.paymentList) {

                const renderRow = (payment: GetPaymentRes, index: number) => {

                    const calculatedAdditionalAmount = payment.calculatedAdditionalAmount || 0
                    const amount = this.props.amount + calculatedAdditionalAmount

                    let image: string = '';
                    switch (payment.customId) {
                        case 'ALIPAY_HK': {
                            image = paymentImageAlipayHk;
                            break;
                        }
                        case 'ALIPAY_CN': {
                            image = paymentImageAlipayCn;
                            break;
                        }
                        case 'CREDIT_CARD': {
                            image = paymentImageCreditCard;
                            break;
                        }
                        case 'STRIPE_APPLE_PAY': {
                            image = paymentImageStripeApplePay;
                            break;
                        }
                    }

                    return (
                        <Ons.ListItem
                            className={'paymentItem'}
                            key={payment.id}
                            onClick={() => {
                                if (payment.customId === 'WALLET' && amount !== undefined && walletBalance < amount) {
                                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[WALLET_NOT_ENOUGHT_BALANCE]' }) })
                                } else if (payment.provider === 'STRIPE' && amount !== undefined && amount < 40000) {
                                    tool.dialog.toast({ content: tool.intl.formatMessage({ id: 'shopOrderDetailsErrorCode[STRIPE_AMOUNT_TOO_LOW]' }) })
                                } else {
                                    this.setState({ selectIndex: index });
                                }
                            }}
                            modifier={'longdivider'}
                            tappable
                        >
                            <div className="left">
                                <Ons.Radio
                                    inputId={`radio-${index}`}
                                    checked={index === this.state.selectIndex}
                                    disabled={(payment.customId === 'WALLET' && amount !== undefined && walletBalance < amount)
                                        || (payment.provider === 'STRIPE' && amount !== undefined && amount < 40000)}
                                />
                            </div>
                            <div className="center">

                                <div
                                    className='centerBoxRigthBox'
                                >
                                    <span
                                        className='name'
                                    >
                                        {payment.name}
                                        {payment.customId === 'WALLET' && (
                                            <span style={{ marginLeft: 8 }}>({tool.intl.formatMessage({ id: 'ModalPayment[walletBalanceLabel]' })}: ${tool.math.initPrice({ price: walletBalance }).toFixed(2)})</span>
                                        )}
                                    </span>
                                    <span
                                        className='description'
                                    >
                                        {payment.description}
                                    </span>
                                    {(payment.calculatedAdditionalAmount !== undefined && payment.calculatedAdditionalAmount > 0) && (
                                        <span
                                            className='calculatedAdditionalAmount'
                                        >
                                            ${tool.math.initPrice({ price: payment.calculatedAdditionalAmount }).toFixed(2)}
                                        </span>
                                    )}
                                </div>
                            </div>
                            {image && (
                                <div className="right">
                                    <img src={image} />
                                </div>
                            )}
                        </Ons.ListItem>
                    )
                }

                const calculatedAdditionalAmount = this.state.paymentList[this.state.selectIndex].calculatedAdditionalAmount || 0
                const amount = this.props.amount + calculatedAdditionalAmount

                return (
                    <div className='normalView'>
                        <h1 className='title'>{tool.intl.formatMessage({ id: 'ModalPayment[title]' })}</h1>
                        <Ons.List
                            className='paymentList'
                            dataSource={this.state.paymentList}
                            renderRow={renderRow}
                        />

                        <div className='paymentButtonContainer'>
                            <Ons.Button
                                className={'paymentButton'}
                                disabled={this.state.selectIndex === undefined}
                                modifier={'large'}
                                onClick={() => {
                                    this.state.paymentList && this.props.onPay(this.state.paymentList[this.state.selectIndex])
                                }}
                            >
                                {tool.intl.formatMessage({ id: 'ModalPayment[paymentButton]' })} (${tool.math.initPrice({ price: amount }).toFixed(2)})
                            </Ons.Button>
                        </div>

                    </div >
                )
            }

            return (
                <BoxNoDataPage
                    title={'没有数据'}
                    onClick={() => this._initData(this.props)}
                />
            )

        }

        const errorView = () => {
            return (
                <div className='errorView'>
                    <FaQuestion className='icon' />
                    <div>{tool.intl.formatMessage({ id: 'pageErrorNotLoadMessage' })}</div>
                    <Ons.Button
                        onClick={() => this._initData(this.props)}
                        className='btn'
                    >
                        {tool.intl.formatMessage({ id: 'boxPageButtonRefresh' })}
                    </Ons.Button>
                </div>
            )
        }

        return (
            <Modal
                popup={true}
                visible={this.props.show}
                onClose={() => this.props.onClose()}
                animationType="slide-up"
            >
                <div
                    id='ModalPayment'
                >
                    {this.state.loadType === 'error' && errorView()}
                    {this.state.loadType === 'normal' && normalView()}
                    {this.state.loadType === 'load' && loadView()}
                </div>
            </Modal>
        );
    }
}

export default ModalPayment






