import ActionType from './ActionType'
import *as ActionArea from './ActionArea'
import tool from '../tool'
import { MyThunkDispatch } from './action-interface'
import api, { GetUserRes, LangReq } from '../api'
import config from '../config/config'



const getDataAction = (data: any) => {
    return {
        type: ActionType.userType.GET_DATA,
        ...data
    }
}



const initDataAction = () => {
    return {
        type: ActionType.userType.INIT_DATA,
    }
}



export interface ActionUserRes extends GetUserRes {
    lang: LangReq
}

/**
 * 获取用户数据
 */
export const getUser = () => {

    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getUser({ userId: 'me' })
        if (res.kind === 'ok') {
            dispatch(getDataAction(res.data))
        }
        return res
    }
}


/**
 * 获取用户设置数据
 */
export const getUserSettingData = () => {

    return async (dispatch: MyThunkDispatch) => {

        const res = await api.getUserSettingList()
        if (res.kind === 'ok') {
            const userSetingData = res.data
            dispatch(getDataAction(userSetingData))

        }
        return res
    }
}



/**
 * 退出登录
 */
export const authOut = () => {


    return async (dispatch: MyThunkDispatch) => {

        const res = await api.deleteAuth()
        if (res.kind === 'ok') {
            dispatch(initData())
        }
        const getFirstItemRes = tool.storage.getFirstItem('first')
        if (getFirstItemRes) {
            tool.navigator.resetPage({ key: 'JJ_Login' })
        } else {
            config.guides.length >= 1
                ? tool.navigator.resetPage({ key: 'JJ_Guide', props: { guides: config.guides } })
                : tool.navigator.resetPage({ key: 'JJ_Login' })
        }
        return res
    }


    return async (dispatch: MyThunkDispatch) => {

        const res = await api.deleteAuth()
        if (res.kind === 'ok') {
            dispatch(initData())
        }

        const areaRes = await api.getAreaList({ type: 'COMMUNITY', count: 10000 })

        if (areaRes.kind === 'ok' && areaRes.data.data.length === 1) {
            const areaId = areaRes.data.data[0].id

            await api.postAuthSessionRequestNew({
                areaId,
                lang: 'zh-tw',
            })
            if (res.kind === 'ok') {
                tool.navigator.resetPage({ key: 'JJ_Loading' }, { animation: 'node' })
                return res
            }
        } else {
            const getFirstItemRes = tool.storage.getFirstItem('first')
            if (getFirstItemRes) {
                tool.navigator.resetPage({ key: 'JJ_Login' })
            } else {
                config.guides.length >= 1
                    ? tool.navigator.resetPage({ key: 'JJ_Guide', props: { guides: config.guides } })
                    : tool.navigator.resetPage({ key: 'JJ_Login' })
            }
        }

        return res
    }
}

/**
 * 初始化
 */
export const initData = () => {

    return (dispatch: MyThunkDispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(initDataAction())
            resolve()
        })
    }
}





