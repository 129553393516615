import React from 'react'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import tool from '../../tool'
import { GetShopCartCampaignTagRes } from '../../api'


interface PageState {

}


export interface Props {
    tagsData: GetShopCartCampaignTagRes[]
    onChange(id: string): void
}

type PageProps = Props & InjectedIntlProps



class JJ_ShopTagMenu extends React.Component<PageProps, PageState>{

    constructor(props: PageProps) {
        super(props);
    }

    static defaultProps = {

    };


    componentDidMount() {
        console.log('JJ_ShopTagMenu', this.props)

    }

    _buttonView() {
        return this.props.tagsData.map((tag) => {
            return (
                <div>
                    <div>
                        <Ons.Button
                            key={tag.id}
                            className='btn'
                            modifier='large'
                            style={{ backgroundColor: `#${tag.color}` }}
                            onClick={() => {
                                tool.navigator.popPage()
                                this.props.onChange(tag.id)
                            }}
                        >
                            {tag.name}
                        </Ons.Button>
                    </div>
                </div>
            )
        })
    }

    render() {

        const nav = {
            showLeftIcon: true,
            title: tool.intl.formatMessage({ id: 'JJ_ShopTagMenu[title]' }),
        }

        return (

            <Ons.Page
                renderToolbar={() => <JJ_Nav {...nav} />}
            >

                <div
                    id='JJ_ShopTagMenu'
                >
                    <div className='contentView'>
                        {this._buttonView()}
                    </div>
                </div>

            </Ons.Page>


        )

    }
}

export default injectIntl(JJ_ShopTagMenu)


