import *as JJ_Request from '../JJ_Request'


/*===========================用戶收貨地址===========================*/
/**
 * 获取列表数据
 */
export const getUserShopDeliveryAddressList = ({
                                                   from = 0,
                                                   count = 1000,
                                                   ...body
                                               }) => {
    const code = JJ_Request.code.user_shop_delivery_address
    const newBody = {
        from,
        count,
        ...body
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}

/**
 * 获取数据
 */
export const getUserShopDeliveryAddress = ({
                                               userDeliveryAddressId = '',
                                           }) => {
    const code = `${JJ_Request.code.user_shop_delivery_address}/${userDeliveryAddressId}`
    return JJ_Request.all([JJ_Request.get(code)])
        .then(ary => ary[0].data)
}
/**
 * 增加数据
 */
export const postUserShopDeliveryAddress = ({
                                                address = '',
                                                isDefault = false,
                                                ...body
                                            }) => {
    const code = JJ_Request.code.user_shop_delivery_address
    const newBody = {
        address,
        isDefault,
        ...body
    }
    return JJ_Request.all([JJ_Request.post(code, newBody)])
        .then(ary => ary[0].data)
}
/**
 * 修改数据
 */
export const putUserShopDeliveryAddress = ({
                                               address = '',
                                               isDefault = false,
                                               userDeliveryAddressId = '',
                                               ...body
                                           }) => {
    const code = JJ_Request.code.user_shop_delivery_address + `/${userDeliveryAddressId}`
    const newBody = {
        address,
        isDefault,
        ...body
    }
    return JJ_Request.all([JJ_Request.put(code, newBody)])
        .then(ary => ary[0].data)
}
/**
 * 删除数据
 */
export const deleteUserShopDeliveryAddress = ({
                                                  userDeliveryAddressId
                                              }) => {
    const code = JJ_Request.code.user_shop_delivery_address + `/${userDeliveryAddressId}`
    return JJ_Request.all([JJ_Request.deleteMy(code)])
        .then(ary => ary[0].data)
}

/*===========================用戶收貨人記錄===========================*/
/**
 * 获取列表数据
 */
export const getUserShopDeliveryRecipientList = ({
                                                     from = 0,
                                                     count = 1000,
                                                     onlyDefault = false,
                                                     ...body
                                                 }) => {
    const code = JJ_Request.code.user_shop_delivery_recipient
    const newBody = {
        from,
        count,
        onlyDefault,
        ...body
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}

/**
 * 获取数据
 */
export const getUserShopDeliveryRecipient = ({
                                                 deliveryRecipientId = '',
                                             }) => {
    const code = `${JJ_Request.code.user_shop_delivery_recipient}/${deliveryRecipientId}`
    return JJ_Request.all([JJ_Request.get(code)])
        .then(ary => ary[0].data)
}
/**
 * 增加数据
 */
export const postUserShopDeliveryRecipient = ({
                                                  recipientName = '',
                                                  recipientPhone = '',
                                                  isDefault = false,
                                                  ...body
                                              }) => {
    const code = JJ_Request.code.user_shop_delivery_recipient
    const newBody = {
        recipientName,
        recipientPhone,
        isDefault,
        ...body
    }
    return JJ_Request.all([JJ_Request.post(code, newBody)])
        .then(ary => ary[0].data)
}
/**
 * 修改数据
 */
export const putUserShopDeliveryRecipient = ({
                                                 recipientName = '',
                                                 recipientPhone = '',
                                                 isDefault = false,
                                                 deliveryRecipientId = '',
                                                 ...body
                                             }) => {
    const code = JJ_Request.code.user_shop_delivery_recipient + `/${deliveryRecipientId}`
    const newBody = {
        recipientName,
        recipientPhone,
        isDefault,
        ...body
    }
    return JJ_Request.all([JJ_Request.put(code, newBody)])
        .then(ary => ary[0].data)
}
/**
 * 删除数据
 */
export const deletegetUserShopDeliveryRecipient = ({
                                                       deliveryRecipientId
                                                   }) => {
    const code = JJ_Request.code.user_shop_delivery_recipient + `/${deliveryRecipientId}`
    return JJ_Request.all([JJ_Request.deleteMy(code)])
        .then(ary => ary[0].data)
}


/*===========================送货方式===========================*/
/**
 * 获取列表数据
 */
export const getShopDeliveryList = ({
                                        from = 0,
                                        count = 1000,
                                        moduleId = '',
                                        onlyEnabled = true,//仅启动
                                        ...body
                                    }) => {
    const code = JJ_Request.code.shop_delivery
    const newBody = {
        from,
        count,
        onlyEnabled,
        moduleId,
        ...body
    }
    return JJ_Request.all([JJ_Request.get(code, newBody)])
        .then(ary => ary[0].data)
}

/**
 * 获取数据
 */
export const getShopDelivery = ({
                                    deliveryId = '',
                                }) => {
    const code = `${JJ_Request.code.shop_delivery}/${deliveryId}`
    return JJ_Request.all([JJ_Request.get(code)])
        .then(ary => ary[0].data)
}
