import React from 'react'

class BaseImage extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    static defaultProps = {
        src:'',
        className:'',
        id:'',
        onClick:(e)=>{}
    };



    render() {
        return (
            <img onClick={(e)=>this.props.onClick(e)} style={this.props.style} className={this.props.className} id = {this.props.id} src={this.props.src}/>
        );
    }

}

export default BaseImage
