import Type from './ActionType';
import api, {
    GetUserShopDeliveryRecipientRes,
    GetUserShopDeliveryRecipientParameters,
    GetUserShopDeliveryRecipientListParameters,
    GetShopDeliveryListParameters,
    GetShopDeliveryRes,
    GetShopDeliveryParameters,
} from '../api';
import { MyThunkDispatch } from './action-interface';
import tool from '../tool';

const upDataAction = (data: ActionShopDeliveryRes) => {
    return {
        type: Type.shopDeliveryType.UP_DATA,
        ...data
    }
}

const initDataAction = () => {
    return {
        type: Type.shopDeliveryType.INIT_DATA
    }
}

interface ActionShopDeliveryListRes {
    [id: string]: GetShopDeliveryRes
}
export interface ActionShopDeliveryRes {
    list: ActionShopDeliveryListRes
}

export const getShopDeliveryList = (params: GetShopDeliveryListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getShopDeliveryList(params)

        if (res.kind === 'ok') {
            const shopDelivery: ActionShopDeliveryRes = tool.store.getState().shopDelivery
            res.data.data.forEach(item => {
                shopDelivery.list[item.id] = item
            })
            dispatch(upDataAction(shopDelivery))
        }
        return res
    }
}
export const getShopDelivery = (params: GetShopDeliveryParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getShopDelivery(params)
        if (res.kind === 'ok') {
            const shopDelivery: ActionShopDeliveryRes = tool.store.getState().shopDelivery
            shopDelivery.list[res.data.id] = res.data
            dispatch(upDataAction(shopDelivery))
        }
        return res
    }
}

export const initData = () => {
    return (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}













