import React from 'react'
import *as Ons from 'react-onsenui'
import JJ_Components from '../../components/JJ_Components'
import { Guide } from '../../config/config.type';
import tool from '../../tool';
import { ConnectedProps, connect } from 'react-redux';
import *as ActionUser from '../../actions/ActionUser'
import JJ_Nav from '../SuperComponent/JJ_Nav';
import { Carousel, CarouselItem } from 'react-onsenui';

interface PageState {
    index: number
}


export interface Props {
    guides: Guide[]
}

type PageProps = Props & PropsFromRedux


interface Page {
}


type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    user: ActionUser.ActionUserRes

}

const mapState = (state: RootState) => ({
    user: state.user,
})

const mapDispatch = {

}


export const connector = connect(
    mapState,
    mapDispatch
)



class JJ_Guide extends React.Component<PageProps, PageState> implements Page {
    constructor(props: PageProps) {
        super(props);
        this.state = {
            index: 0
        }
    }
    componentDidMount() {
        tool.storage.setFirstItem({ key: 'first', data: true })
    }

    /**
     * 初始化数据
     */
    _initData = async () => {

    }

    _view = (guides: Guide[]) => {
        return (
            <div
                id='JJ_Guide'
            >
                {this._guideView(guides)}
                <Ons.Button
                    // modifier='quiet'
                    modifier='material--flat'
                    className='topButton'
                    onClick={() => {
                        tool.navigator.resetPage({
                            key: 'JJ_Login'
                        })
                    }}
                >
                    {tool.intl.formatMessage({ id: 'JJ_Guide[topButton]' })}
                </Ons.Button>
            </div>
        )
    }

    handleChange = (e) => {
        console.log(1111, e)
        this.setState({ index: e.activeIndex });
    }

    _guideView = (guides: Guide[]) => {
        const lang = this.props.user.lang
        console.log('guides', guides)
        return (
            <div
                className='guideView'
            >
                <Carousel
                    onPostChange={(this.handleChange as any)}
                    index={this.state.index}
                    fullscreen
                    swipeable
                    autoScroll
                    overscrollable
                >
                    {guides.map((guide, index) => {

                        return (
                            <CarouselItem
                                key={index}
                                className='guideItemView'
                            >
                                <div
                                    className='imgBox'
                                >
                                    <img
                                        src={guide.img[lang]}
                                        className='img'
                                    />
                                </div>

                                <span
                                    className='title'
                                >
                                    {guide.title[lang]}
                                </span>
                                <span
                                    className='details'
                                >
                                    {guide.details[lang]}
                                </span>
                                {index === (guides.length - 1) && (
                                    <Ons.Button
                                        modifier='large material'
                                        className='footButton'
                                        onClick={() => {
                                            tool.navigator.resetPage({
                                                key: 'JJ_Login'
                                            })
                                        }}
                                    >
                                        {tool.intl.formatMessage({ id: 'JJ_Guide[footButton]' })}
                                    </Ons.Button>
                                )}
                            </CarouselItem>
                        )
                    })}
                </Carousel>


                <div
                    className='pointView'
                >
                    {guides.map((item, index) => (
                        <div
                            key={index}
                            className={`${this.state.index === index ? 'pointItemLight' : 'pointItem'}`}
                            onClick={() => this.setState({ index })}
                        />

                    ))}
                </div>
            </div>
        )
    }

    render() {
        const nav = {
            showLeftIcon: false,
        }
        return (
            <Ons.Page
                renderToolbar={() => <JJ_Nav {...nav} />}
            >
                <JJ_Components.boxPage
                    loadData={this._initData}
                >
                    {this._view(this.props.guides)}
                </JJ_Components.boxPage>
            </Ons.Page>
        )
    }
}




export default connector(JJ_Guide)
