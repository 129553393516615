import React from 'react'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import JJ_UserDeliveryRecipientList from './PageItem/JJ_UserDeliveryRecipientList'
import tool from '../../tool';



interface PageState {
    selectId?: string
}

export interface Props {
    onSubmit(deliveryRecipientId: string): void
}

type PageProps = Props
class JJ_UserDeliveryRecipient extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {

        }
    }



    componentDidMount() {

    }

    render() {

        const nav = {
            showLeftIcon: true,
            title:tool.intl.formatMessage({ id: 'shopOrderDeliveryRecipientTitle' }),
        }
        return (
            <Ons.Page renderToolbar={() => <JJ_Nav {...nav} />}>
                <div
                    id='JJ_UserDeliveryRecipient'
                >
                    <div className="scroll">
                        <JJ_UserDeliveryRecipientList
                            onSelect={selectId => this.setState({ selectId })}
                        />
                    </div>
                    <Ons.Button
                        className="footView"
                        disabled={!this.state.selectId}
                        onClick={() => {
                            if (this.state.selectId) {
                                this.props.onSubmit(this.state.selectId)
                                tool.navigator.popPage()
                            }
                        }}
                    >
                        {tool.intl.formatMessage({ id: '提交' })}
                    </Ons.Button>
                </div>
            </Ons.Page>

        )
    }
}


export default JJ_UserDeliveryRecipient
