import React from 'react';
import { License } from '../../../config/config.type';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import *as ActionUser from '../../../actions/ActionUser'
import tool from '../../../tool';

interface PageState {


}

export interface Props {
    onDetailsAction?(props: PageProps): void
}

type PageProps = Props & License & InjectedIntlProps & PropsFromRedux



type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    user: ActionUser.ActionUserRes

}

const mapState = (state: RootState) => ({
    user: state.user,
})

const mapDispatch = {

}


export const connector = connect(
    mapState,
    mapDispatch
)

class JJ_LicensesListItem extends React.Component<PageProps, PageState> {



    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
        }
    }


    _titleView = () => {
        const lang = this.props.user.lang

        return (
            <div className='titleView'>
                <h1>{tool.intl.formatMessage({ id: 'licensesNumberLabel' })}: {this.props.number}</h1>
                {this.props.description && (
                    <h2>{this.props.description[lang]}</h2>
                )}
                <h2>{tool.intl.formatMessage({ id: 'licensesAddressLabel' })}: {this.props.address[lang]}</h2>
            </div>
        )
    }

    render() {

        return (

            <div
                onClick={() => this.props.onDetailsAction && this.props.onDetailsAction(this.props)}
                id='JJ_LicensesListItem'
            >
                {this._titleView()}

            </div>

        )

    }
}




export default connector(injectIntl(JJ_LicensesListItem))
