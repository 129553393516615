/**
 *获取价格
 */
export const getPrice = ({ price = '1.005' }) => {
    return Number(price.replace(/(\d+)\.(\d{0,4}).*$/, '$1$2')) * Math.pow(10, 4 - Math.min(price.indexOf('.') > -1 ? price.split('.')[1].length : 0, 4))
};

/**
 *初始化价格
 */
export const initPrice = ({ price = 200000 }) => {
    const bitNumber = 2
    const newPrice = price / 10000
    return Number(newPrice.toFixed(bitNumber))
};
