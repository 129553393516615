import React from 'react'
import config from '../../../config/config';
import { License } from '../../../config/config.type';
import JJ_Components from '../../../components/JJ_Components'
import JJ_LicensesListItem from './JJ_LicensesListItem'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import tool from '../../../tool'


interface PageState {
    pageKey: string
}


export interface Props {

}

type PageProps = Props & InjectedIntlProps




interface RootState {
}

class JJ_LicensesList extends React.Component<PageProps, PageState> {


    constructor(props: PageProps) {
        super(props);
        this.state = {
            pageKey: `${new Date()}`
        }

    }

    componentWillUnmount() {
        this.setState = () => { }
    }

    _initData = async (): Promise<void> => {
        try {

        } catch (error) {
            console.log(error)
        }
    }

    _onRenderRow = (rowData: License) => {
        return (
            <JJ_LicensesListItem
                key={rowData.number}
                {...rowData}
            />
        )
    }

    _view = () => {
        return (
            <div
                id='JJ_LicensesList'
            >
                <JJ_Components.baseListView
                    renderSeparator={(sectionID, rowID) => (<div style={{ height: 10 }} key={`${sectionID}${rowID}`} />)}
                    renderRow={this._onRenderRow}
                    onLoadData={async ({ from, count }) => ({
                        data: config.licenses.map(license => ({ ...license, id: license.number })),
                        totalCount: config.licenses.length,
                    })}
                    hideFooter={true}

                />
            </div>
        )
    }

    render() {

        return (
            <JJ_Components.boxPage
                key={`${this.state.pageKey}pageKey`}
                loadData={this._initData}
            >
                {this._view()}
            </JJ_Components.boxPage>

        )
    }
}



export default injectIntl(JJ_LicensesList)



