import React, { ReactElement } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import JJ_Components from '../../../components/JJ_Components'
import *as ActionCampaign from '../../../actions/ActionCampaign'
import { injectIntl, InjectedIntlProps } from "react-intl";
import JJ_ShopCampaignListItem from './JJ_ShopCampaignListItem'
import tool from '../../../tool';
import { GetShopCampaignListParameters, GetShopCampaignRes, GetShopCampaignParameters } from '../../../api';


interface PageState {

}


export interface Props {
    tagName: string
    moduleId: string
    tag?: string
    searchText?: string
    renderHeader?: () => React.ReactElement<any>
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    campaign: ActionCampaign.ActionCampaignRes
}

const mapState = (state: RootState) => ({
    campaignData: state.campaign

})

const mapDispatch = {
    getShopCampaignList: ActionCampaign.getShopCampaignList,
    getShopCampaign: ActionCampaign.getShopCampaign
}


export const connector = connect(
    mapState,
    mapDispatch
)

class JJ_ShopCampaignList extends React.Component<PageProps, PageState> {

    constructor(props: PageProps) {
        super(props);
        this.state = {

        }

    }

    componentDidMount() {

    }




    _getShopCampaignList = async (params: GetShopCampaignListParameters) => {
        const res = await this.props.getShopCampaignList(params)
        if (res.kind === 'ok') {
            // filter out campaigns with empty campaignPackages..
            return {
                ...res.data,
                data: res.data.data.filter(campaign => campaign.campaignPackages && campaign.campaignPackages.length > 0),
            }
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }
    }

    _getShopCampaign = async (params: GetShopCampaignParameters) => {
        const res = await this.props.getShopCampaign(params)
        if (res.kind === 'ok') {
            return res.data
        } else {
            switch (res.kind) {
                case 'INVALID_PARAMS': {

                }
            }
            throw new Error(res.kind)
        }
    }

    _initData = async (): Promise<void> => {
        try {

        } catch (error) {
            console.log(error)
        }
    }


    _onRenderRow = (rowData: GetShopCampaignRes) => {
        const campaign = this.props.campaignData.list[rowData.id]
        return (
            <JJ_ShopCampaignListItem
                campaign={campaign}
                onAction={res => {
                    tool.navigator.pushPage({ key: 'JJ_ShopCampaignDetails', props: { id: res.data.campaign.id, moduleId: this.props.moduleId } })
                }}
                onEndDate={res => {
                    this._getShopCampaign({ campaignId: res.campaign.id })
                }}
            />
        )
    }



    render() {


        return (
            <div
                id='JJ_ShopCampaignList'
            >
                <JJ_Components.baseListView
                    renderHeader={this.props.renderHeader}
                    renderSeparator={(sectionID, rowID) => (<div style={{
                        height: 10,
                        backgroundColor: 'rgba(114, 197, 197, 0.658)'
                    }} key={`${sectionID}${rowID}`} />)}
                    noDataViewText={`${tool.intl.formatMessage({ id: 'shopCampaingListNoDateText' })}`}
                    renderRow={this._onRenderRow}
                    onLoadData={({ from, count }) => this._getShopCampaignList({
                        moduleId: this.props.moduleId,
                        from,
                        count: 10,
                        tag: this.props.tag,
                        queryCampaignPackage: true,
                        // type: 'GROUP',
                        status: 'IMPORTANT',
                        searchText: this.props.searchText ? `%${this.props.searchText}%` : undefined,
                        searchFields: ['name'],
                    })}

                />
            </div>
        )
    }
}


export default connector(injectIntl(JJ_ShopCampaignList))

