import React from 'react'
import queryString from 'query-string';
import { connect, ConnectedProps } from 'react-redux'
import JJ_ShopCampaignList from './PageItem/JJ_ShopCampaignList'
import *as Ons from 'react-onsenui'
import JJ_Nav from '../SuperComponent/JJ_Nav'
import { injectIntl, InjectedIntlProps } from "react-intl";
import { Modal, Tabs, Icon, Badge } from 'antd-mobile'
import { ActionAreaRes } from '../../actions/ActionArea'
import *as ActionCartCampaignPackage from '../../actions/ActionCartCampaignPackage';
import *as ActionModal from '../../actions/ActionModal'
import tool from '../../tool'
import api, { GetShopCartCampaignTagRes, GetShopCartCampaignTagParameters, GetShopCartCampaignPackageListParameters } from '../../api'
import BoxPage from '../../components/Box/BoxPage'
import menuPng from '../../imgs/menu.png'
import JJ_Modal from './PageItem/JJ_Modal'
import JJ_Components from '../../components/JJ_Components'
import config from '../../config/config'
import { FiSearch } from 'react-icons/fi'


interface PageState {
    searchText: string
    searchingText: string
    componentKey: string,
}


export interface Props {
    moduleId: string
}

type PageProps = Props & InjectedIntlProps & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
}

const mapState = (state: RootState) => ({
})

const mapDispatch = {
}

export const connector = connect(
    mapState,
    mapDispatch
)


class JJ_ShopCampaignSearch extends React.Component<PageProps, PageState> {
    input: any | null

    constructor(props: PageProps) {
        super(props);
        this.state = {
            searchText: '',
            searchingText: '',
            componentKey: '',
        }
    }


    componentDidMount() {
        // this.input && this.input.focus()
    }

    _view = () => {
        return (
            <div
                id='JJ_ShopCampaignSearch'
            >
                <div id="JJ_ShopCampaignSearchInputContainer">
                    <FiSearch className='icon' />
                    <form action="javascript://" onSubmit={() => {
                        const searchingText = this.state.searchText.trim();

                        if (searchingText !== this.state.searchingText) {
                            this.setState({
                                searchingText,
                                componentKey: new Date().toString(),
                            });
                        }
                    }}>
                        <Ons.Input
                            ref={ref => this.input = ref}
                            className='input'
                            value={this.state.searchText}
                            float={false}
                            onChange={(e) => this.setState({ searchText: e.target.value })}
                            modifier='transparent'
                            placeholder={tool.intl.formatMessage({ id: 'shopCampaignSearchInputPlaceholder' })}
                        />
                    </form>
                </div>

                {this.state.searchingText !== '' ? (
                    <JJ_ShopCampaignList
                        key={this.state.componentKey}
                        moduleId={this.props.moduleId}
                        searchText={this.state.searchingText}
                        renderHeader={() => (
                            <div id="JJ_ShopCampaignSearchResultHeader">
                                {tool.intl.formatMessage({ id: 'shopCampaignSearchReaultHeaderLabel' })}: {this.state.searchingText}
                            </div>
                        )}
                    />
                ) : (
                    <div id="JJ_ShopCampaignSearchResultEmpty">
                        {tool.intl.formatMessage({ id: 'shopCampaignSearchReaultEmpty' })}
                    </div>
                )}
            </div>
        )
    }

    render() {
        const nav = {
            showLeftIcon: true,
        }
        return (
            <Ons.Page
                renderToolbar={() => <JJ_Nav {...nav} />}
            >
                {this._view()}
            </Ons.Page>
        )

    }
}


export default connector(injectIntl(JJ_ShopCampaignSearch))



