import Type from './ActionType';
import api, {
    GetUserShopDeliveryRecipientRes,
    GetUserShopDeliveryRecipientParameters,
    GetUserShopDeliveryRecipientListParameters,
} from '../api';
import { MyThunkDispatch } from './action-interface';
import tool from '../tool';

const upDataAction = (data: ActionUserShopDeliveryRecipientRes) => {
    return {
        type: Type.shopDeliveryRecipientType.UP_DATA,
        ...data
    }
}

const initDataAction = () => {
    return {
        type: Type.shopDeliveryRecipientType.INIT_DATA
    }
}

interface ActionUserShopDeliveryRecipientListRes {
    [id: string]: GetUserShopDeliveryRecipientRes
}
export interface ActionUserShopDeliveryRecipientRes {
    list: ActionUserShopDeliveryRecipientListRes
}

export const getUserShopDeliveryRecipientList = (params: GetUserShopDeliveryRecipientListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getUserShopDeliveryRecipientList(params)

        if (res.kind === 'ok') {
            const shopDeliveryRecipient: ActionUserShopDeliveryRecipientRes = tool.store.getState().shopDeliveryRecipient
            res.data.data.forEach(item => {
                shopDeliveryRecipient.list[item.id] = item
            })
            dispatch(upDataAction(shopDeliveryRecipient))
        }
        return res
    }
}
export const getUserShopDeliveryRecipient = (params: GetUserShopDeliveryRecipientParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getUserShopDeliveryRecipient(params)
        if (res.kind === 'ok') {
            const shopDeliveryRecipient: ActionUserShopDeliveryRecipientRes = tool.store.getState().shopDeliveryRecipient
            shopDeliveryRecipient.list[res.data.id] = res.data
            dispatch(upDataAction(shopDeliveryRecipient))
        }
        return res
    }
}

export const initData = () => {
    return (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}













