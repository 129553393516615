import React from 'react';
import *as ActionModal from '../../../actions/ActionModal';
import { connect, ConnectedProps } from 'react-redux'
import ModalPayment from '../../../components/Modal/ModalPayment';
import ModalUserCoupon from '../../../components/Modal/ModalUserCoupon';

interface PageState {

}

export interface Props {

}

type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {
    modal: ActionModal.ActionModalRes,

}


const mapState = (state: RootState) => ({

    modal: state.modal,

})

const mapDispatch = {

}

export const connector = connect(
    mapState,
    mapDispatch
)


class JJ_Modal extends React.Component<PageProps, PageState> {
    constructor(props: PageProps) {
        super(props);
        this.state = {

        }
    }

    render() {
        const paymentModal = () => {
            const payment = this.props.modal.payment
            return (
                <ModalPayment
                    {...payment}
                />
            )
        }
        const userCouponModal = () => {
            const userCoupon = this.props.modal.userCoupon
            return (
                <ModalUserCoupon
                    {...userCoupon}
                />
            )
        }
        return (
            <>
                {paymentModal()}
                {userCouponModal()}
            </>
        );
    }
}


export default connector(JJ_Modal)






