import Type from './ActionType';
import { MyThunkDispatch } from './action-interface';
import tool from '../tool'
import { GetShopCampaignRes, GetShopCampaignListParameters, GetShopCampaignParameters } from '../api';
import api from '../api';
const upDataAction = (data: any) => {
    return {
        type: Type.campaignType.UP_DATA, ...data
    }
}

const initDataAction = () => {
    return {
        type: Type.campaignType.INIT_DATA
    }
}

interface ActionCampaignListRes {
    [id: string]: GetShopCampaignRes
}
export interface ActionCampaignRes {
    list: ActionCampaignListRes
}

export const getShopCampaignList = (params: GetShopCampaignListParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getShopCampaignList({ ...params, queryCampaignPackage: true })
        if (res.kind === 'ok') {
            const campaign: ActionCampaignRes = tool.store.getState().campaign
            res.data.data.forEach(item => {
                campaign.list[item.id] = item
            })
            dispatch(upDataAction(campaign))
        }
        return res
    }
}
export const getShopCampaign = (params: GetShopCampaignParameters) => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getShopCampaign({ ...params, queryCampaignPackage: true })
        if (res.kind === 'ok') {
            const campaign: ActionCampaignRes = tool.store.getState().campaign
            campaign.list[res.data.id] = res.data
            dispatch(upDataAction(campaign))
        }
        return res
    }
}

export const initData = () => {
    return (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}












