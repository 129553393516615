import *as Ons from 'react-onsenui'
import JJ_AreaModuleDisabled from '../screens/JJ_AreaModuleDisabled/JJ_AreaModuleDisabled'
import *as JJ_ShopCampaign from '../screens/JJ_ShopCampaign/JJ_ShopCampaign'
import *as JJ_ShopCampaignSearch from '../screens/JJ_ShopCampaign/JJ_ShopCampaignSearch'
import *as JJ_ShopTagMenu from '../screens/JJ_ShopTagMenu/JJ_ShopTagMenu'
import *as JJ_ShopCampaignDetails from '../screens/JJ_ShopCampaign/JJ_ShopCampaignDetails'
import *as JJ_LoginArea from '../screens/JJ_Login/JJ_LoginArea'
import JJ_Login from '../screens/JJ_Login/JJ_Login'
import *as JJ_LoginSms from '../screens/JJ_Login/JJ_LoginSms'
import JJ_Loading from '../screens/JJ_Loading/JJ_Loading'
import *as JJ_ShopOrderDetails from '../screens/JJ_ShopOrder/JJ_ShopOrderDetails'
import *as JJ_ShopOrder from '../screens/JJ_ShopOrder/JJ_ShopOrder'
import *as JJ_ShopDeliverySpLocker from '../screens/JJ_ShopDeliverySpLocker/JJ_ShopDeliverySpLocker'
import *as JJ_ShopCartCampaignPackage from '../screens/JJ_ShopCartCampaignPackage/JJ_ShopCartCampaignPackage'
import *as JJ_UserCoupon from '../screens/JJ_UserCoupon/JJ_UserCoupon'
import *as JJ_UserDelivery from '../screens/JJ_UserDelivery/JJ_UserDelivery'
import *as JJ_TextInput from '../screens/JJ_TextInputPage/JJ_TextInput'
import *as JJ_PhoneInput from '../screens/JJ_TextInputPage/JJ_PhoneInput'
import *as JJ_UserDeliveryRecipient from '../screens/JJ_UserDelivery/JJ_UserDeliveryRecipient'
import *as JJ_UserDeliveryRecipientDetails from '../screens/JJ_UserDelivery/JJ_UserDeliveryRecipientDetails'
import *as JJ_UserDeliveryAddress from '../screens/JJ_UserDelivery/JJ_UserDeliveryAddress'
import *as JJ_UserDeliveryAddressDetails from '../screens/JJ_UserDelivery/JJ_UserDeliveryAddressDetails'
import *as JJ_RegisteredMemberSms from '../screens/JJ_RegisteredMember/JJ_RegisteredMemberSms'
import *as JJ_StripePay from '../screens/JJ_StripePay/JJ_StripePay'
import *as JJ_Guide from '../screens/JJ_Guide/JJ_Guide'
import *as JJ_Licenses from '../screens/JJ_Licenses/JJ_Licenses'



let myNavigator: Ons.Navigator | undefined


type ToolNavigatorRoute =
    {
        key: 'JJ_AreaModuleDisabled'
        props?: {}
    }
    | {
        key: 'JJ_ShopCampaign'
        props: JJ_ShopCampaign.Props
    }
    | {
        key: 'JJ_ShopCampaignSearch'
        props: JJ_ShopCampaignSearch.Props
    }
    | {
        key: 'JJ_ShopTagMenu'
        props: JJ_ShopTagMenu.Props
    }
    | {
        key: 'JJ_ShopCampaignDetails'
        props: JJ_ShopCampaignDetails.Props
    }
    | {
        key: 'JJ_LoginArea'
        props: JJ_LoginArea.Props
    }
    | {
        key: 'JJ_Login'
        props?: {}
    }

    | {
        key: 'JJ_Loading'
        props?: {}
    }
    | {
        key: 'JJ_ShopOrderDetails'
        props: JJ_ShopOrderDetails.Props
    }
    | {
        key: 'JJ_RegisteredMemberSms'
        props: JJ_RegisteredMemberSms.Props
    }
    | {
        key: 'JJ_LoginSms'
        props: JJ_LoginSms.Props
    }
    | {
        key: 'JJ_UserDelivery'
        props: JJ_UserDelivery.Props
    }
    | {
        key: 'JJ_UserDeliveryRecipient'
        props: JJ_UserDeliveryRecipient.Props
    }
    | {
        key: 'JJ_UserDeliveryRecipientDetails'
        props: JJ_UserDeliveryRecipientDetails.Props
    }
    | {
        key: 'JJ_UserDeliveryAddress'
        props: JJ_UserDeliveryAddress.Props
    }
    | {
        key: 'JJ_UserDeliveryAddressDetails'
        props: JJ_UserDeliveryAddressDetails.Props
    }
    | {
        key: 'JJ_TextInput'
        props: JJ_TextInput.Props
    }
    | {
        key: 'JJ_PhoneInput'
        props: JJ_PhoneInput.Props
    }
    | {
        key: 'JJ_StripePay'
        props: JJ_StripePay.Props
    }
    | {
        key: 'JJ_ShopOrder'
        props?: JJ_ShopOrder.Props
    }
    | {
        key: 'JJ_ShopDeliverySpLocker'
        props?: JJ_ShopDeliverySpLocker.Props
    }
    | {
        key: 'JJ_ShopCartCampaignPackage'
        props?: JJ_ShopCartCampaignPackage.Props
    }
    | {
        key: 'JJ_UserCoupon'
        props: JJ_UserCoupon.Props
    }
    | {
        key: 'JJ_Guide'
        props: JJ_Guide.Props
    }
    | {
        key: 'JJ_Licenses'
        props?: JJ_Licenses.Props
    }



class TimeControl {
    time = Date.now()
    action = (): Promise<void> => {
        //间隔
        const interval = () => {
            const timeDiffer = 600
            const newTime = Date.now() - timeDiffer

            if (this.time >= newTime) {
                return false
            } else {
                this.time = Date.now()
                return true
            }
        }

        return new Promise((resolve, reject) => {

            if (interval()) {
                resolve()
            } else {
                const setIntervalTime = setInterval(() => {
                    if (interval()) {
                        clearInterval(setIntervalTime)
                        resolve()
                    }
                }, 100);
            }
        })
    }

}
const timeControlAction = new TimeControl().action




const setRoute = (route: ToolNavigatorRoute) => {

    switch (route.key) {
        case 'JJ_AreaModuleDisabled': {
            return {
                component: JJ_AreaModuleDisabled,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_ShopCampaign': {
            return {
                component: JJ_ShopCampaign.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_ShopCampaignSearch': {
            return {
                component: JJ_ShopCampaignSearch.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_ShopTagMenu': {
            return {
                component: JJ_ShopTagMenu.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_LoginArea': {
            return {
                component: JJ_LoginArea.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_Login': {
            return {
                component: JJ_Login,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }

        case 'JJ_Loading': {
            return {
                component: JJ_Loading,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_ShopOrderDetails': {
            return {
                component: JJ_ShopOrderDetails.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_ShopCampaignDetails': {
            return {
                component: JJ_ShopCampaignDetails.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_RegisteredMemberSms': {
            return {
                component: JJ_RegisteredMemberSms.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_LoginSms': {
            return {
                component: JJ_LoginSms.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_UserDelivery': {
            return {
                component: JJ_UserDelivery.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_UserDeliveryRecipient': {
            return {
                component: JJ_UserDeliveryRecipient.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_UserDeliveryRecipientDetails': {
            return {
                component: JJ_UserDeliveryRecipientDetails.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_UserDeliveryAddress': {
            return {
                component: JJ_UserDeliveryAddress.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_UserDeliveryAddressDetails': {
            return {
                component: JJ_UserDeliveryAddressDetails.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_TextInput': {
            return {
                component: JJ_TextInput.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_PhoneInput': {
            return {
                component: JJ_PhoneInput.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_StripePay': {
            return {
                component: JJ_StripePay.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_ShopOrder': {
            return {
                component: JJ_ShopOrder.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_ShopDeliverySpLocker': {
            return {
                component: JJ_ShopDeliverySpLocker.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_ShopCartCampaignPackage': {
            return {
                component: JJ_ShopCartCampaignPackage.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_UserCoupon': {
            return {
                component: JJ_UserCoupon.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_Guide': {
            return {
                component: JJ_Guide.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
        case 'JJ_Licenses': {
            return {
                component: JJ_Licenses.default,
                props: { key: route.key + Date.now(), ...route.props || {} }
            }
        }
    }
}


export const getNavigator = (navigator: Ons.Navigator) => myNavigator = navigator
export const resetPage = (route: ToolNavigatorRoute, options?: Ons.PageTransitionOptions) => timeControlAction().then(() => myNavigator && myNavigator.resetPage(setRoute(route), options))
export const resetPageStack = (route: ToolNavigatorRoute, options?: Ons.PageTransitionOptions) => timeControlAction().then(() => myNavigator && myNavigator.resetPageStack(setRoute(route), options))
export const pushPage = (route: ToolNavigatorRoute, options?: Ons.PageTransitionOptions) => timeControlAction().then(() => myNavigator && myNavigator.pushPage(setRoute(route), options))
export const popPage = (options?: Ons.PageTransitionOptions) => timeControlAction().then(() => myNavigator && myNavigator.popPage(options))











