import *as ruter from './ToolRuter'
import *as navigator from './ToolNavigator'
import *as store from './ToolStore'
import *as moment from './ToolMoment'
import *as message from './ToolMessage'
import *as dialog from './ToolDialog'
import *as intl from './ToolIntl'
import *as math from './ToolMath'
import intlText from './ToolIntlText'
import request from './ToolRequestData/JJ_RequestDefault'
import config from './ToolConfig'
import *as phoneNumber from './ToolPhoneNumber'
import *as image from './ToolImage'
import *as analytics from './ToolAnalytics'
import *as storage from './ToolStorage'



export default {
    ruter,
    store,
    moment,
    message,
    intl,
    math,
    request,
    intlText,
    config,
    navigator,
    dialog,
    phoneNumber,
    image,
    analytics,
    storage,
}
