import *as JJ_Request from '../JJ_Request'

/**
 * 获取我的用户信息
 * @returns {*|Promise<any>}
 */
export const getUserMe = () => {
    return JJ_Request.all([JJ_Request.get(JJ_Request.code.user_me)])
        .then(ary => ary[0].data)
}



/**
 * 获取用户设置
 * @returns {*|Promise<any>}
 */
export const getUserSeting = () => {

    const code = JJ_Request.code.user_setting
    return JJ_Request.all([JJ_Request.get(code)])
        .then(ary => ary[0].data)

}


/**
 * 设置用户设置
 * @param key
 * @param value
 * @returns {*|Promise<any>}
 */
export const setUserSeting = ({key,value}) => {

    const code = JJ_Request.code.user_setting+`/${key}`
    const body = {
        value
    }
    return JJ_Request.all([JJ_Request.put(code,body)])
        .then(ary => ary[0].data)

}



