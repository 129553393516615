import React from 'react';
import { Modal } from 'antd-mobile'
import QRCode from 'qrcode.react'


interface PageState {

}

export interface Props {
    show: boolean
    onClose(): void
    qrCode: string
    title: string
    info: string
}

type PageProps = Props

class ModalQr extends React.Component<PageProps, PageState> {

    componentWillUnmount() {
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {

        }
    }
    render() {
        const view = () => {
            return (
                <div
                    className='view'
                >
                    <QRCode
                        value={this.props.qrCode}
                        size={window.innerWidth * 0.6}
                    />
                    <span className='info'>{this.props.info}</span>
                </div >

            )
        }
        return (
            <Modal
                popup={false}
                transparent
                visible={this.props.show}
                onClose={() => this.props.onClose()}
                title={this.props.title}
                animationType="slide-down"
            >
                <div
                    id='ModalQr'
                >
                    {view()}
                </div>
            </Modal>
        );
    }
}

export default ModalQr




