import React from 'react';

import *as Ons from 'react-onsenui'

import { FaStar } from 'react-icons/fa';
import { GetUserShopDeliveryRecipientRes } from '../../../api';
import tool from '../../../tool';



interface PageState {

}

export interface Props {
    onSelect(id: string): void
    deliveryRecipient: GetUserShopDeliveryRecipientRes
    onPress(): void
    checked: boolean
}



type PageProps = Props

class JJ_UserDeliveryRecipientListItem extends React.Component<PageProps, PageState> {

    componentDidMount() {
        const { isDefault, id } = this.props.deliveryRecipient
        if (isDefault) {
            this.props.onSelect(id)
        }
    }

    constructor(props: PageProps) {
        super(props);
        this.state = {
        }
    }

    _leftView = () => {

        return (
            <div className='leftView'>
                <Ons.Radio
                    inputId={`radio-${this.props.deliveryRecipient.id}`}
                    onChange={event => this.props.onSelect(this.props.deliveryRecipient.id)}
                    modifier='material'
                    checked={this.props.checked}//选中
                />
                {this.props.deliveryRecipient.isDefault && (
                    <div className="defaultLabel">
                        <FaStar />
                    </div>
                )}
            </div>
        )
    }

    _centerView = () => {
        const list = [
            { name: tool.intl.formatMessage({ id: '收货人' }), type: 'recipientName', value: this.props.deliveryRecipient.recipientName },
            { name: tool.intl.formatMessage({ id: '联系电话' }), type: 'recipientPhone', value: this.props.deliveryRecipient.recipientPhone },
        ]

        return list.map(item => {
            return (
                <Ons.ListItem
                    tappable={false}
                    modifier='longdivider'
                    key={item.type}
                    onClick={() => this.props.onPress()}
                >
                    <div className="left">{item.name}</div>
                    <div className="right">{item.type === 'isDefault'
                        ? <Ons.Switch disabled={true} checked={this.props.deliveryRecipient.isDefault} />
                        : item.value}</div>
                </Ons.ListItem>
            )
        })

    }
    _rightView = () => {
        return (
            <div className='rightView'>

            </div>
        )
    }

    render() {
        return (
            <div id='JJ_UserDeliveryRecipientListItem'>
                <Ons.ListItem
                    modifier='chevron longdivider'
                // tappable={false}
                >
                    <div className="left">{this._leftView()}</div>
                    <div className="center">{this._centerView()}</div>
                    <div className="right">{this._rightView()}</div>
                </Ons.ListItem>
            </div>
        );
    }
}




export default JJ_UserDeliveryRecipientListItem
