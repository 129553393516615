import React from 'react';
import ReactDOM from 'react-dom';
import App from './screens/App';
import * as serviceWorker from './serviceWorker';
import { AppContainer } from 'react-hot-loader'
import configureStore from './store/configureStore'
import './less/index.less';
import './tool/ToolIndex'
import { Provider } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements'
import ToolConfig from './tool/ToolConfig'
import tool from './tool';
import ons from 'onsenui'
const store = configureStore()
tool.store.getStore(store)
const render = (Component: any) => {
    ReactDOM.render(
        <StripeProvider apiKey={ToolConfig.payments.stripe.apiKey}>
            <AppContainer>
                <Provider
                    store={store}>
                    <Component />
                </Provider>
            </AppContainer>
        </StripeProvider>
        , document.getElementById('root'),
    )
}

render(App)

if (ons.platform.isIPhoneX()) { // Utility function
    // Add empty attribute to the <html> element
    document.documentElement.setAttribute('onsflag-iphonex-portrait', '');
}
if (ons.platform.isIPhoneX()) { // Utility function
    // Add empty attribute to the <html> element
    document.documentElement.setAttribute('onsflag-iphonex-landscape', '');
}
serviceWorker.unregister();

